import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  Div,
  Group,
  Placeholder,
  Select,
  Spinner,
  Text,
  Title,
} from '@vkontakte/vkui';
import { Link } from 'react-router-dom';
import { Icon24ListDeleteOutline, Icon24Add } from '@vkontakte/icons';
import { useLocation } from 'react-router-dom';
import { parseFiltersUrl } from 'lib/utils/parseFunction';
import { ApiResponse } from 'lib/api/api';
import { getFullName, getRoleName } from 'lib/utils/userUtils';
import { userFilters, userHeaders } from './constants.users';
import { ITableHeader } from 'lib/types';
import TableSetup from 'components/common/TableSetup';
import { IUser } from './types.users';
import { ADMIN_USERS_ROUTE } from 'lib/constants';
import useFetch from 'lib/hooks/useFetch';
import useParams from 'lib/hooks/useParams';
import FiltersButton from 'components/common/FiltersButton';
import FiltersWrapper from 'components/common/FiltersWrapper';
import FormItemPanel from 'components/common/FormItemPanel';
import InputSearch from 'components/common/InputSearch';
import PaginationComponent from 'components/common/Pagination';

const Users = () => {
  const [activeHeaders, setActiveHeaders] = useState<ITableHeader[]>([]);
  const { data, loading, fetchData } = useFetch<ApiResponse<IUser[]>>();
  const location = useLocation();
  const [users, setUsers] = useState<IUser[]>([]);
  const {
    filters,
    setFilters,
    addFiltersAndUpdateUrl,
    reset,
    handleChange,
    openFilters,
    handletoggleFilters,
    currentPage,
    setCurrentPage,
  } = useParams(userFilters);
  const { q, status, role } = filters;

  const handleSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFilters({ ...filters, q: value });
  };

  const fetchListFunction = () => {
    const queryParams = location.search ? parseFiltersUrl(location.search) : '';

    fetchData({
      path: `/business-profile/?page=${currentPage - 1}${queryParams && `&${queryParams}`}`,
      method: 'get',
    });
  };

  useEffect(() => {
    fetchListFunction();
  }, [location.search, currentPage]);

  useEffect(() => {
    if (!loading && data?.results) {
      setUsers(data.results);
    }
  }, [loading, location.search]);

  useEffect(() => {
    const storedHeaders = localStorage.getItem('columnOrder_users');

    if (storedHeaders) {
      setActiveHeaders(JSON.parse(storedHeaders));
    } else {
      setActiveHeaders(userHeaders);
    }
  }, []);

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <Group>
          <Div className="flex items-center gap-2">
            <Link to={ADMIN_USERS_ROUTE + `/addNew`}>
              <Button
                mode="primary"
                className="vkui-edit-button-primary min-w-fit"
                size="m"
                before={<Icon24Add />}
              >
                Добавить
              </Button>
            </Link>
            <InputSearch
              onSubmit={addFiltersAndUpdateUrl}
              value={q as string}
              onChange={handleSearchValue}
            />
            <FiltersButton
              openFilters={openFilters}
              toggle={handletoggleFilters}
            />
            <TableSetup
              tableId="users"
              headers={userHeaders}
              onActiveHeadersChange={setActiveHeaders}
            />
          </Div>
          {openFilters && (
            <FiltersWrapper
              reset={() => {
                reset();
              }}
              addFiltersAndUpdateUrl={addFiltersAndUpdateUrl}
            >
              <FormItemPanel top="Статус" htmlFor="status">
                <Select
                  className="vkui-select"
                  id="status"
                  name="status"
                  placeholder="Укажите статус"
                  value={status as string}
                  onChange={handleChange}
                  allowClearButton
                  options={[
                    { value: 'active', label: 'Активен' },
                    { value: 'blocked', label: 'Заблокирован' },
                  ]}
                />
              </FormItemPanel>
              <FormItemPanel top="Роль" htmlFor="role">
                <Select
                  className="vkui-select"
                  id="role"
                  name="role"
                  placeholder="Укажите статус"
                  value={role as string}
                  onChange={handleChange}
                  allowClearButton
                  options={[
                    { value: 'admin', label: 'Администратор' },
                    { value: 'support', label: 'Поддержка' },
                    { value: 'editor', label: 'Редактор' },
                  ]}
                />
              </FormItemPanel>
            </FiltersWrapper>
          )}
          {users && users.length > 0 ? (
            <>
              <div className="custom-scrollbar overflow-auto pb-2">
                <table className="w-full">
                  <thead>
                    <tr className="border-y-1 border-color-steel-gray-80">
                      {activeHeaders.map((header) => (
                        <th
                          key={header.value}
                          className="text-color-gray-600 uppercase py-4 whitespace-nowrap"
                        >
                          <Title
                            className="flex text-sm ml-8 max-w-fit gap-1 items-center cursor-pointer"
                            level="2"
                          >
                            {header.label}
                          </Title>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {users &&
                      users.map(
                        ({
                          id,
                          firstname,
                          lastname,
                          middlename,
                          email,
                          status,
                          role,
                        }) => (
                          <tr
                            onClick={() =>
                              window.open(
                                ADMIN_USERS_ROUTE + `/edit/${id}`,
                                '_blank',
                              )
                            }
                            key={id}
                            className="cursor-pointer transition-colors hover:bg-color-steel-gray-20 h-10"
                          >
                            {activeHeaders.some((h) => h.value === 'name') && (
                              <td className="py-2" id="name">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {getFullName(lastname, firstname, middlename)}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some((h) => h.value === 'email') && (
                              <td className="py-2" id="">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {email || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'status',
                            ) && (
                              <td className="py-2" id="">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {status !== null
                                    ? status === 'blocked'
                                      ? 'Заблокирован'
                                      : 'Активен'
                                    : '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some((h) => h.value === 'role') && (
                              <td className="py-2" id="">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {getRoleName(role)}
                                </Text>
                              </td>
                            )}
                          </tr>
                        ),
                      )}
                  </tbody>
                </table>
              </div>
              {data && data?.count > 10 ? (
                <PaginationComponent
                  count={data?.count}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  items={users}
                />
              ) : null}
            </>
          ) : (
            <Div>
              <Placeholder
                icon={<Icon24ListDeleteOutline />}
                header={'Пользователи не найдены'}
              >
                Попробуйте изменить критерии поиска
              </Placeholder>
            </Div>
          )}
        </Group>
      )}
    </>
  );
};

export default Users;
