import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Div,
  Placeholder,
  Spinner,
  Link,
  Select,
  Title,
  Text,
} from '@vkontakte/vkui';
import useFetch from 'lib/hooks/useFetch';
import useParamsHook from 'lib/hooks/useParams';
import { useParams } from 'react-router-dom';
import {
  Icon24TicketOutline,
  Icon24ExternalLinkOutline,
} from '@vkontakte/icons';
import { ApiResponse } from 'lib/api/api';
import { parseFiltersUrl } from 'lib/utils/parseFunction';
import { IEventSeance } from './types.events';
import { eventSeancesFilters, eventSeancesHeaders } from './constants.events';
import { parseDateFrom } from 'lib/utils/date.utils';
import { v4 as uuidv4 } from 'uuid';
import FiltersWrapper from 'components/common/FiltersWrapper';
import PaginationComponent from 'components/common/Pagination';
import CityChipsSelect from 'components/common/CityChipsSelect';
import PlaceChipsSelect from 'components/common/PlaceChipsSelect';
import DateRangePicker from 'components/common/DateRangePicker';
import FormItemPanel from 'components/common/FormItemPanel';
import CustomSortIcon from 'components/common/CustomSortIcon';

export interface IEventSeances {
  eventType?: {
    id: string | null;
    name: string;
  };
}

const EventSeances: FC<IEventSeances> = ({ eventType }) => {
  const {
    data: sessionsData,
    loading: sessionsLoading,
    fetchData: fetchSessions,
  } = useFetch<ApiResponse<IEventSeance[]>>();
  const { eventId } = useParams();
  const [sessions, setSessions] = useState<IEventSeance[]>([]);
  const {
    filters,
    setFilters,
    addFiltersAndUpdateUrl,
    reset,
    handleChange,
    currentPage,
    setCurrentPage,
    sorting,
    setSortingFunc,
  } = useParamsHook(eventSeancesFilters);
  const { city_id, place_id, is_pushkin_card } = filters;
  const [startDatetimeFrom, setStartDatetimeFrom] = useState<Date | undefined>(
    undefined,
  );
  const [startDatetimeTo, setStartDatetimeTo] = useState<Date | undefined>(
    undefined,
  );
  const uuid = uuidv4();

  const fetchSessionsFunction = () => {
    const queryParams = location.search ? parseFiltersUrl(location.search) : '';

    fetchSessions({
      path: `/search/events/seances/${eventId}?page=${
        currentPage - 1
      }${queryParams && `&${queryParams}`}`,
      method: 'get',
    });
  };

  const handleResetFilters = useCallback(() => {
    reset();
    setStartDatetimeTo(undefined);
    setStartDatetimeFrom(undefined);
  }, [reset, setStartDatetimeTo, setStartDatetimeFrom]);

  const getHeaders = () => {
    if (eventType?.name !== 'Кино') {
      return eventSeancesHeaders.filter(
        (item) => item.label !== 'Пушкинская карта',
      );
    } else {
      return eventSeancesHeaders;
    }
  };

  useEffect(() => {
    fetchSessionsFunction();
  }, [location.search, currentPage]);

  useEffect(() => {
    if (sessionsData?.results && !sessionsLoading) {
      setSessions(sessionsData.results);
    }
  }, [sessionsLoading]);

  return (
    <>
      {sessionsLoading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <>
          <FiltersWrapper
            className="max-w-fit"
            reset={handleResetFilters}
            addFiltersAndUpdateUrl={addFiltersAndUpdateUrl}
          >
            <CityChipsSelect
              city_id={city_id}
              filters={filters}
              setFilters={setFilters}
              reset={handleResetFilters}
              filterKey={'city_id'}
            />
            <PlaceChipsSelect
              place_id={place_id}
              filters={filters}
              setFilters={setFilters}
              reset={handleResetFilters}
              filterKey={'place_id'}
            />
            {eventType?.name === 'Кино' && (
              <FormItemPanel top="Пушкинская карта" htmlFor="is_pushkin_card">
                <Select
                  className="vkui-select"
                  id="is_pushkin_card"
                  name="is_pushkin_card"
                  placeholder="Выберите вариант"
                  value={is_pushkin_card as string}
                  onChange={handleChange}
                  allowClearButton
                  options={[
                    { value: 'true', label: 'Да' },
                    { value: 'false', label: 'Нет' },
                  ]}
                />
              </FormItemPanel>
            )}
            <DateRangePicker
              title={'Время начала сеансов'}
              withTime={true}
              from={startDatetimeFrom}
              setFrom={setStartDatetimeFrom}
              fromFilterKey={'start_datetime__from'}
              to={startDatetimeTo}
              setTo={setStartDatetimeTo}
              toFilterKey={'start_datetime__to'}
              filters={filters}
              setFilters={setFilters}
            />
          </FiltersWrapper>
          <Div>
            {sessions.length > 0 ? (
              <>
                <div className="custom-scrollbar overflow-auto pb-2">
                  <table className="w-full">
                    <thead>
                      <tr className="border-y-1 border-color-steel-gray-80">
                        {getHeaders().map((header) => {
                          return (
                            <th
                              key={header.label}
                              className="text-color-gray-600 uppercase py-4 whitespace-nowrap"
                            >
                              <Title
                                className="flex text-sm ml-8 max-w-fit gap-1 items-center cursor-pointer"
                                level="2"
                                onClick={() => {
                                  if (header.sortingValue) {
                                    setSortingFunc(header.sortingValue);
                                  }
                                }}
                              >
                                {header.sortingValue && (
                                  <CustomSortIcon
                                    sortDirection={sorting}
                                    activeHeader={header}
                                  />
                                )}
                                {header.label}
                              </Title>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {sessions.map(
                        ({
                          id,
                          place_id,
                          place_name,
                          city,
                          start,
                          duration_minutes,
                          formats,
                          tickets_count,
                          widget_url,
                        }) => {
                          return (
                            <tr
                              key={id}
                              className="cursor-pointer transition-colors hover:bg-color-steel-gray-20 h-10"
                            >
                              <td className="py-2">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {parseDateFrom({
                                    date: start,
                                    format: 'day',
                                    utc: false,
                                  })}
                                </Text>
                              </td>
                              <td className="py-2">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {city}
                                </Text>
                              </td>
                              <td className="py-2">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-[#2D81E0] hover:underline"
                                    href={`/places/edit/${place_id}`}
                                  >
                                    {place_name}
                                  </a>
                                </Text>
                              </td>
                              <td className="py-2">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {parseDateFrom({
                                    date: start,
                                    format: 'time',
                                    utc: false,
                                  })}
                                </Text>
                              </td>
                              <td className="py-2">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {duration_minutes
                                    ? `${duration_minutes} мин`
                                    : '-'}
                                </Text>
                              </td>
                              <td className="py-2">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {formats.length > 0
                                    ? formats
                                        .map((format) => format.title)
                                        .join(', ')
                                    : '-'}
                                </Text>
                              </td>
                              <td className="py-2">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {tickets_count || '-'}
                                </Text>
                              </td>
                              <td className="py-2">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {widget_url ? (
                                    <Link
                                      href={`${widget_url}?interaction_id=${uuid}`}
                                      target="_blank"
                                    >
                                      <Icon24ExternalLinkOutline
                                        width={18}
                                        height={18}
                                        className="mb-1"
                                      />
                                    </Link>
                                  ) : (
                                    '-'
                                  )}
                                </Text>
                              </td>
                              {eventType?.name === 'Кино' && (
                                <td className="py-2">
                                  <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                    {is_pushkin_card === true ? 'Да' : 'Нет'}
                                  </Text>
                                </td>
                              )}
                            </tr>
                          );
                        },
                      )}
                    </tbody>
                  </table>
                </div>
                {sessionsData && sessionsData?.count > 10 && (
                  <PaginationComponent
                    count={sessionsData?.count}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    items={sessions}
                  />
                )}
              </>
            ) : (
              <Div>
                <Placeholder
                  icon={<Icon24TicketOutline />}
                  header={'Сеансы не найдены'}
                >
                  Выберите другое мероприятия или измените критерии поиска
                </Placeholder>
              </Div>
            )}
          </Div>
        </>
      )}
    </>
  );
};

export default EventSeances;
