import React, {
  FC,
  ReactNode,
  useState,
  createContext,
  SetStateAction,
  Dispatch,
} from 'react';
import { ISnackbarContent } from 'components/common/Snackbar';

export interface ISnackbarContext {
  snackbarContent: ISnackbarContent;
  setSnackbarContent: Dispatch<SetStateAction<ISnackbarContent>>;
}

export interface ISnackbarContextProvider {
  children: ReactNode;
}

export const SnackbarContext = createContext<ISnackbarContext>(
  {} as ISnackbarContext,
);

export const SnackbarContextProvider: FC<ISnackbarContextProvider> = ({
  children,
}) => {
  const [snackbarContent, setSnackbarContent] = useState<ISnackbarContent>({
    message: '',
    type: 'success',
  });

  return (
    <SnackbarContext.Provider value={{ snackbarContent, setSnackbarContent }}>
      {children}
    </SnackbarContext.Provider>
  );
};
