import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Div,
  FormItem,
  Group,
  ModalCard,
  ModalRoot,
  Spinner,
  Text,
  Title,
} from '@vkontakte/vkui';
import { Icon24ErrorCircleOutline } from '@vkontakte/icons';
import { TextTooltip } from '@vkontakte/vkui/dist/components/TextTooltip/TextTooltip';
import { IReviewEdit } from './types.reviews';
import { reviewEventBlank, reviewStatusMap } from './constants.reviews';
import { parseDateFrom } from 'lib/utils/date.utils';
import { ADMIN_EVENTS_ROUTE } from 'lib/constants';
import useFetch from 'lib/hooks/useFetch';
import PanelTitle from 'components/common/PanelTitle';

const ReviewsEdit: FC = () => {
  const [reviewData, setReviewData] = useState<IReviewEdit>(reviewEventBlank);
  const [openModal, setOpenModal] = useState<string>('');
  const { reviewId, methodType } = useParams();
  const {
    id,
    user_id,
    is_anonim,
    status,
    value,
    text,
    created_at,
    event_id,
    event_name,
    profile,
    complaints,
  } = reviewData;
  const { data, loading, fetchData } = useFetch<IReviewEdit>();
  const { fetchData: sendData } = useFetch();

  const fetchDataFunction = () => {
    fetchData({ path: `/rate/ratings/${reviewId}/`, method: 'get' });
  };

  const handleReview = async (reviewType: string) => {
    const reviewPromise = sendData({
      path: `rate/ratings/${reviewId}/`,
      method: 'patch',
      body: {
        status: reviewType,
      },
      onSuccessMessage: 'Статус успешно изменён',
    });

    await reviewPromise;
    await setOpenModal('');
    await fetchDataFunction();
  };

  useEffect(() => {
    fetchDataFunction();
  }, []);

  useEffect(() => {
    if (methodType === 'edit') {
      if (!loading && data) {
        setReviewData(data);
      }
    }
  }, [loading]);

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <>
          <PanelTitle>
            <Title className="text-color-black">Детали отзыва</Title>
            <span className="text-color-black text-3xl">-</span>
            <Title className="inline text-color-steel-gray-500">
              {id ? id : ''}
            </Title>
          </PanelTitle>
          <Group className="custom-scrollbar">
            <FormItem top="ID пользователя">
              <Text className="text-base text-color-text-primary">
                {user_id || '-'}
              </Text>
            </FormItem>
            <FormItem top="Номер">
              <Text className="text-base text-color-text-primary">
                {profile?.phone || '-'}
              </Text>
            </FormItem>
            <FormItem top="Пользователь">
              <Text className="text-base text-color-text-primary">
                {profile?.first_name
                  ? `${profile.first_name} ${profile.last_name || '-'}`
                  : profile?.last_name || 'Имя не указано'}
              </Text>
            </FormItem>
            <FormItem top="Анонимно">
              <Text className="text-base text-color-text-primary">
                {is_anonim !== null ? (is_anonim ? 'Да' : 'Нет') : '-'}
              </Text>
            </FormItem>
            <FormItem top="Статус">
              <Text className="text-base text-color-text-primary">
                {reviewStatusMap[status as keyof typeof reviewStatusMap] || '-'}
              </Text>
            </FormItem>
            <FormItem top="Оценка">
              <Text className="text-base text-color-text-primary">
                {value || '-'}
              </Text>
            </FormItem>
            <FormItem top="Текст">
              <Text className="flex gap-2 text-base text-color-text-primary">
                {complaints.length > 0 && (
                  <TextTooltip text="Есть жалоба на отзыв" arrowPadding={1}>
                    <Icon24ErrorCircleOutline color={'#E64646'} />
                  </TextTooltip>
                )}
                {text || '-'}
              </Text>
              {complaints.length > 0 && (
                <Button
                  className="vkui-edit-button-secondary mt-2"
                  mode="secondary"
                  size="m"
                  appearance="accent"
                  onClick={() => setOpenModal('REVIEW')}
                >
                  Рассмотреть жалобу
                </Button>
              )}
            </FormItem>
            <FormItem top="Дата и время отзыва">
              <Text className="text-base text-color-text-primary">
                {parseDateFrom({
                  date: created_at,
                  format: 'daytime',
                  utc: false,
                }) || '-'}
              </Text>
            </FormItem>
            <FormItem top="ID Мероприятия">
              <Text className="text-base text-color-text-primary">
                {event_id || '-'}
              </Text>
            </FormItem>
            <FormItem top="Мероприятиe">
              <Text
                className="text-base cursor-pointer text-[#2D81E0] hover:underline"
                onClick={() =>
                  window.open(
                    ADMIN_EVENTS_ROUTE + `/edit/${event_id}`,
                    '_blank',
                  )
                }
              >
                {event_name || '-'}
              </Text>
            </FormItem>
            <Div>
              <ButtonGroup align="right" stretched>
                {(status === 'PENDING' || status === 'REJECTED') && (
                  <Button
                    className="vkui-edit-button-primary"
                    mode="primary"
                    size="m"
                    appearance="accent"
                    onClick={() => setOpenModal('PUBLISHED')}
                  >
                    Опубликовать
                  </Button>
                )}
                {(status === 'PENDING' || status === 'PUBLISHED') && (
                  <Button
                    className="vkui-edit-button-primary"
                    mode="primary"
                    size="m"
                    appearance="accent"
                    onClick={() => setOpenModal('REJECTED')}
                  >
                    Скрыть
                  </Button>
                )}
              </ButtonGroup>
            </Div>
          </Group>
        </>
      )}
      <ModalRoot activeModal={openModal}>
        <ModalCard
          id="REVIEW"
          onClose={() => setOpenModal('')}
          className="fixed"
          size={425}
        >
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black" level="2">
              Жалоба на отзыв
            </Title>
            <Text className="text-color-gray-600">
              На данный отзыв поступила жалоба. Что вы хотите сделать с данным
              отзывом?
            </Text>
            {status === 'PUBLISHED' && (
              <ButtonGroup className="pt-3" align="right" stretched>
                <Button
                  className="vkui-edit-button-secondary"
                  mode="secondary"
                  size="s"
                  onClick={() => handleReview('REJECTED')}
                >
                  Скрыть отзыв
                </Button>
                <Button
                  className="vkui-edit-button-primary"
                  mode="primary"
                  size="s"
                  onClick={() => handleReview('PUBLISHED')}
                >
                  Оставить опубликованным
                </Button>
              </ButtonGroup>
            )}
            {status === 'REJECTED' && (
              <ButtonGroup className="pt-3" align="right" stretched>
                <Button
                  className="vkui-edit-button-secondary"
                  mode="secondary"
                  size="s"
                  onClick={() => handleReview('REJECTED')}
                >
                  Оставить скрытым
                </Button>
                <Button
                  className="vkui-edit-button-primary"
                  mode="primary"
                  size="s"
                  onClick={() => handleReview('PUBLISHED')}
                >
                  Опубликовать
                </Button>
              </ButtonGroup>
            )}
          </Div>
        </ModalCard>
        <ModalCard
          id="PUBLISHED"
          onClose={() => setOpenModal('')}
          className="fixed"
        >
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black" level="2">
              Опубликовать отзыв
            </Title>
            <Text className="text-color-gray-600">
              Вы уверены, что хотите опубликовать отзыв?
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={() => setOpenModal('')}
              >
                Отмена
              </Button>
              <Button
                className="vkui-edit-button-primary"
                mode="primary"
                size="m"
                onClick={() => handleReview('PUBLISHED')}
              >
                Да, опубликовать
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard>
        <ModalCard
          id="REJECTED"
          onClose={() => setOpenModal('')}
          className="fixed"
        >
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black" level="2">
              Скрыть отзыв
            </Title>
            <Text className="text-color-gray-600">
              Вы уверены, что хотите скрыть отзыв?
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={() => setOpenModal('')}
              >
                Отмена
              </Button>
              <Button
                className="vkui-edit-button-primary"
                mode="primary"
                size="m"
                onClick={() => handleReview('REJECTED')}
              >
                Да, скрыть
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard>
      </ModalRoot>
    </>
  );
};

export default ReviewsEdit;
