import React, { useEffect, useState } from 'react';
import { Div, Placeholder, Spinner, Link, Title, Text } from '@vkontakte/vkui';
import useFetch from 'lib/hooks/useFetch';
import useParamsHook from 'lib/hooks/useParams';
import { useParams } from 'react-router-dom';
import { Icon24TicketOutline } from '@vkontakte/icons';
import { ApiResponse } from 'lib/api/api';
import { parseFiltersUrl } from 'lib/utils/parseFunction';
import { IEventLink } from './types.events';
import { eventLinksHeaders } from './constants.events';
import PaginationComponent from 'components/common/Pagination';

const SIZE_PAGE_LINKS = 10;

const EventLinks = () => {
	const {
		data: linksData,
		loading: linksLoading,
		fetchData: fetchLinks,
	} = useFetch<ApiResponse<IEventLink[]>>();
	const { eventId } = useParams();
	const [links, setLinks] = useState<IEventLink[]>([]);
	const { currentPage, setCurrentPage } = useParamsHook({});

	const fetchLinksFunction = () => {
		const queryParams = location.search ? parseFiltersUrl(location.search) : '';

		fetchLinks({
			path: `/event/${eventId}/links/?page=${
				currentPage - 1
			}&page_size=${SIZE_PAGE_LINKS}${queryParams && `&${queryParams}`}`,
			method: 'get',
		});
	};

	useEffect(() => {
		fetchLinksFunction();
	}, [location.search, currentPage]);

	useEffect(() => {
		if (linksData?.results && !linksLoading) {
			setLinks(linksData.results);
		}
	}, [linksLoading]);

	return (
		<>
			{linksLoading ? (
				<Div style={{ width: '100%', height: '400px' }}>
					<Spinner size="medium" className="spinner" />
				</Div>
			) : (
				<Div>
					{links.length > 0 ? (
						<>
							<div className="custom-scrollbar overflow-auto pb-2">
								<table className="w-full">
									<thead>
										<tr className="border-y-1 border-color-steel-gray-80">
											{eventLinksHeaders.map((header) => {
												return (
													<th
														key={header.label}
														className="whitespace-nowrap py-4 uppercase text-color-gray-600"
													>
														<Title
															className="ml-8 flex max-w-fit cursor-pointer items-center gap-1 text-sm"
															level="2"
														>
															{header.label}
														</Title>
													</th>
												);
											})}
										</tr>
									</thead>
									<tbody>
										{links.map(({ city_name, web_url, miniapp_url }) => {
											return (
												<tr
													key={web_url}
													className="h-10 cursor-pointer transition-colors hover:bg-color-steel-gray-20"
												>
													<td className="py-2">
														<Text className="ml-8 min-w-156 max-w-19 overflow-hidden text-ellipsis whitespace-nowrap">
															{city_name}
														</Text>
													</td>
													<td className="py-2">
														<Text className="ml-8 min-w-156 max-w-19 overflow-hidden text-ellipsis whitespace-nowrap">
															{web_url ? (
																<Link
																	href={web_url}
																	// href={`${web_url}?interaction_id=${uuid}`}
																	target="_blank"
																>
																	{web_url}
																</Link>
															) : (
																'-'
															)}
														</Text>
													</td>
													<td className="py-2">
														<Text className="ml-8 min-w-156 max-w-19 overflow-hidden text-ellipsis whitespace-nowrap">
															{miniapp_url ? (
																<Link href={miniapp_url} target="_blank">
																	{miniapp_url}
																</Link>
															) : (
																'-'
															)}
														</Text>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
							{linksData && linksData?.count > SIZE_PAGE_LINKS && (
								<PaginationComponent
									count={linksData?.count}
									currentPage={currentPage}
									setCurrentPage={setCurrentPage}
									items={links}
									pageSize={SIZE_PAGE_LINKS}
								/>
							)}
						</>
					) : (
						<Div>
							<Placeholder
								icon={<Icon24TicketOutline />}
								header={'Ссылки не найдены'}
							></Placeholder>
						</Div>
					)}
				</Div>
			)}
		</>
	);
};

export default EventLinks;
