import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Icon20Dropdown } from '@vkontakte/icons';
import { Div, Group, Text, classNames } from '@vkontakte/vkui';
import HeaderItem from './HeaderItem';
import { Popover } from '@vkontakte/vkui/dist/components/Popover/Popover';
import {
  ADMIN_DIRECTORIES_ROUTE,
  ADMIN_EVENTS_ROUTE,
  ADMIN_ORDERS_ROUTE,
  ADMIN_PERSONS_ROUTE,
  ADMIN_PLACES_ROUTE,
  ADMIN_REVIEWS_ROUTE,
  ADMIN_USERS_ROUTE,
  directories,
  events,
} from '../../lib/constants';
import { getUserRole } from 'lib/utils/authUtils';
import UserProfile from './UserProfile';

export interface PopupObjType {
  users: boolean;
  directories: boolean;
  events: boolean;
  reviews: boolean;
}

const AdminHeader: React.FC = () => {
  const { pathname } = useLocation();
  const userRole = getUserRole();
  const [openPopup, setOpenPopup] = useState<PopupObjType>({
    users: false,
    directories: false,
    events: false,
    reviews: false,
  });

  const handleChangePopupValue = (type: keyof typeof openPopup) => {
    setOpenPopup({ ...openPopup, [type]: !openPopup[type] });
  };

  return (
    <Group className="flex justify-between">
      <Div className="flex items-center gap-4">
        {userRole === 'support' && (
          <>
            <HeaderItem
              className={classNames(
                'text-nav transition-colors cursor-pointer hover:text-color-blue-400',
                pathname.includes('orders') ? 'text-color-blue-400' : '',
              )}
              setOpenPopup={setOpenPopup}
              title="Заказы"
              link={ADMIN_ORDERS_ROUTE}
            />
            <HeaderItem
              className={classNames(
                'text-nav transition-colors cursor-pointer hover:text-color-blue-400',
                pathname.includes(`reviews`) ? 'text-color-blue-400' : '',
              )}
              setOpenPopup={setOpenPopup}
              title="Отзывы"
              link={ADMIN_REVIEWS_ROUTE}
            />
            <HeaderItem
              className={classNames(
                'text-nav transition-colors cursor-pointer hover:text-color-blue-400',
                !pathname.includes('reviews/events') &&
                  pathname.includes('events')
                  ? 'text-color-blue-400'
                  : '',
              )}
              setOpenPopup={setOpenPopup}
              title="Мероприятия"
              link={ADMIN_EVENTS_ROUTE}
            />
            <HeaderItem
              className={classNames(
                'text-nav transition-colors cursor-pointer hover:text-color-blue-400',
                !pathname.includes('reviews/places') &&
                  pathname.includes('places')
                  ? 'text-color-blue-400'
                  : '',
              )}
              setOpenPopup={setOpenPopup}
              title="Места проведения"
              link={ADMIN_PLACES_ROUTE}
            />
          </>
        )}
        {userRole === 'editor' && (
          <>
            <HeaderItem
              className={classNames(
                'text-nav transition-colors cursor-pointer hover:text-color-blue-400',
                pathname.includes('orders') ? 'text-color-blue-400' : '',
              )}
              setOpenPopup={setOpenPopup}
              title="Заказы"
              link={ADMIN_ORDERS_ROUTE}
            />
            <HeaderItem
              className={classNames(
                'text-nav transition-colors cursor-pointer hover:text-color-blue-400',
                pathname.includes(`reviews`) ? 'text-color-blue-400' : '',
              )}
              setOpenPopup={setOpenPopup}
              title="Отзывы"
              link={ADMIN_REVIEWS_ROUTE}
            />
            <Popover
              action="click"
              shown={openPopup.events}
              onShownChange={() => handleChangePopupValue('events')}
              content={
                <Div className="flex flex-col p-0">
                  {events.map(({ title, link }) => (
                    <HeaderItem
                      className={classNames(
                        'py-3 px-5 text-nav transition-colors cursor-pointer hover:text-color-blue-400',
                        pathname.includes('events') &&
                          !pathname.includes('reviews') &&
                          pathname.includes(link)
                          ? 'text-color-blue-400'
                          : '',
                      )}
                      setOpenPopup={setOpenPopup}
                      key={link}
                      title={title}
                      link={link}
                    />
                  ))}
                </Div>
              }
            >
              <Div className="flex items-center p-0">
                <Text
                  className={classNames(
                    'text-nav transition-colors cursor-pointer hover:text-color-blue-400',
                    (pathname.includes('events') &&
                      !pathname.includes('reviews')) ||
                      (pathname.includes('ranking') &&
                        !pathname.includes('reviews'))
                      ? 'text-color-blue-400'
                      : '',
                  )}
                >
                  Мероприятия
                </Text>
                <Icon20Dropdown
                  fill="#99A2AD"
                  width={18}
                  hanging={18}
                  className={`mt-0.5 ml-0.5 transition-all ${openPopup.events ? 'rotate-180' : ''}`}
                />
              </Div>
            </Popover>
            <HeaderItem
              className={classNames(
                'text-nav transition-colors cursor-pointer hover:text-color-blue-400',
                !pathname.includes('reviews/places') &&
                  pathname.includes('places')
                  ? 'text-color-blue-400'
                  : '',
              )}
              setOpenPopup={setOpenPopup}
              title="Места проведения"
              link={ADMIN_PLACES_ROUTE}
            />
            <HeaderItem
              className={classNames(
                'text-nav transition-colors cursor-pointer hover:text-color-blue-400',
                pathname.includes(`persons`) ? 'text-color-blue-400' : '',
              )}
              setOpenPopup={setOpenPopup}
              title="Персоны"
              link={ADMIN_PERSONS_ROUTE}
            />
            <Popover
              action="click"
              shown={openPopup.directories}
              onShownChange={() => handleChangePopupValue('directories')}
              content={
                <Div className="flex flex-col p-0">
                  {directories.map(({ title, link }) => (
                    <HeaderItem
                      className={classNames(
                        'py-3 px-5 text-nav transition-colors cursor-pointer hover:text-color-blue-400',
                        pathname.includes(`${link}`)
                          ? 'text-color-blue-400'
                          : '',
                      )}
                      setOpenPopup={setOpenPopup}
                      key={link}
                      title={title}
                      link={ADMIN_DIRECTORIES_ROUTE + link}
                    />
                  ))}
                </Div>
              }
            >
              <Div className="flex items-center p-0">
                <Text
                  className={classNames(
                    'text-nav transition-colors cursor-pointer hover:text-color-blue-400',
                    pathname.includes('directories')
                      ? 'text-color-blue-400'
                      : '',
                  )}
                >
                  Справочники
                </Text>
                <Icon20Dropdown
                  fill="#99A2AD"
                  width={18}
                  hanging={18}
                  className={`mt-0.5 ml-0.5 transition-all ${openPopup.directories ? 'rotate-180' : ''}`}
                />
              </Div>
            </Popover>
          </>
        )}
        {userRole === 'admin' && (
          <>
            <HeaderItem
              className={classNames(
                'text-nav transition-colors cursor-pointer hover:text-color-blue-400',
                pathname.includes('orders') ? 'text-color-blue-400' : '',
              )}
              setOpenPopup={setOpenPopup}
              title="Заказы"
              link={ADMIN_ORDERS_ROUTE}
            />
            <HeaderItem
              className={classNames(
                'text-nav transition-colors cursor-pointer hover:text-color-blue-400',
                pathname.includes(`reviews`) ? 'text-color-blue-400' : '',
              )}
              setOpenPopup={setOpenPopup}
              title="Отзывы"
              link={ADMIN_REVIEWS_ROUTE}
            />
            <Popover
              action="click"
              shown={openPopup.events}
              onShownChange={() => handleChangePopupValue('events')}
              content={
                <Div className="flex flex-col p-0">
                  {events.map(({ title, link }) => (
                    <HeaderItem
                      className={classNames(
                        'py-3 px-5 text-nav transition-colors cursor-pointer hover:text-color-blue-400',
                        pathname.includes('events') &&
                          !pathname.includes('reviews') &&
                          pathname.includes(link)
                          ? 'text-color-blue-400'
                          : '',
                      )}
                      setOpenPopup={setOpenPopup}
                      key={link}
                      title={title}
                      link={link}
                    />
                  ))}
                </Div>
              }
            >
              <Div className="flex items-center p-0">
                <Text
                  className={classNames(
                    'text-nav transition-colors cursor-pointer hover:text-color-blue-400',
                    (pathname.includes('events') &&
                      !pathname.includes('reviews')) ||
                      (pathname.includes('ranking') &&
                        !pathname.includes('reviews'))
                      ? 'text-color-blue-400'
                      : '',
                  )}
                >
                  Мероприятия
                </Text>
                <Icon20Dropdown
                  fill="#99A2AD"
                  width={18}
                  hanging={18}
                  className={`mt-0.5 ml-0.5 transition-all ${openPopup.events ? 'rotate-180' : ''}`}
                />
              </Div>
            </Popover>
            <HeaderItem
              className={classNames(
                'text-nav transition-colors cursor-pointer hover:text-color-blue-400',
                !pathname.includes('reviews/places') &&
                  pathname.includes('places')
                  ? 'text-color-blue-400'
                  : '',
              )}
              setOpenPopup={setOpenPopup}
              title="Места проведения"
              link={ADMIN_PLACES_ROUTE}
            />
            <HeaderItem
              className={classNames(
                'text-nav transition-colors cursor-pointer hover:text-color-blue-400',
                pathname.includes(`persons`) ? 'text-color-blue-400' : '',
              )}
              setOpenPopup={setOpenPopup}
              title="Персоны"
              link={ADMIN_PERSONS_ROUTE}
            />
            <Popover
              action="click"
              shown={openPopup.directories}
              onShownChange={() => handleChangePopupValue('directories')}
              content={
                <Div className="flex flex-col p-0">
                  {directories.map(({ title, link }) => (
                    <HeaderItem
                      className={classNames(
                        'py-3 px-5 text-nav transition-colors cursor-pointer hover:text-color-blue-400',
                        pathname.includes(`${link}`)
                          ? 'text-color-blue-400'
                          : '',
                      )}
                      setOpenPopup={setOpenPopup}
                      key={link}
                      title={title}
                      link={ADMIN_DIRECTORIES_ROUTE + link}
                    />
                  ))}
                </Div>
              }
            >
              <Div className="flex items-center p-0">
                <Text
                  className={classNames(
                    'text-nav transition-colors cursor-pointer hover:text-color-blue-400',
                    pathname.includes('directories')
                      ? 'text-color-blue-400'
                      : '',
                  )}
                >
                  Справочники
                </Text>
                <Icon20Dropdown
                  fill="#99A2AD"
                  width={18}
                  hanging={18}
                  className={`mt-0.5 ml-0.5 transition-all ${openPopup.directories ? 'rotate-180' : ''}`}
                />
              </Div>
            </Popover>
            <HeaderItem
              className={classNames(
                'text-nav transition-colors cursor-pointer hover:text-color-blue-400',
                pathname.includes('users') ? 'text-color-blue-400' : '',
              )}
              setOpenPopup={setOpenPopup}
              title="Пользователи"
              link={ADMIN_USERS_ROUTE}
            />
          </>
        )}
      </Div>
      <UserProfile />
    </Group>
  );
};

export default AdminHeader;
