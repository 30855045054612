import React, { FC } from 'react';
import { Card, CardGrid, Group, Header, Title } from '@vkontakte/vkui';
import { Icon36GhostSimpleOutline } from '@vkontakte/icons';
import Layout from 'layout';
import PanelTitle from 'components/common/PanelTitle';

const ErrorPage: FC = () => {
  return (
    <Layout>
      <PanelTitle withArrow={false}>
        <Title className="text-color-black">
          <h1>Ошибка</h1>
        </Title>
      </PanelTitle>
      <Group mode="plain">
        <CardGrid size="l">
          <Card mode="outline">
            <div
              style={{ height: 500 }}
              className="flex items-center justify-center flex-col gap-4"
            >
              <Icon36GhostSimpleOutline color={'#818c99'} />
              <Header mode="secondary">Что-то пошло не так</Header>
            </div>
          </Card>
        </CardGrid>
      </Group>
    </Layout>
  );
};

export default ErrorPage;
