import React, { type ReactNode } from "react"

import { FormItem, classNames } from "@vkontakte/vkui"

interface IFormItemPanel {
    children: ReactNode
    className?: string
    top?: string
    htmlFor?: string
    width?: string
}

const FormItemPanel = ({ children, className, top, htmlFor, width = '248px' }: IFormItemPanel) => {
    const styles = classNames(className, 'filters-item p-0')
    return (
        <FormItem style={{ maxWidth: width, width: width }} htmlFor={htmlFor} top={top} className={styles}>
            {children}
        </FormItem>
    )
}

export default FormItemPanel