import React, { FC, SyntheticEvent, useRef, useState } from 'react';
import { Button, ButtonGroup, Div, Title } from '@vkontakte/vkui';
import ReactCrop, {
  Crop,
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from 'react-image-crop';
import { setCanvas, uploadToFileStorage } from 'lib/utils/imageFile.utils';

type ImageCropperProps = {
  minDimension: number;
  aspectRatio: number;
  imageSrc: string;
  setOpenModal: (openModal: string) => void;
  setState: (url: string) => void;
};

const ImageCropper: FC<ImageCropperProps> = ({
  minDimension,
  aspectRatio,
  imageSrc,
  setOpenModal,
  setState,
}) => {
  const [crop, setCrop] = useState<Crop>();
  const imageRef = useRef<HTMLImageElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const onImageLoad = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    const { width, height } = event.currentTarget;
    const cropWidthInPercent = (minDimension / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: '%',
        width: cropWidthInPercent,
      },
      aspectRatio,
      width,
      height,
    );

    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const handleButtonClick = () => {
    const currentImage = imageRef.current;
    const currentCanvas = canvasRef.current;
    const currentCrop = crop || {};

    if (currentImage && currentCanvas) {
      setCanvas(
        currentImage,
        currentCanvas,
        convertToPixelCrop(
          currentCrop,
          currentImage.width,
          currentImage.height,
        ),
      );

      const dataUrl = currentCanvas.toDataURL();
      uploadToFileStorage(dataUrl, setState);
      setOpenModal('');
    }
  };

  return (
    <Div className="flex flex-col items-center justify-center relative max-h-fit">
      <Title className="text-color-black" level="2">
        Выберите область
      </Title>
      {imageSrc && (
        <>
          <ReactCrop
            crop={crop}
            keepSelection
            aspect={aspectRatio}
            minWidth={minDimension}
            onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
            ruleOfThirds
          >
            <img
              src={imageSrc}
              ref={imageRef}
              onLoad={onImageLoad}
              className="max-h-96"
              alt="Загрузить"
            />
          </ReactCrop>
          <ButtonGroup className="pt-3" align="right" stretched>
            <Button
              className="vkui-edit-button-secondary"
              mode="secondary"
              size="l"
              onClick={() => setOpenModal('')}
            >
              Закрыть
            </Button>
            <Button
              className="vkui-edit-button-primary"
              mode="primary"
              size="l"
              onClick={() => handleButtonClick()}
            >
              Сохранить
            </Button>
          </ButtonGroup>
        </>
      )}
      {crop && (
        <canvas
          ref={canvasRef}
          className="hidden m4-t border border-solid border-black object-contain"
          style={{
            width: minDimension,
            height: minDimension,
          }}
        />
      )}
    </Div>
  );
};

export default ImageCropper;
