import React, { useEffect, useRef, useState, FC } from 'react';
import FormItemPanel from './FormItemPanel';
import { EmptyChipsSelect } from './EmptyChipsSelect';
import ChipsSelectComponent from './ChipsSelectComponent';
import { IChip } from 'lib/types';
import useDebounce from 'lib/hooks/useDebounce';
import useFetch from 'lib/hooks/useFetch';
import { ApiResponse } from 'lib/api/api';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { IType } from 'components/directories/TypesDirectory/types.typesDirectory';

interface ITypeChipsSelectProps {
  // eslint-disable-next-line
  category_id: any;
  // eslint-disable-next-line
  filters: Record<string, any>;
  // eslint-disable-next-line
  setFilters: (filters: Record<string, any>) => void;
  reset: () => void;
  filterKey: string;
}

const TypeChipsSelect: FC<ITypeChipsSelectProps> = ({
  category_id,
  filters,
  setFilters,
  reset,
  filterKey,
}) => {
  const [allCategories, setAllCategories] = useState<IType[]>([]);
  const [currentCategories, setCurrentCategories] = useState<IType[]>([]);
  const [categoriesCurrentPage, setCategoriesCurrentPage] = useState<number>(1);
  const [categoriesSearch, setCategoriesSearch] = useState<string>('');
  const debouncedCategoriesSearch = useDebounce(categoriesSearch, 500);
  const categoryListInnerRef = useRef<HTMLDivElement>(null);

  const {
    data: categoriesData,
    loading: categoriesLoading,
    fetchData: fetchCategories,
  } = useFetch<ApiResponse<IType[]>>();

  const [categoriesSentryRef, { rootRef: categoriesRootRef }] =
    useInfiniteScroll({
      onLoadMore: () => {
        setCategoriesCurrentPage((prev) => prev + 1);
      },
      hasNextPage: !!categoriesData?.next,
      loading: categoriesLoading,
      rootMargin: '0px 0px 0px 0px',
      delayInMs: 200,
    });

  useEffect(() => {
    handleReset();
  }, [reset]);

  const handleReset = () => {
    setCategoriesSearch('');
  };

  useEffect(() => {
    fetchCategories({
      path: `/event/categories/?is_active=true&page=${
        categoriesCurrentPage - 1
      }&page_size=10${categoriesSearch && `&q=${debouncedCategoriesSearch}`}`,
      method: 'get',
    });
  }, [categoriesCurrentPage, debouncedCategoriesSearch]);

  useEffect(() => {
    setCategoriesCurrentPage(1);
    setCurrentCategories([]);
  }, [categoriesSearch]);

  useEffect(() => {
    if (!categoriesLoading && categoriesData?.results) {
      setCurrentCategories(categoriesData.results);
      setAllCategories((prev) => [...prev, ...categoriesData.results]);
      categoryListInnerRef.current?.scrollIntoView({
        inline: 'start',
        block: 'nearest',
      });
    }
  }, [categoriesLoading]);

  return (
    <>
      <FormItemPanel top="Тип">
        {allCategories.length === 0 ? (
          <EmptyChipsSelect />
        ) : (
          <ChipsSelectComponent
            disabled={allCategories.length === 0}
            hasNextPage={!!categoriesData?.next}
            loaderRef={categoriesSentryRef}
            listRef={categoriesRootRef}
            listInnerRef={categoryListInnerRef}
            onInput={(e) => {
              setCategoriesSearch(e.target.value.trim());
            }}
            inputValue={categoriesSearch}
            chipClassName="max-w-[175px] truncate"
            placeholder="Выберите тип"
            values={(category_id as IChip[]).map((category) => ({
              label:
                allCategories.find((item) => item.id === category.value)
                  ?.name || category.label,
              value: category.value,
            }))}
            onChange={(value) => setFilters({ ...filters, [filterKey]: value })}
            options={currentCategories.map((category) => ({
              label: category.name,
              value: category.id,
            }))}
          />
        )}
      </FormItemPanel>
    </>
  );
};

export default TypeChipsSelect;
