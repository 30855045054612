import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Cell,
  Div,
  FormItem,
} from '@vkontakte/vkui';
import { Icon20AddCircle, Icon24RemoveCircle } from '@vkontakte/icons';
import InputSearch from 'components/common/InputSearch';
import { ApiResponse } from 'lib/api/api';
import useFetch from 'lib/hooks/useFetch';
import { IEventPerson } from './types.events';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'lib/hooks/useSnackbar';
import useDebounce from 'lib/hooks/useDebounce';

interface IEventPersonsProps {
  currentPersons: IEventPerson[];
  eventId: string | undefined;
}

const EventPersons: FC<IEventPersonsProps> = ({ currentPersons, eventId }) => {
  const navigate = useNavigate();
  const { setSnackbarContent } = useSnackbar();
  const [personSearchQuery, setPersonSearchQuery] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const { fetchData: patchData } = useFetch();
  const [searchPersons, setSearchPersons] = useState<IEventPerson[]>([]);
  const [oldPersons, setOldPersons] = useState<IEventPerson[]>([]);
  const [newPersons, setNewPersons] = useState<IEventPerson[]>([]);
  const debouncedSearchQuery = useDebounce(personSearchQuery, 500);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const {
    data: personsData,
    loading: loadingPersons,
    fetchData: fetchPersons,
  } = useFetch<ApiResponse<IEventPerson[]>>();

  const fetchPersonsFunction = (query: string, pageNumber: number) => {
    fetchPersons({
      path: `/person/persons/?page=${pageNumber}&page_size=30&q=${query}`,
      method: 'get',
    });
  };

  const handleSearchPerson = () => {
    setPage(0); 
    fetchPersonsFunction(personSearchQuery, 0);
    setIsModalOpen(true);
  };

  const handleSave = async () => {
    const oldArr = oldPersons.map((item) => item.id);
    const newArr = newPersons.map((item) => item.id);

    try {
      await patchData({
        path: `/event/events/${eventId}/`,
        method: 'patch',
        body: { persons: [...oldArr, ...newArr] },
        onSuccessMessage: 'Персона добавлена',
      });
      navigate(0);
    } catch (error) {
      setSnackbarContent({
        type: 'error',
        message: 'Ошибка при добавлении персоны',
      });
    }
  };

  const handleAddPerson = async (person: IEventPerson) => {
    const oldArr = oldPersons.map((item) => item.id);
    const newArr = newPersons.map((item) => item.id);

    if (oldArr.includes(person.id) || newArr.includes(person.id)) {
      setSnackbarContent({
        type: 'error',
        message: 'Персона уже добавлена',
      });
      return;
    } else {
      setNewPersons([...newPersons, person]);
    }
  };

  const handleRemovePerson = async (id: string) => {
    setOldPersons(oldPersons.filter((person) => person.id !== id));
    setNewPersons(newPersons.filter((person) => person.id !== id));
  };

  useEffect(() => {
    if (debouncedSearchQuery) {
      setPage(0);
      fetchPersonsFunction(debouncedSearchQuery, 0);
      setIsModalOpen(true);
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (!loadingPersons && personsData?.results) {
      if (page === 0) {
        setSearchPersons(personsData.results);
      } else {
        setSearchPersons((prev) => [...prev, ...personsData.results]);
      }
      setHasMore(!!personsData.next);
    }
  }, [loadingPersons, personsData, page]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest('input')
      ) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setOldPersons(currentPersons);
  }, [currentPersons]);

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (
      scrollTop + clientHeight >= scrollHeight &&
      hasMore &&
      !loadingPersons
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (page > 0 && debouncedSearchQuery) {
      fetchPersonsFunction(debouncedSearchQuery, page);
    }
  }, [page]);

  return (
    <>
      <div className="h-96">
        <FormItem>
          <InputSearch
            onSubmit={handleSearchPerson}
            value={personSearchQuery}
            onChange={(e) => setPersonSearchQuery(e.target.value)}
            placeholder="Добавить персону"
          />
        </FormItem>
        {isModalOpen && !loadingPersons && (
          <div
            ref={modalRef}
            className="absolute top-16 left-2 w-fit z-10"
          >
            {searchPersons.length > 0 ? (
              <FormItem>
                <Card mode="shadow">
                  <ul
                    className="overflow-y-auto"
                    style={{ maxHeight: '416px' }}
                    onScroll={handleScroll}
                  >
                    {searchPersons.map((person) => (
                      <li
                        key={person.id}
                        className="p-2 hover:text-gray-400 cursor-pointer flex gap-2 items-center "
                        onClick={() => {
                          handleAddPerson(person);
                          setIsModalOpen(false);
                          setPersonSearchQuery('');
                        }}
                      >
                        <Icon20AddCircle width={14} color={'#5181B8'} />
                        {person.full_name}
                      </li>
                    ))}
                  </ul>
                </Card>
              </FormItem>
            ) : (
              <FormItem>
                <Card mode="shadow">
                  <Div>По вашему запросу ничего не найдено</Div>
                </Card>
              </FormItem>
            )}
          </div>
        )}
        <FormItem>
          <ul className="flex flex-wrap gap-4">
            {oldPersons.map(({ id, full_name }) => {
              return (
                <li key={id}>
                  <Cell
                    className="group bg-color-card-grey text-color-steel-gray-500 rounded-md min-w-fit"
                    after={
                      <Icon24RemoveCircle
                        className="cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute -right-5 -top-5 transform -translate-y-1/2"
                        width={28}
                        height={28}
                        color={'#e64646'}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleRemovePerson(id);
                        }}
                      />
                    }
                  >
                    {full_name}
                  </Cell>
                </li>
              );
            })}
            {newPersons.map(({ id, full_name }) => {
              return (
                <li key={id}>
                  <Cell
                    className="group bg-color-card-grey text-color-steel-gray-500 rounded-md min-w-fit outline-1 outline-dashed outline-color-nav-items-blue"
                    after={
                      <Icon24RemoveCircle
                        className="cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute -right-5 -top-5 transform -translate-y-1/2"
                        width={28}
                        height={28}
                        color={'#e64646'}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleRemovePerson(id);
                        }}
                      />
                    }
                  >
                    {full_name}
                  </Cell>
                </li>
              );
            })}
          </ul>
        </FormItem>
      </div>
      <Div>
        <ButtonGroup align="right" stretched>
          <Button
            className="vkui-edit-button-primary"
            mode="primary"
            size="m"
            appearance="accent"
            onClick={handleSave}
          >
            Применить изменения
          </Button>
        </ButtonGroup>
      </Div>
    </>
  );
};

export default EventPersons;
