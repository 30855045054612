import { diff } from 'deep-object-diff';

export const findObjectsDiffs = (
  // eslint-disable-next-line
  obj1: any,
  // eslint-disable-next-line
  obj2: any,
  // eslint-disable-next-line
): Record<string, any> => {
  return diff(obj1, obj2);
};

// eslint-disable-next-line
export const isObjectsEqual = (obj1: any, obj2: any): boolean => {
  const differences = diff(obj1, obj2);
  return Object.keys(differences).length === 0;
};
