import React, {
  ReactNode,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

interface CheckedElementsContextType<T> {
  checkedElements: string[];
  setCheckedElements: Dispatch<SetStateAction<string[]>>;
  handleCheckedItem: (id: string) => void;
  clearCheckedElements: () => void;
  handleToggleAllCheckValue: (filteredItems: T[]) => void;
}

const CheckedElementsContext = createContext<
  // eslint-disable-next-line
  CheckedElementsContextType<any> | undefined
>(undefined);

// eslint-disable-next-line
interface ICheckedElementsProvider<T> {
  children: ReactNode;
}

export const CheckedElementsProvider = <T,>({
  children,
}: ICheckedElementsProvider<T>) => {
  const [checkedElements, setCheckedElements] = useState<string[]>([]);

  const handleCheckedItem = (id: string) => {
    setCheckedElements((prevCheckedElements) => {
      if (prevCheckedElements.includes(id)) {
        return prevCheckedElements.filter((item) => item !== id);
      } else {
        return [...prevCheckedElements, id];
      }
    });
  };

  const clearCheckedElements = () => {
    setCheckedElements([]);
  };

  const handleToggleAllCheckValue = (filteredItems: T[]) => {
    setCheckedElements((prev) =>
      prev.length !== filteredItems.length
        // eslint-disable-next-line
        ? filteredItems.map((item) => (item as any).id.toString())
        : [],
    );
  };

  const contextValue: CheckedElementsContextType<T> = {
    checkedElements,
    setCheckedElements,
    handleCheckedItem,
    clearCheckedElements,
    handleToggleAllCheckValue,
  };

  return (
    <CheckedElementsContext.Provider value={contextValue}>
      {children}
    </CheckedElementsContext.Provider>
  );
};

export const useCheckedElements = <
  // eslint-disable-next-line
  T extends {},
>(): CheckedElementsContextType<T> => {
  const context = useContext(CheckedElementsContext);
  if (!context) {
    throw new Error(
      'useCheckedElements must be used within a CheckedElementsProvider',
    );
  }
  return context as CheckedElementsContextType<T>;
};
