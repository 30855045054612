// eslint-disable-next-line
export const parseErrorMessage = (error: any) => {
  const errorMessage = error.response?.data?.error_message;

  if (!errorMessage) {
    return {};
  }

  try {
    if (errorMessage.startsWith('{') || errorMessage.startsWith('[')) {
      const parseObj = JSON.parse(
        errorMessage.replace(/'/g, '"').replace(/None/g, '[]'),
      );
      return parseObj;
    }
    return { message: errorMessage };
  } catch (parseError) {
    console.error('Ошибка разбора JSON:', parseError);
    return { message: errorMessage };
  }
};

