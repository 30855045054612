import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { IAuth, IAuthToken, IAuthUser } from 'lib/types';

const axiosInstanceAuth: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const authService = {
  login: (url: string, data: IAuth): Promise<AxiosResponse<IAuthUser>> =>
    axiosInstanceAuth.post<IAuthUser>(url, data),

  refreshToken: (
    url: string,
    data: IAuthToken,
  ): Promise<AxiosResponse<IAuthUser>> =>
    axiosInstanceAuth.post<IAuthUser>(url, data),
};
