import { AxiosInstance, AxiosResponse } from 'axios';
import { createAxiosInstance } from './axiosInstance';

export interface ApiResponse<T> {
  count: number;
  next: number | null;
  previous: number | null;
  results: T;
}

const axiosInstance: AxiosInstance = createAxiosInstance(
  process.env.REACT_APP_BASE_URL as string,
  'application/json',
);

export const apiService = {
  get: <T>(url: string): Promise<AxiosResponse<T>> => axiosInstance.get<T>(url),
  post: <T>(url: string, data?: T): Promise<AxiosResponse<T>> =>
    axiosInstance.post<T>(url, data),
  put: <T>(url: string, data?: T): Promise<AxiosResponse<T>> =>
    axiosInstance.put<T>(url, data),
  patch: <T>(url: string, data?: T): Promise<AxiosResponse<T>> =>
    axiosInstance.patch<T>(url, data),
  delete: <T>(url: string): Promise<AxiosResponse<T>> =>
    axiosInstance.delete<T>(url),
  deleteAll: <T>(url: string, data: T): Promise<AxiosResponse<T>> =>
    axiosInstance.delete<T>(url, { data }),
};

export default apiService;
