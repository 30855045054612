export const getFullName = (
  lastname: string,
  firstname: string,
  middlename?: string,
) => {
  const names = [lastname, firstname, middlename].filter(
    (name) => name && name.trim() !== '',
  );
  return names.length > 0 ? names.join(' ') : '-';
};

export const getRoleName = (role: string) => {
  switch (role) {
    case 'admin':
      return 'Администратор';
    case 'support':
      return 'Поддержка';
    case 'editor':
      return 'Редактор';
    default:
      return '-';
  }
};
