import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Title } from '@vkontakte/vkui';
import Layout from 'layout';
import PanelTitle from 'components/common/PanelTitle';
import RankingEdit from 'components/Ranking/RankingEdit';
import Ranking from 'components/Ranking/Ranking';

const EventsRankingPage: FC = () => {
  const { methodType } = useParams();

  return (
    <Layout>
      {!methodType ? (
        <PanelTitle withArrow={false}>
          <Title className="text-color-black">Ранжирование и закрепление</Title>
        </PanelTitle>
      ) : null}
      {methodType ? <RankingEdit /> : <Ranking />}
    </Layout>
  );
};

export default EventsRankingPage;
