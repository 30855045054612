import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Title } from '@vkontakte/vkui';
import Layout from 'layout';
import PanelTitle from 'components/common/PanelTitle';
import PlacesEdit from 'components/Places/PlacesEdit';
import Places from 'components/Places/Places';

const PlacesPage: FC = () => {
  const { methodType } = useParams();

  return (
    <Layout>
      {!methodType ? (
        <PanelTitle withArrow={false}>
          <Title className="text-color-black">Места проведения</Title>
        </PanelTitle>
      ) : null}
      {methodType ? <PlacesEdit/> : <Places />}
    </Layout>
  );
};

export default PlacesPage;
