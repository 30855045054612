import React, { ChangeEvent } from 'react';
import { FormItem, Input, Text } from '@vkontakte/vkui';

interface SeoMetaFormItemProps {
	top: string;
	name: string;
	value: string;
	onChange: (value:string) => void;
	placeholder: string;
	className?: string;
	maxLength?: number;
}

const SeoMetaFormItem: React.FC<SeoMetaFormItemProps> = ({
	top,
	name,
	value,
	onChange,
	placeholder,
	className,
	maxLength,
}) => {
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		onChange(event.target.value);
	};

	return (
		<FormItem className={className} top={top} status={'default'}>
			<Input
				name={name}
				onChange={handleChange}
				placeholder={placeholder}
				value={value}
				readOnly={false}
				maxLength={maxLength}
			/>
			{maxLength && (
				<Text className="text-color-steel-gray-400">{`Ограничение символов ${value.length}/${maxLength}`}</Text>
			)}
		</FormItem>
	);
};

export default SeoMetaFormItem;