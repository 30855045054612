// eslint-disable-next-line
const parseJSON = (input: string): any => {
  try {
    return JSON.parse(input);
  } catch (error) {
    console.error(`Error parsing JSON: ${error}`);
    return null;
  }
};

export const escapeDoubleQuotes = (inputString: string): string => {
  return inputString.replace(/"/g, '\\"');
};

export const cleanSearchQuery = (query: string) => {
  return query.trim().replace(/\s+/g, ' ');
};

// parseFiltersUrl, parses root, turns key/value array into strings
export const parseFiltersUrl = (search: string) => {
  if (search) {
    const queryParams = search.substring(1).split('&');

    const updatedFilters: Record<string, (string | number)[]> = {};

    queryParams.forEach((param) => {
      const [key, value] = param.split('=');

      if (key === 'page') return;

      const decodedValue = decodeURIComponent(value);
      const parsedValue = parseJSON(decodedValue);

      if (Array.isArray(parsedValue)) {
        if (!updatedFilters[key]) {
          updatedFilters[key] = [];
        }
        updatedFilters[key] = updatedFilters[key].concat(parsedValue);
      } else {
        if (!updatedFilters[key]) {
          updatedFilters[key] = [];
        }
        updatedFilters[key].push(parsedValue);
      }
    });

    const queryString = Object.entries(updatedFilters)
      .map(([key, values]) =>
        values
          .map((value) => `${key}=${encodeURIComponent(value.toString())}`)
          .join('&'),
      )
      .join('&');

    return queryString;
  }
};

// parses root, turns key/value array into strings
export const parseRoute = (route: string): string => {
  const keyValuePairs = route.split('&');
  const parsedRoute: string[] = [];

  keyValuePairs.forEach((pair) => {
    const [key, value] = pair.split('=');

    if (value && value.startsWith('["') && value.endsWith('"]')) {
      const valuesArray = JSON.parse(value.replace(/'/g, '"'));
      valuesArray.forEach((item: string) => {
        parsedRoute.push(`${key}="${item}"`);
      });
    } else {
      parsedRoute.push(`${key}=${value}`);
    }
  });

  return parsedRoute.join('&').replace(/&\?/g, '&');
};

// inverse function to parseRoute
export const reverseParseRoute = (
  queryString: string,
  targetKey: string,
): string => {
  const queryParams = new URLSearchParams(queryString);
  const reversedRoute: Record<string, string | string[]> = {};

  queryParams.forEach((value, key) => {
    if (!reversedRoute[key]) {
      reversedRoute[key] = [];
    }
    (reversedRoute[key] as string[]).push(value);
  });

  const finalRoute: string[] = [];

  for (const [key, values] of Object.entries(reversedRoute)) {
    if (key === targetKey) {
      if (Array.isArray(values)) {
        const formattedValues = values.map((v) => `"${v}"`).join(',');
        finalRoute.push(`${key}=[${formattedValues.replace(/""/g, '"')}]`);
      } else {
        finalRoute.push(`${key}="${values}"`);
      }
    } else {
      finalRoute.push(`${key}=${values}`);
    }
  }

  return `?${finalRoute.join('&')}`;
};

// inverse function to parseRoute, but it cuts off all other values, leaving only the passed key
export const extractFromRoute = (
  queryString: string,
  targetKey: string,
): string => {
  const queryParams = new URLSearchParams(queryString);
  const reversedRoute: Record<string, string | string[]> = {};

  queryParams.forEach((value, key) => {
    if (!reversedRoute[key]) {
      reversedRoute[key] = [];
    }
    (reversedRoute[key] as string[]).push(value);
  });

  const finalRoute: string[] = [];
  const targetValue = reversedRoute[targetKey];

  if (targetValue !== undefined) {
    if (Array.isArray(targetValue)) {
      finalRoute.push(
        `${targetKey}=${JSON.stringify(
          targetValue.map((v) => v.replace(/"/g, '')),
        )}`,
      );
    } else {
      finalRoute.push(`${targetKey}="${targetValue}"`);
    }
  }

  return finalRoute.join('&');
};
