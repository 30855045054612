import { ISnackbarContent } from 'components/common/Snackbar';
import {
  IMAGE_MIME_TYPE_ERROR,
  IMAGE_SIZE_ERROR,
  MAX_IMAGE_SIZE,
  imagesMimeTypes,
} from 'lib/constants';
import { Dispatch, SetStateAction } from 'react';

export const validateImage = ({
  image,
  setSnackbarContent,
  mimeTypes = imagesMimeTypes,
  maxSize = MAX_IMAGE_SIZE,
}: {
  image: File;
  setSnackbarContent?: Dispatch<SetStateAction<ISnackbarContent>>;
  mimeTypes?: string[];
  maxSize?: number;
}) => {
  let isValid = true;
  const errors = [];

  const isRightMimeType = mimeTypes.some((type) => type === image.type);
  if (!isRightMimeType) {
    errors.push(IMAGE_MIME_TYPE_ERROR);
    isValid = false;
  }

  const isRightSize = image.size < maxSize;
  if (!isRightSize) {
    errors.push(IMAGE_SIZE_ERROR);
    isValid = false;
  }

  if (setSnackbarContent) {
    setSnackbarContent({
      message: errors.join('. '),
      type: 'error',
    });
  }

  return {
    isValid,
    errors,
  };
};
