import { HistoryContext } from "providers/HistoryContext"
import { useContext } from "react"

export const useHistory = () => {
  const { history, setHistory, getPreviousRoute } = useContext(HistoryContext)
  
  return {
    history, 
    setHistory,
    getPreviousRoute
  }
}