import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Div,
  FormItem,
  Group,
  ModalCard,
  ModalRoot,
  Select,
  Spinner,
  Text,
  Title,
} from '@vkontakte/vkui';
import { findObjectsDiffs } from 'lib/utils/findObjectsDiffs';
import { useSnackbar } from 'lib/hooks/useSnackbar';
import { replaceEmptyWithNull } from 'lib/utils/replaceEmptyWithNull';
import { ADMIN_DIRECTORIES_ROUTE } from 'lib/constants';
import useFetch from 'lib/hooks/useFetch';
import useFormItems from 'lib/hooks/useFormItems';
import { eventLabelBlank, eventLabelFieldNameMap } from './constants.eventLabelsDirectory';
import { IEventLabel } from './types.eventLabelsDirectory';
import PanelTitle from 'components/common/PanelTitle';
import EventFormItem from 'components/common/EventFormItem';
import getErrorMessage from 'lib/utils/getErrorMessage';

const EventLabelsDirectoryEdit = () => {
  const { data, loading, fetchData, error } = useFetch<IEventLabel>();
  const { fetchData: patchData } = useFetch();
  const navigate = useNavigate();
  const { setSnackbarContent } = useSnackbar();
  const { directoryId, methodType } = useParams();
  const [openModal, setOpenModal] = useState<string>('');
  const [eventLabelData, setEventLabelData] =
    useState<IEventLabel>(eventLabelBlank);
  const { formData, isError, errorItems, handleChange } =
    useFormItems<IEventLabel>({
      initialValues: eventLabelData,
    });
  const { name, type } = formData;
  const [isNewChecked, setIsNewChecked] = useState<boolean>(true);

  const handleDeleteEventLabel = () => {
    fetchData({
      path: `/event/label/${directoryId}/`,
      method: 'delete',
      onSuccessMessage: 'Успешно удалено',
    })
      .then(() => setOpenModal(''))
      .then(() => navigate(`${ADMIN_DIRECTORIES_ROUTE}/event_labels`));
  };

  const handleSave = async () => {
    if (methodType === 'edit') {
      const diffObject = findObjectsDiffs(formData, eventLabelData);
      const diffKeys = Object.keys(diffObject);
      // eslint-disable-next-line
      const diffData: Record<string, any> = {};

      if (diffKeys.length !== 0) {
        diffKeys.forEach((key) => {
          diffData[key] = formData[key as keyof IEventLabel];
        });
      }

      if (eventLabelData?.is_active !== isNewChecked)
        diffData.is_active = isNewChecked;

      if (Object.keys(diffData).length > 0) {
        const editPromise = patchData({
          path: `/event/label/${directoryId}/`,
          method: 'patch',
          body: replaceEmptyWithNull(diffData),
          onSuccessMessage: 'Изменения сохранены',
        });

        const response = await editPromise;
        const error = response?.errorData?.response?.data;

        if (error?.code) {
          setSnackbarContent({
            type: 'error',
            message: getErrorMessage(error, eventLabelFieldNameMap),
          });
        } else {
          await fetchDataFunction();
        }
      }
    }

    if (methodType === 'addNew') {
      // eslint-disable-next-line
      const { id, ...diffData } = formData;
      diffData.is_active = isNewChecked;

      const addPromise = patchData({
        path: `/event/label/`,
        method: 'post',
        body: replaceEmptyWithNull(diffData),
        onSuccessMessage: 'Успешно добавлено',
      });

      const response = await addPromise;
      const error = response?.errorData?.response?.data;

      if (error?.code) {
        setSnackbarContent({
          type: 'error',
          message: getErrorMessage(error, eventLabelFieldNameMap),
        });
      } else {
        await navigate(`${ADMIN_DIRECTORIES_ROUTE}/event_labels`);
      }
    }
  };

  const handleIsNewCheckedChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsNewChecked(event.target.checked);
  };

  const fetchDataFunction = () => {
    if (methodType === 'edit') {
      fetchData({ path: `/event/label/${directoryId}/`, method: 'get' });
    }
  };

  useEffect(() => {
    fetchDataFunction();
  }, []);

  useEffect(() => {
    if (data) {
      setEventLabelData(data);
    }
  }, [loading]);

  useEffect(() => {
    setIsNewChecked(eventLabelData.is_active);
  }, [eventLabelData]);

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <>
          <PanelTitle>
            <Title className="text-color-black">
              {methodType === 'edit'
                ? `Лейбл мероприятия`
                : 'Добавить лейбл мероприятия'}
            </Title>
            {methodType === 'edit' && (
              <Title className="inline text-color-steel-gray-500">
                {eventLabelData?.name}
              </Title>
            )}
          </PanelTitle>
          <Group className="custom-scrollbar">
            <FormItem top="Тип">
              <Select
                name="type"
                className="vkui-select"
                placeholder="Укажите тип"
                value={type}
                onChange={handleChange}
                options={[
                  { label: 'Свойство мероприятия', value: 'event' },
                  { label: 'Свойство цены', value: 'price' },
                ]}
                disabled={methodType === 'edit'}
              />
            </FormItem>
            <EventFormItem
              className="vkui-input"
              top="Название"
              name="name"
              value={name}
              onChange={handleChange}
              placeholder="Введите название лейбла"
              isRequired={true}
              isError={isError}
              errorItems={errorItems}
              error={error}
              maxLength={type === 'event' ? 25 : 22}
              after={
                <span className="pr-3 text-sm">
                  Ограничение символов {name.length}/
                  {type === 'event' ? 25 : 22}
                </span>
              }
            />
            <FormItem className="py-0 mt-3 mb-2 ml-3 w-32">
              <Checkbox
                checked={isNewChecked}
                onChange={handleIsNewCheckedChange}
              >
                Активно
              </Checkbox>
            </FormItem>
            <Div>
              <ButtonGroup align="right" stretched>
                {methodType === 'edit' ? (
                  <>
                    <Button
                      className="vkui-edit-button-secondary"
                      mode="secondary"
                      size="m"
                      appearance="accent"
                      onClick={() => setOpenModal('delete')}
                    >
                      Удалить
                    </Button>
                    <Button
                      className="vkui-edit-button-primary"
                      mode="primary"
                      size="m"
                      appearance="accent"
                      onClick={handleSave}
                    >
                      Сохранить
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() =>
                        navigate(`${ADMIN_DIRECTORIES_ROUTE}/event_labels`)
                      }
                      className="vkui-edit-button-secondary"
                      mode="secondary"
                      size="m"
                      appearance="accent"
                    >
                      Назад
                    </Button>
                    <Button
                      className="vkui-edit-button-primary"
                      mode="primary"
                      size="m"
                      appearance="accent"
                      onClick={handleSave}
                    >
                      Добавить
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </Div>
          </Group>
        </>
      )}
      <ModalRoot activeModal={openModal}>
        <ModalCard
          id="delete"
          onClose={() => setOpenModal('')}
          className="fixed"
        >
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black" level="2">
              Удаление лейбла
            </Title>
            <Text className="text-color-gray-600">
              Данный лейбл будет удален
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={() => setOpenModal('')}
              >
                Отмена
              </Button>
              <Button
                className="vkui-edit-button-primary"
                mode="primary"
                size="m"
                onClick={() => handleDeleteEventLabel()}
              >
                Удалить
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard>
      </ModalRoot>
    </>
  );
};

export default EventLabelsDirectoryEdit;
