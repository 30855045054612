// paths:
export const ADMIN_LOGIN_ROUTE = '/login';
export const ADMIN_USERS_ROUTE = '/users';
export const ADMIN_EVENTS_ROUTE = '/events';
export const ADMIN_RANKING_ROUTE = '/ranking';
export const ADMIN_PLACES_ROUTE = '/places';
export const ADMIN_DIRECTORIES_ROUTE = '/directories';
export const ADMIN_REVIEWS_ROUTE = '/reviews';
export const ADMIN_ORDERS_ROUTE = '/orders';
export const ADMIN_PERSONS_ROUTE = '/persons';
export const ADMIN_NOT_FOUND_ROUTE = '/not-found';

// crop:
export const MIN_DIMENSION = 200;
export const ASPECT_RATIO = 3 / 2;
export const MAX_IMAGE_SIZE = 12582912;
export const IMAGE_MIME_TYPE_ERROR =
  'Разрешенные расширения изображений: JPEG, JPG, PNG, BMP';
export const IMAGE_SIZE_ERROR =
  'Максимальный размер изображения не должен превышать 12 МБайт';
export const imagesMimeTypes = ['image/jpeg', 'image/png', 'image/bmp'];

export const dataProviderOptions = [
  { value: 'intickets', label: 'intickets' },
  { value: 'kinokassa', label: 'kinokassa' },
];

export const dataOwnerOptions = [
  { value: 'intickets', label: 'intickets' },
  { value: 'kinokassa', label: 'kinokassa' },
  { value: 'kassir', label: 'kassir' },
];

export const dataEnricherOptions = [{ value: 'afisher', label: 'afisher' }];

export const activity = [
  { label: 'Активен', value: true },
  { label: 'Заблокирован', value: false },
];

export const isActive = [
  { label: 'Есть', value: true },
  { label: 'Нет', value: false },
];

export const isShow = [
  { label: 'Опубликовано', value: true },
  { label: 'Скрыто', value: false },
];

export const directories = [
  { title: 'Теги для мероприятий', link: '/event_tags' },
  { title: 'Типы', link: '/types' },
  { title: 'Жанр', link: '/genres' },
  { title: 'Лейблы мероприятий', link: '/event_labels' },
];

export const users = [{ title: 'Пользователи', link: '/users' }];

export const events = [
  { title: 'Список мероприятий', link: '/events' },
  { title: 'Ранжирование и закрепление', link: '/ranking' },
];

export const reviews = [{ title: 'Отзывы на мероприятия', link: '/events' }];
