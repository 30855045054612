import { useContext } from 'react';
import { SnackbarContext } from 'providers/SnackbarContext';

export const useSnackbar = () => {
  const { snackbarContent, setSnackbarContent } = useContext(SnackbarContext);

  return {
    snackbarContent,
    setSnackbarContent,
  };
};
