import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Div,
  Group,
  ModalCard,
  ModalRoot,
  Placeholder,
  Select,
  Spinner,
  Text,
  Title,
  Input
} from '@vkontakte/vkui';
import { Icon16SearchOutline, Icon24ListDeleteOutline } from '@vkontakte/icons';
import {
  ADMIN_EVENTS_ROUTE,
  dataEnricherOptions,
  dataOwnerOptions,
  dataProviderOptions,
} from 'lib/constants';
import { ApiResponse } from 'lib/api/api';
import { parseFiltersUrl } from 'lib/utils/parseFunction';
import { TextTooltip } from '@vkontakte/vkui/dist/components/TextTooltip/TextTooltip';
import { useCheckedElements } from 'providers/CheckedElementsContext';
import { ITableHeader } from 'lib/types';
import {
  countUniqueValues,
  createStringFromObjArray,
} from 'lib/utils/arrayOfObjects';
import { IEvent, IEventsProps } from './types.events';
import { eventFilters, eventHeaders } from './constants.events';
import TableSetup from 'components/common/TableSetup';
import useFetch from 'lib/hooks/useFetch';
import useParams from 'lib/hooks/useParams';
import InputSearch from 'components/common/InputSearch';
import FiltersButton from 'components/common/FiltersButton';
import FiltersWrapper from 'components/common/FiltersWrapper';
import FormItemPanel from 'components/common/FormItemPanel';
import PaginationComponent from 'components/common/Pagination';
import CityChipsSelect from 'components/common/CityChipsSelect';
import PlaceChipsSelect from 'components/common/PlaceChipsSelect';
import TypeChipsSelect from 'components/common/TypeChipsSelect';
import LabelChipsSelect from 'components/common/LabelChipsSelect';
import DateRangePicker from 'components/common/DateRangePicker';
import { handleKeyDown } from 'lib/utils/handleKeyDown';

const Events: FC<IEventsProps> = ({ onCheckboxesChange }) => {
  const [activeHeaders, setActiveHeaders] = useState<ITableHeader[]>([]);
  const { data, loading, fetchData } = useFetch<ApiResponse<IEvent[]>>();
  const location = useLocation();
  const [openModal, setOpenModal] = useState<string>('');
  const [events, setEvents] = useState<IEvent[]>([]);
  const { checkedElements, setCheckedElements, handleCheckedItem } =
    useCheckedElements<IEvent>();
  const {
    filters,
    setFilters,
    addFiltersAndUpdateUrl,
    reset,
    handleChange,
    openFilters,
    handletoggleFilters,
    currentPage,
    setCurrentPage,
  } = useParams(eventFilters);
  const {
    q,
    preview_url_exists,
    description_exists,
    seances_exists,
    is_enabled,
    city_id,
    place_id,
    category_id,
    provider,
    label_top_id,
    label_bottom_id,
    source_owner,
    data_enricher,
    changed_from_admin,
    is_pushkin_card,
    event_provider_id
  } = filters;

  const [startDatetimeFrom, setStartDatetimeFrom] = useState<Date | undefined>(
    undefined,
  );

  const [startDatetimeTo, setStartDatetimeTo] = useState<Date | undefined>(
    undefined,
  );

  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFilters({ ...filters, q: value });
  };

  const fetchListFunction = () => {
    const queryParams = location.search ? parseFiltersUrl(location.search) : '';

    fetchData({
      path: `/search/events?page=${currentPage - 1}${queryParams && `&${queryParams}`}`,
      method: 'get',
    });
  };

  const handleResetFilters = useCallback(() => {
    reset();
    setStartDatetimeFrom(undefined);
    setStartDatetimeTo(undefined);
  }, [reset, setStartDatetimeFrom, setStartDatetimeTo]);

  useEffect(() => {
    fetchListFunction();
  }, [location.search, currentPage]);

  useEffect(() => {
    if (!loading && data?.results) {
      setEvents(data.results);
    }
  }, [loading, location.search]);

  onCheckboxesChange &&
    useEffect(() => {
      onCheckboxesChange(checkedElements);
    }, [checkedElements]);

  useEffect(() => {
    setCheckedElements([]);
  }, []);

  useEffect(() => {
    const storedHeaders = localStorage.getItem('columnOrder_events');

    if (storedHeaders) {
      setActiveHeaders(JSON.parse(storedHeaders));
    } else {
      setActiveHeaders(eventHeaders);
    }
  }, []);

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <Group>
          <Div className="flex items-center gap-2">
            <InputSearch
              onSubmit={addFiltersAndUpdateUrl}
              value={q as string}
              onChange={handleChangeSearchValue}
            />
            <FiltersButton
              openFilters={openFilters}
              toggle={handletoggleFilters}
            />
            <TableSetup
              tableId="events"
              headers={eventHeaders}
              onActiveHeadersChange={setActiveHeaders}
            />
          </Div>
          {openFilters && (
            <FiltersWrapper
              reset={handleResetFilters}
              addFiltersAndUpdateUrl={addFiltersAndUpdateUrl}
            >
              <FormItemPanel top="ID мероприятия у поставщика">
                <Input
                  onKeyDown={(event) => {
                    handleKeyDown(event, addFiltersAndUpdateUrl);
                  }}
                  className="vkui-input"
                  name="event_provider_id"
                  placeholder="Укажите id"
                  value={event_provider_id as string}
                  onChange={handleChange}
                  before={<Icon16SearchOutline fill="#99A2AD" />}
                />
              </FormItemPanel>
              <TypeChipsSelect
                category_id={category_id}
                filters={filters}
                setFilters={setFilters}
                reset={handleResetFilters}
                filterKey={'category_id'}
              />
              <CityChipsSelect
                city_id={city_id}
                filters={filters}
                setFilters={setFilters}
                reset={handleResetFilters}
                filterKey={'city_id'}
              />
              <PlaceChipsSelect
                place_id={place_id}
                filters={filters}
                setFilters={setFilters}
                reset={handleResetFilters}
                filterKey={'place_id'}
              />
              <FormItemPanel top="Обложка" htmlFor="preview_url_exists">
                <Select
                  className="vkui-select"
                  id="preview_url_exists"
                  name="preview_url_exists"
                  placeholder="Наличие обложки"
                  value={preview_url_exists as string}
                  onChange={handleChange}
                  allowClearButton
                  options={[
                    { value: 'true', label: 'Есть' },
                    { value: 'false', label: 'Нет' },
                  ]}
                />
              </FormItemPanel>
              <FormItemPanel top="Описание" htmlFor="description_exists">
                <Select
                  className="vkui-select"
                  id="description_exists"
                  name="description_exists"
                  placeholder="Наличие описания"
                  value={description_exists as string}
                  onChange={handleChange}
                  allowClearButton
                  options={[
                    { value: 'true', label: 'Есть' },
                    { value: 'false', label: 'Нет' },
                  ]}
                />
              </FormItemPanel>
              <FormItemPanel top="Сеансы" htmlFor="seances_exists">
                <Select
                  className="vkui-select"
                  id="seances_exists"
                  name="seances_exists"
                  placeholder="Наличие сеансов"
                  value={seances_exists as string}
                  onChange={handleChange}
                  allowClearButton
                  options={[
                    { value: 'true', label: 'Есть' },
                    { value: 'false', label: 'Нет' },
                  ]}
                />
              </FormItemPanel>
              <FormItemPanel top="Статус" htmlFor="is_enabled">
                <Select
                  className="vkui-select"
                  id="is_enabled"
                  name="is_enabled"
                  placeholder="Укажите статус"
                  value={is_enabled as string}
                  onChange={handleChange}
                  allowClearButton
                  options={[
                    { value: 'true', label: 'Активно' },
                    { value: 'false', label: 'Неактивно' },
                  ]}
                />
              </FormItemPanel>
              <FormItemPanel top="Поставщик" htmlFor="provider">
                <Select
                  className="vkui-select"
                  id="provider"
                  name="provider"
                  placeholder="Выберите поставщика"
                  value={provider as string}
                  onChange={handleChange}
                  allowClearButton
                  options={dataProviderOptions}
                />
              </FormItemPanel>
              <LabelChipsSelect
                label_top_id={label_top_id}
                filters={filters}
                setFilters={setFilters}
                reset={handleResetFilters}
                labelType={'Лейбл мероприятия'}
              />
              <LabelChipsSelect
                label_bottom_id={label_bottom_id}
                filters={filters}
                setFilters={setFilters}
                reset={handleResetFilters}
                labelType={'Лейбл цены'}
              />
              <FormItemPanel top="Владелец данных" htmlFor="source_owner">
                <Select
                  className="vkui-select"
                  id="source_owner"
                  name="source_owner"
                  placeholder="Выберите владельца"
                  value={source_owner as string}
                  onChange={handleChange}
                  allowClearButton
                  options={dataOwnerOptions}
                />
              </FormItemPanel>
              <FormItemPanel top="Обогащение данных" htmlFor="data_enricher">
                <Select
                  className="vkui-select"
                  id="data_enricher"
                  name="data_enricher"
                  placeholder="Выберите источник"
                  value={data_enricher as string}
                  onChange={handleChange}
                  allowClearButton
                  options={dataEnricherOptions}
                />
              </FormItemPanel>
              <FormItemPanel
                top="Изменено админом"
                htmlFor="changed_from_admin"
              >
                <Select
                  className="vkui-select"
                  id="changed_from_admin"
                  name="changed_from_admin"
                  placeholder="Выберите вариант"
                  value={changed_from_admin as string}
                  onChange={handleChange}
                  allowClearButton
                  options={[
                    { value: 'true', label: 'Да' },
                    { value: 'false', label: 'Нет' },
                  ]}
                />
              </FormItemPanel>
              <FormItemPanel top="Пушкинская карта" htmlFor="is_pushkin_card">
                <Select
                  className="vkui-select"
                  id="is_pushkin_card"
                  name="is_pushkin_card"
                  placeholder="Выберите вариант"
                  value={is_pushkin_card as string}
                  onChange={handleChange}
                  allowClearButton
                  options={[
                    { value: 'true', label: 'Да' },
                    { value: 'false', label: 'Нет' },
                  ]}
                />
              </FormItemPanel>
              <div className="z-10">
                <DateRangePicker
                  title={'Время начала сеансов'}
                  withTime={true}
                  from={startDatetimeFrom}
                  setFrom={setStartDatetimeFrom}
                  fromFilterKey={'start_datetime__from'}
                  to={startDatetimeTo}
                  setTo={setStartDatetimeTo}
                  toFilterKey={'start_datetime__to'}
                  filters={filters}
                  setFilters={setFilters}
                />
              </div>
            </FiltersWrapper>
          )}
          {events && events.length ? (
            <>
              <div className="custom-scrollbar overflow-auto pb-2">
                <table className="w-full">
                  <thead>
                    <tr className="border-y-1 border-color-steel-gray-80">
                      {onCheckboxesChange && <th></th>}
                      {activeHeaders.map((header) => (
                        <th
                          key={header.value}
                          className="text-color-gray-600 uppercase py-4 whitespace-nowrap"
                        >
                          <Title
                            className="flex text-sm ml-8 max-w-fit gap-1 items-center cursor-pointer"
                            level="2"
                          >
                            {header.label}
                          </Title>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {events &&
                      events.map(
                        ({
                          id,
                          name,
                          description,
                          preview_url,
                          category,
                          places,
                          is_enabled,
                          rating,
                          seances,
                          provider,
                          label_top,
                          label_bottom,
                          source_owner,
                          data_enricher,
                          changed_from_admin,
                          is_pushkin_card,
                        }) => (
                          <tr
                            onClick={() =>
                              window.open(
                                ADMIN_EVENTS_ROUTE + `/edit/${id}`,
                                '_blank',
                              )
                            }
                            key={id}
                            className="cursor-pointer transition-colors hover:bg-color-steel-gray-20 h-10"
                          >
                            {onCheckboxesChange && (
                              <td
                                className="py-1"
                                onClick={(event) => event.stopPropagation()}
                              >
                                <Checkbox
                                  checked={checkedElements.includes(id)}
                                  onChange={() => handleCheckedItem(id)}
                                />
                              </td>
                            )}
                            {activeHeaders.some((h) => h.value === 'name') && (
                              <td className="py-2" id="name">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {name || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'category',
                            ) && (
                              <td className="py-2" id="category">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {category?.name || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some((h) => h.value === 'city') && (
                              <td className="py-2" id="city">
                                {places.length > 1 ? (
                                  <TextTooltip
                                    className="max-w-lg"
                                    text={createStringFromObjArray(
                                      places,
                                      'city',
                                    )}
                                  >
                                    <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                      {places[0].city}{' '}
                                      {countUniqueValues(places, 'city') > 1
                                        ? `+${countUniqueValues(places, 'city') - 1}`
                                        : ''}
                                    </Text>
                                  </TextTooltip>
                                ) : (
                                  <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                    {places[0]?.city || '-'}
                                  </Text>
                                )}
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'place_name',
                            ) && (
                              <td className="py-2" id="place_name">
                                {places.length > 1 ? (
                                  <TextTooltip
                                    className="max-w-lg"
                                    text={createStringFromObjArray(
                                      places,
                                      'name',
                                    )}
                                  >
                                    <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                      {places[0].name}{' '}
                                      {countUniqueValues(places, 'name') > 1
                                        ? `+${countUniqueValues(places, 'name') - 1}`
                                        : ''}
                                    </Text>
                                  </TextTooltip>
                                ) : (
                                  <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                    {places[0]?.name || '-'}
                                  </Text>
                                )}
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'is_enabled',
                            ) && (
                              <td className="py-2" id="is_enabled">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {is_enabled ? 'Активно' : 'Неактивно'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'provider',
                            ) && (
                              <td className="py-2" id="provider">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {provider ? provider : 'Нет'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'rating',
                            ) && (
                              <td className="py-2" id="rating">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {rating ? rating : 'Нет'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'preview_url',
                            ) && (
                              <td className="py-2" id="preview_url">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {preview_url?.length > 0 ? 'Есть' : 'Нет'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'description',
                            ) && (
                              <td className="py-2" id="description">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {description?.length > 0 ? 'Есть' : 'Нет'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'seances',
                            ) && (
                              <td className="py-2" id="seances">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {seances.length > 0 ? seances.length : 'Нет'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'label_top',
                            ) && (
                              <td className="py-2" id="label_top">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {label_top?.name ? label_top.name : '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'label_bottom',
                            ) && (
                              <td className="py-2" id="label_bottom">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {label_bottom?.name ? label_bottom.name : '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'source_owner',
                            ) && (
                              <td className="py-2" id="source_owner">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {source_owner ? source_owner : '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'data_enricher',
                            ) && (
                              <td className="py-2" id="data_enricher">
                                {data_enricher?.length ? (
                                  <TextTooltip
                                    text={data_enricher.join(', ')}
                                    className="max-w-lg"
                                  >
                                    <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                      {`${data_enricher.join(', ').substring(0, 30)}${data_enricher.join(', ').length > 30 ? '...' : ''}`}
                                    </Text>
                                  </TextTooltip>
                                ) : (
                                  <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                    {'-'}
                                  </Text>
                                )}
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'changed_from_admin',
                            ) && (
                              <td className="py-2" id="changed_from_admin">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {changed_from_admin === true ? 'Да' : 'Нет'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'is_pushkin_card',
                            ) && (
                              <td className="py-2" id="is_pushkin_card">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {is_pushkin_card === true ? 'Да' : 'Нет'}
                                </Text>
                              </td>
                            )}
                          </tr>
                        ),
                      )}
                  </tbody>
                </table>
              </div>
              {data && data?.count > 10 ? (
                <PaginationComponent
                  count={data?.count}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  items={events}
                />
              ) : null}
            </>
          ) : (
            <Div>
              <Placeholder
                icon={<Icon24ListDeleteOutline />}
                header={'Мероприятия не найдены'}
              >
                Попробуйте изменить критерии поиска
              </Placeholder>
            </Div>
          )}
        </Group>
      )}
      <ModalRoot activeModal={openModal}>
        <ModalCard id="show" onClose={() => setOpenModal('')}>
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black" level="2">
              Опубликовать мероприятие
            </Title>
            <Text className="text-color-gray-600">
              Все выбранные мероприятия будут опубликованы
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={() => setOpenModal('')}
              >
                Отмена
              </Button>
              <Button
                className="vkui-edit-button-primary"
                mode="primary"
                size="m"
                onClick={() => console.log('show')}
              >
                Опубликовать
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard>
        <ModalCard id="hidden" onClose={() => setOpenModal('')}>
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black" level="2">
              Скрыть мероприятия
            </Title>
            <Text className="text-color-gray-600">
              Все выбранные мероприятия будут скрыты
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={() => setOpenModal('')}
              >
                Отмена
              </Button>
              <Button
                className="vkui-edit-button-primary"
                mode="primary"
                size="m"
                onClick={() => console.log('hidden')}
              >
                Скрыть
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard>
        <ModalCard id="closeReviews" onClose={() => setOpenModal('')}>
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black" level="2">
              Закрыть отзывы
            </Title>
            <Text className="text-color-gray-600">
              У выбранных мероприятий будут закрыты отзывы
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={() => setOpenModal('')}
              >
                Отмена
              </Button>
              <Button
                className="vkui-edit-button-primary"
                mode="primary"
                size="m"
                onClick={() => console.log('hidden')}
              >
                Закрыть
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard>
        <ModalCard id="closeSelling" onClose={() => setOpenModal('')}>
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black" level="2">
              Закрыть продажу билетов
            </Title>
            <Text className="text-color-gray-600">
              У выбранных мероприятий будет закрыта продажа билетов
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={() => setOpenModal('')}
              >
                Отмена
              </Button>
              <Button
                className="vkui-edit-button-primary"
                mode="primary"
                size="m"
                onClick={() => console.log('hidden')}
              >
                Закрыть
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard>
      </ModalRoot>
    </>
  );
};

export default Events;
