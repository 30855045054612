export interface IEventsProps {
	onCheckboxesChange?: (checkedElements: string[]) => void;
	isForRankings?: boolean;
}

export interface IEvent {
	id: string;
	name: string;
	description: string;
	preview_url: string;
	provider: string;
	is_enabled: string;
	rating: number;
	seances: string[];
	seances_exists: string;
	category: {
		name: string;
	};
	places: Array<{
		city: string;
		name: string;
	}>;
	label_top: {
		id: string;
		name: string;
		type: string;
	};
	label_bottom: {
		id: string;
		name: string;
		type: string;
	};
	source_owner: string;
	data_enricher: string[];
	changed_from_admin: boolean;
	is_pushkin_card: boolean;
}

export type IEventEdit = {
	id: string;
	slug: string;
	name: string;
	description: string;
	short_description: string;
	age_category: string;
	organizer: string;
	provider_id: number;
	provider: string;
	vk_url: string;
	rating: number;
	preview_url: string;
	preview_url_original: string;
	image_urls: string[];
	is_enabled: boolean;
	category_id: string;
	countries: string[];
	year: number;
	category: {
		id: string;
		name: string;
	};
	label_top: {
		id: string;
		name: string;
	};
	label_bottom: {
		id: string;
		name: string;
	};
	genres: Array<{
		id: string;
		name: string;
	}>;
	trailer: {
		thumbnail: string;
		preview: string;
		hd: string;
	};
	poster: string;
	source_owner: string;
	data_enricher: string[];
	changed_from_admin: string[];
	persons: IEventPerson[];
	is_pushkin_card: boolean;
	changed_from_admin_at: string;
};

export interface IEventSeance {
	id: string;
	place_id: string;
	place_name: string;
	city: string;
	start: string;
	duration_minutes: number;
	formats: Array<{
		title: string;
	}>;
	tickets_count: number;
	widget_url: string;
	is_pushkin_card: boolean;
}

export interface IEventLink {
	city_name: string;
	web_url: string;
	miniapp_url: string;
}

export interface IEventPerson {
	id: string;
	full_name: string;
}

export enum TabId {
	info = 'info',
	sessions = 'sessions',
	media = 'media',
	persons = 'persons',
	links = 'links',
}
