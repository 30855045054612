import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Title } from '@vkontakte/vkui';
import Layout from 'layout';
import PanelTitle from 'components/common/PanelTitle';
import OrdersEdit from 'components/Orders/OrdersEdit';
import Orders from 'components/Orders/Orders';

const OrdersPage: FC = () => {
  const { methodType } = useParams();

  return (
    <Layout>
      {!methodType ? (
        <PanelTitle withArrow={false}>
          <Title className="text-color-black">Заказы</Title>
        </PanelTitle>
      ) : null}
      {methodType ? <OrdersEdit /> : <Orders />}
    </Layout>
  );
};

export default OrdersPage;
