import React from 'react';
import { CustomSelectOption, CustomSelectOptionProps } from '@vkontakte/vkui';
import { useInView } from 'framer-motion';

type CustomSelectOptionWithInViewProps = {
	callback?: VoidFunction;
} & CustomSelectOptionProps;

const CustomSelectOptionWithInView = ({
	callback,
	...props
}: CustomSelectOptionWithInViewProps) => {
	const ref = React.useRef(null);
	const isInView = useInView(ref, { once: true });

	React.useEffect(() => {
		if (isInView) {
			callback?.();
		}
	}, [isInView, isInView]);

	return (
		<div ref={ref}>
			<CustomSelectOption {...props} />
		</div>
	);
};

export default CustomSelectOptionWithInView;
