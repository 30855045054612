import { Div } from '@vkontakte/vkui';
import React, { type ReactNode, useEffect, useMemo } from 'react';
import AdminHeader from './components/Header/AdminHeader';
import { useCheckedElements } from './providers/CheckedElementsContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Snackbar } from './components/common/Snackbar';
import { useSnackbar } from 'lib/hooks/useSnackbar';
import { useHistory } from 'lib/hooks/useHistory';
import { useAuth } from 'providers/AuthProvider';
import { ErrorBoundary } from 'react-error-boundary';
import { checkUserRights } from 'lib/utils/checkUserRights';
import ErrorPage from 'pages/ErrorPage';
import { ADMIN_LOGIN_ROUTE } from 'lib/constants';

interface ILayout {
  children: ReactNode;
}

const Layout = ({ children }: ILayout) => {
  const { clearCheckedElements } = useCheckedElements();
  const { pathname } = useLocation();
  const { snackbarContent, setSnackbarContent } = useSnackbar();
  const { history, setHistory, getPreviousRoute } = useHistory();
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    clearCheckedElements();
    setHistory((prev) => [...prev, pathname]);
  }, [pathname]);

  useEffect(() => {
    const prev = getPreviousRoute();

    if (!prev?.includes('/addNew') && !prev?.includes('/edit/')) {
      setSnackbarContent({
        message: '',
        type: 'success',
      });
    }
  }, [history]);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');

    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const snackbar = useMemo(
    () => (
      <Snackbar
        isShow={!!snackbarContent.message}
        message={snackbarContent.message}
        type={snackbarContent.type}
      />
    ),
    [snackbarContent],
  );

  useEffect(() => {
    if (!user) {
      navigate(ADMIN_LOGIN_ROUTE);
    } else {
      if (!user.role) {
        localStorage.removeItem('user');
        setUser(null);
      } else {
        checkUserRights(user.role, location.pathname);
      }
    }
  }, [user, navigate]);

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      {user ? (
        <Div className="py-8 px-16">
          <AdminHeader />
          {children}
          {snackbar}
        </Div>
      ) : (
        <Div className="h-screen">
          {children}
          {snackbar}
        </Div>
      )}
    </ErrorBoundary>
  );
};

export default Layout;
