import React from 'react';
import { Button } from '@vkontakte/vkui';
import { Icon20FilterOutline } from '@vkontakte/icons';

interface IFiltersButton {
  openFilters: boolean;
  toggle: () => void;
}

const FiltersButton = ({ openFilters, toggle }: IFiltersButton) => {
  return (
    <Button
      size="l"
      style={
        openFilters
          ? { color: '#2d81e0', border: '1px solid #2d81e0', height: '42px' }
          : { color: '#818C99', border: '1px solid #e5e7eb', height: '42px' }
      }
      onClick={toggle}
      mode="tertiary"
      className="filters-button rounded-md min-w-fit"
      before={
        <Icon20FilterOutline color={openFilters ? '#2d81e0' : '#818C99'} />
      }
    >
      Фильтры
    </Button>
  );
};

export default FiltersButton;
