export const eventLabelHeaders = [
	{
		order: 1,
		label: 'Название',
		value: 'name',
	},
	{
		order: 2,
		label: 'Тип',
		value: 'type',
	},
	{
		order: 3,
		label: 'Статус',
		value: 'is_active',
	},
];

export const eventLabelFilters = {
	ordering: '',
	q: '',
};

export const eventLabelBlank = {
	id: '',
	name: '',
	type: 'event',
	is_active: true,
};

export const eventLabelFieldNameMap = {
	type: 'Тип',
	name: 'Название',
	isNewChecked: 'Активно',
};
