export const userHeaders = [
	{
		order: 1,
		label: 'ФИО',
		value: 'name',
	},
	{
		order: 2,
		label: 'Почта',
		value: 'email',
	},
	{
		order: 3,
		label: 'Статус',
		value: 'status',
	},
	{
		order: 4,
		label: 'Роль',
		value: 'role',
	},
];

export const userFilters = {
	q: '',
	status: '',
	role: '',
};

export const userBlank = {
	id: '',
	firstname: '',
	lastname: '',
	middlename: '',
	email: '',
	phone: '',
	status: '',
	role: '',
	password: '',
};

export const userFieldNameMap = {
	lastname: 'Фамилия',
	firstname: 'Имя',
	middlename: 'Отчество',
	newEmail: 'Почта пользователя',
	email: 'Почта пользователя',
	newPassword: 'Пароль пользователя',
	password: 'Пароль пользователя',
	phone: 'Номер телефона',
	status: 'Статус',
	role: 'Роль',
};
