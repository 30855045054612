import { useEffect, useState } from 'react';

interface UseFormOptions<T> {
  initialValues: T;
  onSubmit?: (data: T) => void;
  excludedArrayFields?: string[];
}

interface UseFormResult<T> {
  formData: T;
  isError: boolean;
  errorItems: string[];
  handleChange: (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  validate: () => void;
  reset: () => void;
  isDisabled: boolean;
}

// eslint-disable-next-line
const useFormItems = <T extends Record<string, any>>({
  initialValues,
  onSubmit,
  excludedArrayFields = [],
}: UseFormOptions<T>): UseFormResult<T> => {
  const [formData, setFormData] = useState<T>(initialValues);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorItems, setErrorItems] = useState<string[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (errorItems.includes(name)) {
      setErrorItems((prevErrorItems) =>
        prevErrorItems.filter((item) => item !== name),
      );
      if (errorItems.length === 0) setIsError(false);
    }
  };

  const validate = () => {
    const excludedFields: Array<keyof T> = excludedArrayFields;
    const fieldsToCheck = Object.keys(formData) as Array<keyof T>;
    const emptyFields = fieldsToCheck.filter(
      (field) => !excludedFields.includes(field) && formData[field] === '',
    );

    if (emptyFields.length > 0) {
      setErrorItems(emptyFields as string[]);
      setIsError(true);
      console.log(
        `Ошибка useFormItems validate(): emptyFields.length > 0 есть незаполненные поля, проверить excludedArrayFields на наличие необязательных полей`,
      );
    } else {
      setIsError(false);
      setErrorItems([]);
      onSubmit && onSubmit(formData);
    }
  };

  const reset = () => {
    setFormData(initialValues);
    setErrorItems([]);
    setIsError(false);
  };

  useEffect(() => {
    setFormData(initialValues);
  }, [initialValues]);

  useEffect(() => {
    setIsDisabled(JSON.stringify(initialValues) === JSON.stringify(formData));
  }, [JSON.stringify(formData)]);

  return {
    formData,
    isError,
    errorItems,
    handleChange,
    validate,
    reset,
    isDisabled,
  };
};

export default useFormItems;
