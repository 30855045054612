interface FieldError {
	location: string;
	field: string;
	element_index: string;
	message: string;
}

interface ErrorObject {
	code: number;
	error_message: string;
	field_errors: FieldError[];
	service_code: string;
}

function getErrorMessage(
	error: ErrorObject,
	fieldNameMap: Record<string, string>,
): string {
	if (error.field_errors && error.field_errors.length > 0) {
		const fieldError = error.field_errors[0];
		const fieldName = fieldNameMap[fieldError.field] || fieldError.field;
		return `${fieldName}: ${fieldError.message}`;
	} else if (error.error_message) {
		return error.error_message;
	} else {
		return 'Непредвиденная ошибка, обратитесь к администратору';
	}
}

export default getErrorMessage;
