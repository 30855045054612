import React from 'react';
import { useEffect, useState } from 'react';
import { Pagination, AdaptivityProvider } from '@vkontakte/vkui';
import { useNavigate } from 'react-router';

interface IPaginationComponent<T> {
	count?: number;
	items: T[];
	currentPage: number;
	setCurrentPage: (page: number) => void;
	pageSize?: number;
}

const SIZE_PAGE_DEFAULT = 10;

const PaginationComponent = <T,>({
	items,
	currentPage,
	count,
	setCurrentPage,
	pageSize = SIZE_PAGE_DEFAULT,
}: IPaginationComponent<T>) => {
	const siblingCount = 1;
	const boundaryCount = 1;
	const [totalPages, setTotalPages] = useState<number>();
	const disabled = false;
	const navigate = useNavigate();

	useEffect(() => {
		const calculateTotalPages = Math.ceil((count || items.length) / pageSize);
		setTotalPages(calculateTotalPages);
	}, [items]);

	const onChange = (page: number) => {
		const params = new URLSearchParams(location.search);
		params.set('page', `"${page}"`);
		const paramsString = params.toString().replace(/\+/g, '%20');
		navigate(`?${paramsString}`);
		setCurrentPage(page);
	};

	return (
		<AdaptivityProvider>
			<div className="flex w-full justify-between pt-4">
				<span className="pointer-events-none pr-2 pt-2 text-sm text-color-gray-300 opacity-0">
					Показаны{' '}
					{Math.min(((currentPage ?? 1) - 1) * pageSize + 1, count ?? 0)}
					{Math.min((currentPage ?? 1) * pageSize, count ?? 0)} из {count ?? 0}{' '}
					записей
				</span>
				<Pagination
					className="vkui-edit-pagination"
					currentPage={currentPage}
					siblingCount={siblingCount}
					boundaryCount={boundaryCount}
					totalPages={totalPages}
					disabled={disabled}
					onChange={onChange}
				/>
				<span className="pr-2 pt-2 text-sm text-color-gray-300">
					Показаны{' '}
					{Math.min(((currentPage ?? 1) - 1) * pageSize + 1, count ?? 0)}-
					{Math.min((currentPage ?? 1) * pageSize, count ?? 0)} из {count ?? 0}{' '}
					записей
				</span>
			</div>
		</AdaptivityProvider>
	);
};

export default PaginationComponent;
