import React from 'react';
import { useParams } from 'react-router-dom';
import { Title } from '@vkontakte/vkui';
import { directories } from 'lib/constants';
import Layout from 'layout';
import PanelTitle from 'components/common/PanelTitle';
import Directories from 'components/directories/Directories';
import EventTagsDirectoryEdit from 'components/directories/EventTagsDirectory/EventTagsDirectoryEdit';
import TypesDirectoryEdit from 'components/directories/TypesDirectory/TypesDirectoryEdit';
import GenresDirectoryEdit from 'components/directories/GenresDirectory/GenresDirectoryEdit';
import EventLabelsDirectoryEdit from 'components/directories/EventLabelsDirectory/EventLabelsDirectoryEdit';

const DirectoriesPage = () => {
  const { directoriesType, methodType } = useParams();
  const title = directoriesType
    ? directories.find((item) => item.link.includes(directoriesType))?.title
    : 'Справочники';

  const handleReturnPanel = () => {
    if (methodType) {
      return editPages[directoriesType as keyof typeof editPages];
    } else {
      return (
        <>
          <PanelTitle withArrow={false}>
            <Title className="text-color-black">{title}</Title>
          </PanelTitle>
          <Directories />
        </>
      );
    }
  };

  const editPages = {
    event_tags: <EventTagsDirectoryEdit />,
    types: <TypesDirectoryEdit />,
    genres: <GenresDirectoryEdit />,
    event_labels: <EventLabelsDirectoryEdit />,
  };

  return <Layout>{handleReturnPanel()}</Layout>;
};

export default DirectoriesPage;
