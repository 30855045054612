import axios, { AxiosError, AxiosInstance } from 'axios';
import { getUserToken } from 'lib/utils/authUtils';

const createAxiosInstance = (
  sourceUrl: string,
  contentType: string,
  noAuth?: boolean,
): AxiosInstance => {
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: sourceUrl,
    headers: {
      'Content-Type': contentType,
    },
  });

  // Resizer service doesn't pass allow-credentials header on OPTIONS request,
  // so requests with Authorization header fail with CORS error.
  if (!noAuth) {
    axiosInstance.interceptors.request.use(
      (config) => {
        const token = getUserToken('access');

        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        } else {
          return Promise.reject(new Error('Пользователь не авторизован'));
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  const errorInterceptor = (error: AxiosError) => {
    console.error(
      `Error ${error.config?.method} ${error.config?.url}:`,
      error.message,
    );
    return Promise.reject(error);
  };

  axiosInstance.interceptors.response.use(
    (response) => response,
    errorInterceptor,
  );

  return axiosInstance;
};

export { createAxiosInstance };
