import { IOrdering, ITableHeader } from 'lib/types';
import React from 'react';

interface ICustomSortIconProps {
  sortDirection: IOrdering;
  activeHeader: ITableHeader;
}

export default function CustomSortIcon({
  sortDirection,
  activeHeader,
}: ICustomSortIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      {activeHeader.value === sortDirection.key ? (
        <>
          <path
            d="M9.03431 5H2.96569C2.60932 5 2.43086 4.56914 2.68284 4.31716L5.71716 1.28284C5.87337 1.12663 6.12663 1.12663 6.28284 1.28284L9.31716 4.31716C9.56914 4.56914 9.39068 5 9.03431 5Z"
            fill={sortDirection.value === 'asc' ? '#5181B8' : '#DDE1ED'}
          />
          <path
            d="M2.96569 7H9.03431C9.39068 7 9.56914 7.43086 9.31716 7.68284L6.28284 10.7172C6.12663 10.8734 5.87337 10.8734 5.71716 10.7172L2.68284 7.68284C2.43086 7.43086 2.60932 7 2.96569 7Z"
            fill={sortDirection.value === 'desc' ? '#5181B8' : '#DDE1ED'}
          />
        </>
      ) : (
        <>
          <path
            d="M9.03431 5H2.96569C2.60932 5 2.43086 4.56914 2.68284 4.31716L5.71716 1.28284C5.87337 1.12663 6.12663 1.12663 6.28284 1.28284L9.31716 4.31716C9.56914 4.56914 9.39068 5 9.03431 5Z"
            fill={'#DDE1ED'}
          />
          <path
            d="M2.96569 7H9.03431C9.39068 7 9.56914 7.43086 9.31716 7.68284L6.28284 10.7172C6.12663 10.8734 5.87337 10.8734 5.71716 10.7172L2.68284 7.68284C2.43086 7.43086 2.60932 7 2.96569 7Z"
            fill={'#DDE1ED'}
          />
        </>
      )}
    </svg>
  );
}
