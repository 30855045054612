import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IParseDateFromProps {
  date: string;
  format: 'day' | 'time' | 'daytime';
  utc: boolean;
}

export const parseDateFrom = ({
  date,
  format,
  utc,
}: IParseDateFromProps): string => {
  let parsedDate = '';

  switch (format) {
    case 'day':
      parsedDate = utc
        ? dayjs(date).format('DD.MM.YYYY')
        : dayjs.utc(date).format('DD.MM.YYYY');
      break;

    case 'time':
      parsedDate = utc
        ? dayjs(date).format('HH:mm')
        : dayjs.utc(date).format('HH:mm');
      break;

    case 'daytime':
      parsedDate = utc
        ? dayjs(date).format('DD.MM.YY, HH:mm')
        : dayjs.utc(date).format('DD.MM.YY, HH:mm');
      break;

    default:
      break;
  }

  return parsedDate;
};

interface IFormatDateToProps {
  date: Date;
  format: 'dayFrom' | 'dayTo' | 'daytime';
}

export const formatDateTo = ({ date, format }: IFormatDateToProps): string => {
  let formattedDate = '';

  switch (format) {
    case 'dayFrom':
      formattedDate = `${dayjs(date).format('YYYY-MM-DD')}T00:00:00`;
      break;

    case 'dayTo':
      formattedDate = `${dayjs(date).format('YYYY-MM-DD')}T23:59:59`;
      break;

    case 'daytime':
      formattedDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ss');
      break;

    default:
      break;
  }

  return formattedDate;
};
