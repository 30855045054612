import { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export interface IPortal {
  // eslint-disable-next-line
  children: any;
  isEnable?: boolean;
}

export const Portal: FC<IPortal> = (props) => {
  const { children, isEnable = true } = props;

  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  if (!isEnable) {
    return children;
  }

  return mounted
    ? // eslint-disable-next-line
      createPortal(children, document.getElementById('portal')!)
    : null;
};
