export const arraysAreEqual = (arr1: string[] | null, arr2: string[] | null) => {
  if (arr1 === null || arr2 === null) {
    return arr1 === arr2;
  }

  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.every((value, index) => value === arr2[index]);
};