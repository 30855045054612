import React, { FC, useEffect } from 'react';
import {
  Icon48ArrowLeftOutline,
  Icon48ArrowRightOutline,
} from '@vkontakte/icons';
import { Div, IconButton } from '@vkontakte/vkui';
import { handleDisplayedImageIndexChange } from 'lib/utils/imageFile.utils';

interface ImageGalleryProps {
  selectedImages: string[];
  displayedImageIndex: number;
  setDisplayedImageIndex: (index: number) => void;
}

const ImageGallery: FC<ImageGalleryProps> = ({
  selectedImages,
  displayedImageIndex,
  setDisplayedImageIndex,
}) => {
  const handleIndexChange = (action: 'next' | 'prev') => {
    handleDisplayedImageIndexChange(action, {
      selectedImages,
      displayedImageIndex,
      setDisplayedImageIndex,
    });
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        handleIndexChange('prev');
      }
      else if (event.key === 'ArrowRight') {
        handleIndexChange('next');
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleIndexChange]);

  return (
    <Div className="w-full h-full flex items-center justify-center relative">
      <Div
        onClick={() => handleIndexChange('prev')}
        className="transition-all cursor-pointer bg-slate-950 bg-opacity-20 hover:bg-opacity-40 h-14
        w-14 rounded-full absolute left-4 flex justify-center items-center "
      >
        <IconButton hasHover={false} aria-label={'Предыдущее изображение'}>
          <Icon48ArrowLeftOutline width={32} height={32} />
        </IconButton>
      </Div>

      <img
        src={selectedImages[displayedImageIndex]}
        className="p-4 rounded-3xl object-contain max-h-[640px]"
      />

      <Div
        onClick={() => handleIndexChange('next')}
        className="transition-all cursor-pointer bg-slate-950 bg-opacity-20 hover:bg-opacity-40
        h-14 w-14 rounded-full absolute right-4 flex justify-center items-center 0"
      >
        <IconButton hasHover={false} aria-label={'Следюущее изображение'}>
          <Icon48ArrowRightOutline width={32} height={32} />
        </IconButton>
      </Div>
    </Div>
  );
};

export default ImageGallery;
