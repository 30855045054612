export const genreHeaders = [
	{
		order: 1,
		label: 'Название',
		value: 'name',
	},
	{
		order: 2,
		label: 'Тип',
		value: 'category',
	},
	{
		order: 3,
		label: 'Активность',
		value: 'is_active',
	},
];

export const genreFilters = {
	ordering: '',
	q: '',
	category_id: [],
};

export const genreBlank = {
	id: '',
	name: '',
	categories: [],
	is_active: false,
};

export const genresFieldNameMap = {
	name: 'Название',
	categories: 'Тип',
	isNewActive: 'Активно',
};
