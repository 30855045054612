import React from 'react';
import { ChipOption, CustomSelectOption, Spinner } from '@vkontakte/vkui';
import {
	ChipsSelect,
	ChipsSelectProps,
} from '@vkontakte/vkui/dist/components/ChipsSelect/ChipsSelect';
import { CustomSelectOptionWithInView } from './CustomSelectOption';

type ChipsSelectWithLoadedProps<Option extends ChipOption> = {
	onLoadMore: VoidFunction;
	enableLoadMore?: boolean;
	isLoading?: boolean;
} & ChipsSelectProps<Option>;

const ChipsSelectWithLoaded = <Option extends ChipOption>({
	onLoadMore,
	enableLoadMore,
	isLoading,
	...props
}: ChipsSelectWithLoadedProps<Option>) => {
	return (
		<ChipsSelect
			{...props}
			renderOption={(renderProps) => {
				if (
					enableLoadMore &&
					props.options?.at(-1)?.value === renderProps.option?.value
				) {
					return (
						<>
							<CustomSelectOptionWithInView
								{...renderProps}
								callback={onLoadMore}
							/>
							{isLoading && <Spinner size="medium" />}
						</>
					);
				}
				return <CustomSelectOption {...renderProps} />;
			}}
		/>
	);
};

export default ChipsSelectWithLoaded;
