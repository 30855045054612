import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from 'react-router-dom';
import { CheckedElementsProvider } from './providers/CheckedElementsContext';
import { ConfigProvider } from '@vkontakte/vkui';
import './styles.scss';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-quill/dist/quill.snow.css';
import { SnackbarContextProvider } from 'providers/SnackbarContext';
import { HistoryContextProvider } from 'providers/HistoryContext';
import { AuthProvider } from 'providers/AuthProvider';
import { ADMIN_NOT_FOUND_ROUTE, ADMIN_ORDERS_ROUTE } from 'lib/constants';
import { authRoutes, publicRoutes } from 'routes';

const router = createBrowserRouter([
  ...publicRoutes,
  ...authRoutes,
  {
    path: '/',
    loader: () => redirect(ADMIN_ORDERS_ROUTE),
    element: <></>,
  },
  {
    path: '*',
    loader: () => redirect(ADMIN_NOT_FOUND_ROUTE),
    element: <></>,
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <ConfigProvider platform="vkcom" appearance="light" isWebView={true}>
      <AuthProvider>
        <CheckedElementsProvider>
          <HistoryContextProvider>
            <SnackbarContextProvider>
              <RouterProvider router={router} />
            </SnackbarContextProvider>
          </HistoryContextProvider>
        </CheckedElementsProvider>
      </AuthProvider>
    </ConfigProvider>
  </StrictMode>,
);
