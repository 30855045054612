import { Icon24BrowserBack } from '@vkontakte/icons';
import { useNavigate } from 'react-router-dom';
import React from 'react';

interface IArrowBack {
  color?: '#2688EB' | '#99A2AD'
}

const ArrowBack = ({ color = '#2688EB' }: IArrowBack) => {
  const navigate = useNavigate();
  return <Icon24BrowserBack fill={color} onClick={() => navigate(-1)} />;
};

export default ArrowBack;
