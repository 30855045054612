import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Div,
  Group,
  Placeholder,
  Select,
  Spinner,
  Text,
  Title,
} from '@vkontakte/vkui';
import { Icon24ListDeleteOutline, Icon24Add } from '@vkontakte/icons';
import { ITableHeader } from 'lib/types';
import useFetch from 'lib/hooks/useFetch';
import useParams from 'lib/hooks/useParams';
import { ApiResponse } from 'lib/api/api';
import { parseFiltersUrl } from 'lib/utils/parseFunction';
import { TextTooltip } from '@vkontakte/vkui/dist/components/TextTooltip/TextTooltip';
import {
  countUniqueValues,
  createStringFromObjArray,
} from 'lib/utils/arrayOfObjects';
import { rankingFilters, rankingHeaders } from './constants.rankings';
import TableSetup from 'components/common/TableSetup';
import { parseDateFrom } from 'lib/utils/date.utils';
import { ADMIN_RANKING_ROUTE } from 'lib/constants';
import { IRanking } from './types.rankings';
import FiltersButton from 'components/common/FiltersButton';
import InputSearch from 'components/common/InputSearch';
import FiltersWrapper from 'components/common/FiltersWrapper';
import FormItemPanel from 'components/common/FormItemPanel';
import PaginationComponent from 'components/common/Pagination';
import TypeChipsSelect from 'components/common/TypeChipsSelect';
import CityChipsSelect from 'components/common/CityChipsSelect';

const Ranking: FC = () => {
  const [activeHeaders, setActiveHeaders] = useState<ITableHeader[]>([]);
  const [rankings, setRankings] = useState<IRanking[]>([]);
  const { data, loading, fetchData } = useFetch<ApiResponse<IRanking[]>>();
  
  const {
    filters,
    setFilters,
    addFiltersAndUpdateUrl,
    reset,
    handleChange,
    openFilters,
    handletoggleFilters,
    currentPage,
    setCurrentPage,
  } = useParams(rankingFilters);

  const { category_id, cities, is_active } = filters;

  const handleChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilters({ ...filters, q: value });
  };

  const handleResetFilters = useCallback(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    const queryParams = location.search ? parseFiltersUrl(location.search) : '';

    fetchData({
      path: `/ranking/rankings/?page=${currentPage - 1}&${queryParams}`,
      method: 'get',
    });
  }, [location.search, currentPage]);

  useEffect(() => {
    if (!loading && data?.results) {
      setRankings(data.results);
    }
  }, [loading, location.search]);

  useEffect(() => {
    const storedHeaders = localStorage.getItem('columnOrder_ranking');
    if (storedHeaders) {
      setActiveHeaders(JSON.parse(storedHeaders));
    } else {
      setActiveHeaders(rankingHeaders);
    }
  }, []);

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <Group>
          <Div className="flex items-center gap-2">
            <Link to={ADMIN_RANKING_ROUTE + `/addNew`}>
              <Button
                mode="primary"
                className="vkui-edit-button-primary min-w-fit"
                size="m"
                before={<Icon24Add />}
              >
                Добавить
              </Button>
            </Link>
            <InputSearch
              onSubmit={addFiltersAndUpdateUrl}
              value={filters.q as string}
              onChange={handleChangeSearchValue}
            />
            <FiltersButton
              openFilters={openFilters}
              toggle={handletoggleFilters}
            />
            <TableSetup
              tableId="ranking"
              headers={rankingHeaders}
              onActiveHeadersChange={setActiveHeaders}
            />
          </Div>
          {openFilters ? (
            <FiltersWrapper
              reset={handleResetFilters}
              addFiltersAndUpdateUrl={addFiltersAndUpdateUrl}
            >
              <TypeChipsSelect
                category_id={category_id}
                filters={filters}
                setFilters={setFilters}
                reset={handleResetFilters}
                filterKey={'category_id'}
              />
              <CityChipsSelect
                city_id={cities}
                filters={filters}
                setFilters={setFilters}
                reset={handleResetFilters}
                filterKey={'cities'}
              />
              <FormItemPanel top="Статус" className="p-0">
                <Select
                  className="vkui-select"
                  name="is_active"
                  placeholder="Укажите статус"
                  value={String(is_active)}
                  onChange={handleChange}
                  options={[
                    { label: 'Активно', value: 'true' },
                    { label: 'Неактивно', value: 'false' },
                  ]}
                />
              </FormItemPanel>
            </FiltersWrapper>
          ) : null}

          {rankings && rankings.length ? (
            <>
              <div className="custom-scrollbar overflow-auto pb-2">
                <table className="w-full">
                  <thead>
                    <tr className="border-y-1 border-color-steel-gray-80">
                      {activeHeaders.map((header) => (
                        <th
                          key={header.value}
                          className="text-color-gray-600 uppercase py-4 whitespace-nowrap"
                        >
                          <Title
                            className="flex text-sm ml-8 max-w-fit gap-1 items-center cursor-pointer"
                            level="2"
                          >
                            {header.label}
                          </Title>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rankings.map(
                      ({
                        id,
                        category,
                        cities_obj,
                        is_active,
                        events,
                        created_at,
                      }) => (
                        <tr
                          key={id}
                          onClick={() =>
                            window.open(
                              ADMIN_RANKING_ROUTE + `/edit/${id}`,
                              '_blank',
                            )
                          }
                          className="cursor-pointer transition-colors hover:bg-color-steel-gray-20 h-10"
                        >
                          {activeHeaders.some(
                            (h) => h.value === 'category',
                          ) && (
                            <td className="py-2" id="category">
                              <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                {category?.name || '-'}
                              </Text>
                            </td>
                          )}
                          {activeHeaders.some(
                            (h) => h.value === 'cities_obj',
                          ) && (
                            <td className="py-2" id="cities_obj">
                              {cities_obj && cities_obj.length > 1 ? (
                                <TextTooltip
                                  className="max-w-lg"
                                  text={createStringFromObjArray(
                                    cities_obj,
                                    'name',
                                  )}
                                >
                                  <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                    {cities_obj[0].name}{' '}
                                    {countUniqueValues(cities_obj, 'name') > 1
                                      ? `+${countUniqueValues(cities_obj, 'name') - 1}`
                                      : ''}
                                  </Text>
                                </TextTooltip>
                              ) : (
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {cities_obj &&
                                    (cities_obj[0] ? cities_obj[0].name : '-')}
                                </Text>
                              )}
                            </td>
                          )}
                          {activeHeaders.some(
                            (h) => h.value === 'is_active',
                          ) && (
                            <td className="py-2" id="is_active">
                              <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                {is_active !== null
                                  ? is_active
                                    ? 'Активно'
                                    : 'Неактивно'
                                  : '-'}
                              </Text>
                            </td>
                          )}
                          {activeHeaders.some(
                            (h) => h.value === 'events_count',
                          ) && (
                            <td className="py-2" id="events_count">
                              <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                {events.length}
                              </Text>
                            </td>
                          )}
                          {activeHeaders.some(
                            (h) => h.value === 'created_at',
                          ) && (
                            <td className="py-2" id="created_at">
                              <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                {created_at &&
                                  parseDateFrom({
                                    date: created_at,
                                    format: 'day',
                                    utc: false,
                                  })}
                              </Text>
                            </td>
                          )}
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
              </div>
              {data && data?.count > 10 ? (
                <PaginationComponent
                  count={data?.count}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  items={rankings}
                />
              ) : null}
            </>
          ) : (
            <Div>
              <Placeholder
                icon={<Icon24ListDeleteOutline />}
                header={'Закрепления не найдены'}
              >
                Попробуйте изменить критерии поиска
              </Placeholder>
            </Div>
          )}
        </Group>
      )}
    </>
  );
};

export default Ranking;
