import React, { FC, useEffect, useState } from 'react';
import useFetch from 'lib/hooks/useFetch';
import { IChip } from 'lib/types';
import { ApiResponse } from 'lib/api/api';
import { getUserRole } from 'lib/utils/authUtils';
import { IType } from 'components/directories/TypesDirectory/types.typesDirectory';
import { ChipsSelectWithLoaded } from 'components/common/ChipsSelectWithLoaded';

const PAGE_SIZE_GENRES = 24;

type SelectGenresProps = {
	categoryId?: string | null;
	newGenres: IChip[];
	setNewGenres: React.Dispatch<React.SetStateAction<IChip[]>>;
};

const SelectGenres: FC<SelectGenresProps> = ({
	categoryId,
	newGenres,
	setNewGenres,
}) => {
	const userRole = getUserRole();

	const {
		data: genresOptionsData,
		loading: genresOptionsLoading,
		fetchData: genresOptionsFetch,
		error: genresOptionsError,
	} = useFetch<ApiResponse<IType[]>>();

	const [allGenres, setAllGenres] = useState<IChip[]>([]);

	const fetchGenres = async (page?: number) => {
		if (genresOptionsData?.next || page !== undefined) {
			genresOptionsFetch({
				path: `/event/genres/?is_active=true&page=${page ?? genresOptionsData?.next}&page_size=${PAGE_SIZE_GENRES}&category_id=${categoryId}`,
				method: 'get',
			});
		}
	};

	useEffect(() => {
		if (!genresOptionsLoading && !genresOptionsError) {
			if (genresOptionsData?.results.length) {
				const newGenresOptions = genresOptionsData.results.map(
					({ id, name }) => ({
						label: name,
						value: id,
					}),
				);

				setAllGenres([...allGenres, ...newGenresOptions]);
			}
		}
	}, [genresOptionsLoading]);

	useEffect(() => {
		setAllGenres([]);
		if (categoryId) {
			fetchGenres(0);
		}
	}, [categoryId]);

	return (
		<ChipsSelectWithLoaded
			name="genres"
			className="vkui-select"
			id="genres"
			placeholder="Укажите жанр"
			creatable={false}
			value={newGenres || []}
			options={allGenres}
			onChange={setNewGenres}
			disabled={userRole === 'support'}
			enableLoadMore={Boolean(genresOptionsData?.next)}
			isLoading={genresOptionsLoading}
			onLoadMore={fetchGenres}
		/>
	);
};

export default SelectGenres;
