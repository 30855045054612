import React, { Ref, useEffect, useState } from 'react';
import {
  CellButton,
  Checkbox,
  Chip,
  Div,
  Input,
  Spinner,
  Text,
} from '@vkontakte/vkui';
import { IChip } from 'lib/types';
import {
  Icon20Dropdown,
  Icon20Search,
  Icon28InfoCircleOutline,
} from '@vkontakte/icons';
import { Popover } from '@vkontakte/vkui/dist/components/Popover/Popover';
import { TextTooltip } from '@vkontakte/vkui/dist/components/TextTooltip/TextTooltip';

interface IChipsSelectComponent {
  placeholder?: string;
  options: IChip[];
  values: IChip[];
  onChange: (values: IChip[]) => void;
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputValue?: string;
  chipClassName?: string;
  listRef?: Ref<HTMLDivElement>;
  loaderRef?: Ref<HTMLDivElement>;
  listInnerRef?: Ref<HTMLDivElement>;
  hasNextPage?: boolean;
  disabled?: boolean;
}

const ChipsSelectComponent = ({
  placeholder,
  options,
  values,
  onChange,
  chipClassName,
  listRef,
  loaderRef,
  hasNextPage,
  onInput,
  inputValue,
  listInnerRef,
  disabled = false,
}: IChipsSelectComponent) => {
  const [shown, setShown] = useState<boolean>(false);
  const [allValues, setAllValues] = useState<IChip[]>(values || []);
  const [formValues, setFormValues] = useState<IChip[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<IChip[]>(options);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const handleChangeValueState = (item: IChip) => {
    if (allValues.find((el) => JSON.stringify(el) === JSON.stringify(item))) {
      const items = allValues.filter(
        (el) => JSON.stringify(el) !== JSON.stringify(item),
      );
      setAllValues(items);
      onChange(items);
    } else {
      setAllValues([...allValues, item]);
      onChange([...allValues, item]);
    }
  };

  useEffect(() => {
    if (allValues.length) {
      if (allValues.length > 1) {
        setFormValues([
          allValues[0],
          {
            label: `+${allValues.length - 1}`,
            value: `+${allValues.length - 1}`,
          },
        ]);
      } else {
        setFormValues([allValues[0]]);
      }
    } else {
      setFormValues([]);
    }
  }, [allValues?.length]);

  useEffect(() => {
    if (values?.length === 0) {
      setFormValues([]);
      setAllValues([]);
    }
  }, [values]);

  return (
    <div
      className={`${disabled ? 'pointer-events-none opacity-50' : 'cursor-pointer'}`}
    >
      <Popover
        action="click"
        shown={shown}
        onShownChange={setShown}
        className="overflow-y-hidden"
        content={
          <Div className="p-0 m-0 w-96">
            <Input
              style={{ outline: 'none' }}
              className="chips-input input-style bg-color-white p-0 m-0"
              placeholder="Поиск"
              defaultValue={inputValue}
              onChange={onInput}
              after={<Icon20Search />}
            />
            <Div
              className="custom-scrollbar p-0 max-h-[380px] overflow-y-scroll"
              getRootRef={listRef}
            >
              <Div
                className="mx-1 px-3 py-0 border-b-1 border-solid border-color-form-border"
                getRootRef={listInnerRef}
              >
                {allValues.map((item) => (
                  <CellButton
                    before={
                      <Checkbox
                        className="pointer-events-none"
                        checked={
                          allValues.filter(
                            (el) => JSON.stringify(el) === JSON.stringify(item),
                          ).length > 0
                        }
                      />
                    }
                    onClick={() => handleChangeValueState(item)}
                    key={item.value}
                  >
                    {item.label}
                  </CellButton>
                ))}
              </Div>
              <Div className="mx-1 px-3 py-0">
                {filteredOptions.length ? (
                  <>
                    {filteredOptions.map((item) => {
                      const index = allValues.findIndex(
                        (value) => value.value === item.value,
                      );
                      if (index > -1) return;

                      return (
                        <div
                          key={item.value}
                          onClick={() => handleChangeValueState(item)}
                          className="cursor-pointer"
                        >
                          <CellButton
                            before={
                              <Checkbox
                                className="pointer-events-none"
                                checked={
                                  allValues.filter(
                                    (el) =>
                                      JSON.stringify(el) ===
                                      JSON.stringify(item),
                                  ).length > 0
                                }
                              />
                            }
                            className="text-color-black"
                          >
                            {item.label}
                          </CellButton>
                        </div>
                      );
                    })}
                    {hasNextPage && (
                      <Spinner
                        getRootRef={loaderRef}
                        size="medium"
                        className="spinner"
                      />
                    )}
                  </>
                ) : (
                  <Div className="p-5 flex justify-center items-center flex-col gap-2">
                    <Icon28InfoCircleOutline fill="#5181B8" />
                    <Text className="text-lg foWnt-semibold text-color-blue-400">
                      Ничего не найдено
                    </Text>
                  </Div>
                )}
              </Div>
            </Div>
          </Div>
        }
      >
        <Div
          className="flex items-center justify-between bg-color-steel-gray-20 rounded-lg h-10"
          style={{ outline: '0.5px solid #d9dadc' }}
        >
          {allValues.length > 0 ? (
            <>
              <Div className="p-0 flex gap-1 -ml-1">
                {formValues[0] && (
                  <Chip
                    onRemove={() => handleChangeValueState(formValues[0])}
                    value={formValues[0].value}
                    className={chipClassName}
                  >
                    {formValues[0].label}
                  </Chip>
                )}
                {formValues[1] && (
                  <TextTooltip
                    text={`${
                      allValues.length > 1
                        ? allValues
                            ?.filter((item, idx) => idx !== 0)
                            .map((item: IChip) => item.label)
                            .join(', ')
                        : allValues.length === 1
                          ? allValues[0].label
                          : ''
                    }`}
                  >
                    <Chip
                      removable={false}
                      value={formValues[1].value}
                      className={chipClassName}
                    >
                      {formValues[1].label}
                    </Chip>
                  </TextTooltip>
                )}
              </Div>
              <Icon20Dropdown
                fill="#99A2AD"
                className={`transition-all ${shown ? 'rotate-180' : ''}`}
              />
            </>
          ) : (
            <>
              <Text className="text-color-steel-gray-400 pl-2">
                {placeholder}
              </Text>
              <Icon20Dropdown
                fill="#99A2AD"
                className={`transition-all ${shown ? 'rotate-180' : ''}`}
              />
            </>
          )}
        </Div>
      </Popover>
    </div>
  );
};

export default ChipsSelectComponent;
