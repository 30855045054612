import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Div,
  Group,
  Input,
  Placeholder,
  Select,
  Spinner,
  Title,
  Text,
  ModalCard,
  ButtonGroup,
  ModalRoot,
} from '@vkontakte/vkui';
import {
  Icon24ListDeleteOutline,
  Icon16SearchOutline,
  Icon16CopyOutline,
  Icon20ErrorCircleOutline,
} from '@vkontakte/icons';
import { useCheckedElements } from 'providers/CheckedElementsContext';
import { ApiResponse } from 'lib/api/api';
import { parseFiltersUrl } from 'lib/utils/parseFunction';
import { TextTooltip } from '@vkontakte/vkui/dist/components/TextTooltip/TextTooltip';
import { useSnackbar } from 'lib/hooks/useSnackbar';
import { ChipsSelect } from '@vkontakte/vkui/dist/components/ChipsSelect/ChipsSelect';
import { IChip, ITableHeader } from 'lib/types';
import { handleKeyDown } from 'lib/utils/handleKeyDown';
import { IReview } from './types.reviews';
import {
  reviewEventFilters,
  reviewEventHeaders,
  reviewStatusMap,
} from './constants.reviews';
import TableSetup from 'components/common/TableSetup';
import { parseDateFrom } from 'lib/utils/date.utils';
import { ADMIN_REVIEWS_ROUTE } from 'lib/constants';
import useFetch from 'lib/hooks/useFetch';
import useParams from 'lib/hooks/useParams';
import InputSearch from 'components/common/InputSearch';
import FiltersButton from 'components/common/FiltersButton';
import FormItemPanel from 'components/common/FormItemPanel';
import FiltersWrapper from 'components/common/FiltersWrapper';
import PaginationComponent from 'components/common/Pagination';
import DateRangePicker from 'components/common/DateRangePicker';
import CustomSortIcon from 'components/common/CustomSortIcon';

const Reviews = () => {
  const [activeHeaders, setActiveHeaders] = useState<ITableHeader[]>([]);
  const { data, loading, fetchData } = useFetch<ApiResponse<IReview[]>>();
  const { fetchData: sendUpdatedStatus } = useFetch();
  const location = useLocation();
  const { setSnackbarContent } = useSnackbar();
  const [openModal, setOpenModal] = useState<string>('');
  const {
    filters,
    setFilters,
    addFiltersAndUpdateUrl,
    reset,
    handleChange,
    openFilters,
    handletoggleFilters,
    currentPage,
    setCurrentPage,
    sorting,
    setSortingFunc,
  } = useParams(reviewEventFilters);
  const {
    q,
    user_id,
    event_name,
    event_id,
    status,
    is_text,
    is_complaint,
    is_anonim,
  } = filters;
  const {
    checkedElements,
    handleCheckedItem,
    handleToggleAllCheckValue,
    clearCheckedElements,
  } = useCheckedElements<IReview>();
  const [reviews, setReviews] = useState<IReview[]>([]);
  const [newRatings, setNewRatings] = useState<IChip[]>([]);
  const [createdAtFrom, setCreatedAtFrom] = useState<Date | undefined>(
    undefined,
  );
  const [createdAtTo, setCreatedAtTo] = useState<Date | undefined>(undefined);

  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFilters({ ...filters, q: value });
  };

  const fetchListFunction = () => {
    const queryParams = location.search ? parseFiltersUrl(location.search) : '';

    if (queryParams) {
      const ratingValueParam =
        new URLSearchParams(location.search).get('rating_value') ?? '[]';
      const ratingValueArr = JSON.parse(ratingValueParam);
      setNewRatings(
        ratingValueArr.map((value: string) => ({
          label: value,
          value: value,
        })),
      );
    }

    fetchData({
      path: `/rate/ratings/?page=${currentPage - 1}${queryParams && `&${queryParams}`}`,
      method: 'get',
    });
  };

  const onResetFilters = useCallback(() => {
    reset();
    setCreatedAtFrom(undefined);
    setCreatedAtTo(undefined);
    setNewRatings([]);
  }, [reset, setCreatedAtFrom, setCreatedAtTo, setNewRatings]);

  const handleUpdateStatus = async (operationType: string) => {
    const updatePromise = sendUpdatedStatus({
      path: `rate/ratings/update-status/`,
      method: 'patch',
      body: {
        ids: checkedElements,
        status: operationType,
      },
      onSuccessMessage: 'Статус успешно изменён',
    });

    await updatePromise;
    await setOpenModal('');
    await fetchListFunction();
    await clearCheckedElements();
  };

  const handleRatingChange = (value: IChip[]) => {
    if (value) {
      setNewRatings(value);
      const ratingValueArr = value.map((e) => e.label);
      setFilters({
        ...filters,
        rating_value: ratingValueArr,
      });
    }
  };

  useEffect(() => {
    fetchListFunction();
  }, [location.search, currentPage]);

  useEffect(() => {
    if (!loading && data?.results) {
      setReviews(data.results);
    }
  }, [loading, location.search]);

  useEffect(() => {
    const storedHeaders = localStorage.getItem('columnOrder_reviews');

    if (storedHeaders) {
      setActiveHeaders(JSON.parse(storedHeaders));
    } else {
      setActiveHeaders(reviewEventHeaders);
    }
  }, []);

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <Group>
          <Div className="flex items-center gap-2">
            <Button
              disabled={checkedElements.length === 0}
              mode="secondary"
              className="vkui-edit-button-secondary min-w-fit"
              size="m"
              onClick={() => setOpenModal('REJECTED')}
            >
              Скрыть
            </Button>
            <Button
              disabled={checkedElements.length === 0}
              mode="secondary"
              className="vkui-edit-button-secondary min-w-fit"
              size="m"
              onClick={() => setOpenModal('PUBLISHED')}
            >
              Опубликовать
            </Button>
            <InputSearch
              onSubmit={addFiltersAndUpdateUrl}
              value={q as string}
              onChange={handleChangeSearchValue}
            />
            <FiltersButton
              openFilters={openFilters}
              toggle={handletoggleFilters}
            />
            <TableSetup
              tableId="reviews"
              headers={reviewEventHeaders}
              onActiveHeadersChange={setActiveHeaders}
            />
          </Div>
          {openFilters ? (
            <FiltersWrapper
              reset={onResetFilters}
              addFiltersAndUpdateUrl={addFiltersAndUpdateUrl}
            >
              <FormItemPanel top="ID пользователя">
                <Input
                  onKeyDown={(event) => {
                    handleKeyDown(event, addFiltersAndUpdateUrl);
                  }}
                  className="vkui-input"
                  name="user_id"
                  placeholder="Укажите ID пользователя"
                  value={user_id as string}
                  onChange={handleChange}
                  before={<Icon16SearchOutline fill="#99A2AD" />}
                />
              </FormItemPanel>
              <FormItemPanel top="Мероприятие">
                <Input
                  onKeyDown={(event) => {
                    handleKeyDown(event, addFiltersAndUpdateUrl);
                  }}
                  className="vkui-input"
                  name="event_name"
                  placeholder="Укажите название"
                  value={event_name as string}
                  onChange={handleChange}
                  before={<Icon16SearchOutline fill="#99A2AD" />}
                />
              </FormItemPanel>
              <FormItemPanel top="ID мероприятия">
                <Input
                  onKeyDown={(event) => {
                    handleKeyDown(event, addFiltersAndUpdateUrl);
                  }}
                  className="vkui-input"
                  name="event_id"
                  placeholder="Укажите ID мероприятия"
                  value={event_id as string}
                  onChange={handleChange}
                  before={<Icon16SearchOutline fill="#99A2AD" />}
                />
              </FormItemPanel>
              <FormItemPanel top="Оценка" htmlFor="rating_value">
                <ChipsSelect
                  name="rating_value"
                  className="vkui-select"
                  id="rating_value"
                  placeholder="Выберите оценку"
                  creatable={false}
                  value={newRatings || []}
                  onChange={(value) => handleRatingChange(value)}
                  options={[
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                  ]}
                />
              </FormItemPanel>
              <FormItemPanel top="Статус" htmlFor="status">
                <Select
                  className="vkui-select"
                  id="status"
                  name="status"
                  placeholder="Выберите статус"
                  value={status as string}
                  onChange={handleChange}
                  allowClearButton
                  options={[
                    { value: 'PENDING', label: 'На проверке' },
                    { value: 'PUBLISHED', label: 'Опубликован' },
                    { value: 'REJECTED', label: 'Скрыт' },
                    { value: 'DELETED', label: 'Удален' },
                  ]}
                />
              </FormItemPanel>
              <FormItemPanel top="Наличие отзыва" htmlFor="is_text">
                <Select
                  className="vkui-select"
                  id="is_text"
                  name="is_text"
                  placeholder="Наличие отзыва"
                  value={is_text as string}
                  onChange={handleChange}
                  allowClearButton
                  options={[
                    { value: 'true', label: 'Есть' },
                    { value: 'false', label: 'Нет' },
                  ]}
                />
              </FormItemPanel>
              <FormItemPanel top="Наличие жалобы" htmlFor="is_complaint">
                <Select
                  className="vkui-select"
                  id="is_complaint"
                  name="is_complaint"
                  placeholder="Наличие жалобы"
                  value={is_complaint as string}
                  onChange={handleChange}
                  allowClearButton
                  options={[
                    { value: 'true', label: 'Есть' },
                    { value: 'false', label: 'Нет' },
                  ]}
                />
              </FormItemPanel>
              <FormItemPanel top="Анонимно" htmlFor="is_anonim">
                <Select
                  className="vkui-select"
                  id="is_anonim"
                  name="is_anonim"
                  placeholder="Анонимно"
                  value={is_anonim as string}
                  onChange={handleChange}
                  allowClearButton
                  options={[
                    { value: 'true', label: 'Да' },
                    { value: 'false', label: 'Нет' },
                  ]}
                />
              </FormItemPanel>
              <div className="z-10">
                <DateRangePicker
                  title={'Дата отзыва'}
                  withTime={false}
                  from={createdAtFrom}
                  setFrom={setCreatedAtFrom}
                  fromFilterKey={'created_at__from'}
                  to={createdAtTo}
                  setTo={setCreatedAtTo}
                  toFilterKey={'created_at__to'}
                  filters={filters}
                  setFilters={setFilters}
                />
              </div>
            </FiltersWrapper>
          ) : null}
          {reviews && reviews.length > 0 ? (
            <>
              <div className="custom-scrollbar overflow-auto pb-2">
                <table className="w-full">
                  <thead>
                    <tr className="border-y-1 border-color-steel-gray-80">
                      <th>
                        <Checkbox
                          checked={checkedElements.length === reviews.length}
                          onChange={() => handleToggleAllCheckValue(reviews)}
                        />
                      </th>
                      {activeHeaders.map((header) => (
                        <th
                          key={header.value}
                          className="text-color-gray-600 uppercase py-4 whitespace-nowrap"
                        >
                          <Title
                            className="flex text-sm ml-8 max-w-fit gap-1 items-center cursor-pointer"
                            level="2"
                            onClick={() => {
                              if (header.sortingValue) {
                                setSortingFunc(header.sortingValue);
                              }
                            }}
                          >
                            {header.sortingValue && (
                              <CustomSortIcon
                                sortDirection={sorting}
                                activeHeader={header}
                              />
                            )}
                            {header.label}
                          </Title>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {reviews &&
                      reviews.map(
                        ({
                          id,
                          user_id,
                          is_anonim,
                          event_name,
                          value,
                          text,
                          status,
                          created_at,
                          event_id,
                          complaints,
                        }) => (
                          <tr
                            onClick={() =>
                              window.open(
                                ADMIN_REVIEWS_ROUTE + `/edit/${id}`,
                                '_blank',
                              )
                            }
                            key={id}
                            className="cursor-pointer transition-colors hover:bg-color-steel-gray-20 h-10"
                          >
                            {checkedElements && (
                              <td
                                className="py-2"
                                onClick={(event) => event.stopPropagation()}
                              >
                                <Checkbox
                                  checked={checkedElements.includes(id)}
                                  onChange={() => handleCheckedItem(id)}
                                />
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'user_id',
                            ) && (
                              <td className="py-2" id="user_id">
                                <Text className="flex justify-between max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {user_id
                                    ? `***-${user_id?.split('-').pop()}`
                                    : '-'}
                                  {user_id && (
                                    <Icon16CopyOutline
                                      color={'#99A2AD'}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        navigator.clipboard.writeText(user_id);
                                        setSnackbarContent({
                                          type: 'success',
                                          message:
                                            'ID cкопирован в буфер обмена',
                                        });
                                      }}
                                    />
                                  )}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'is_anonim',
                            ) && (
                              <td className="py-2" id="is_anonim">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {is_anonim !== null
                                    ? is_anonim
                                      ? 'Да'
                                      : 'Нет'
                                    : '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'event_name',
                            ) && (
                              <td className="py-2" id="event_name">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {event_name || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some((h) => h.value === 'value') && (
                              <td className="py-2" id="value">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {value || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some((h) => h.value === 'text') && (
                              <td className="py-2" id="text">
                                {text ? (
                                  <TextTooltip text={text} className="max-w-lg">
                                    <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                      {complaints.length > 0 ? (
                                        <div className="bg-red-100 text-red-500 flex justify-between gap-2 items-center p-1 rounded">
                                          {`${text.substring(0, 30)}${text.length > 30 ? '...' : ''}`}
                                          <Icon20ErrorCircleOutline
                                            color={'rgb(239 68 68)'}
                                          />
                                        </div>
                                      ) : (
                                        <div className="flex justify-between gap-2 items-center p-1 rounded">
                                          {`${text.substring(0, 30)}${text.length > 30 ? '...' : ''}`}
                                        </div>
                                      )}
                                    </Text>
                                  </TextTooltip>
                                ) : (
                                  <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                    {'Нет отзыва'}
                                  </Text>
                                )}
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'status',
                            ) && (
                              <td className="py-2" id="status">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {reviewStatusMap[
                                    status as keyof typeof reviewStatusMap
                                  ] || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'created_at',
                            ) && (
                              <td className="py-2" id="created_at">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {parseDateFrom({
                                    date: created_at,
                                    format: 'daytime',
                                    utc: false,
                                  }) || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'event_id',
                            ) && (
                              <td className="py-2" id="event_id">
                                <Text className="flex justify-between max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {event_id
                                    ? `***-${event_id?.split('-').pop()}`
                                    : '-'}
                                  {event_id && (
                                    <Icon16CopyOutline
                                      color={'#99A2AD'}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        navigator.clipboard.writeText(event_id);
                                        setSnackbarContent({
                                          type: 'success',
                                          message:
                                            'ID cкопирован в буфер обмена',
                                        });
                                      }}
                                    />
                                  )}
                                </Text>
                              </td>
                            )}
                          </tr>
                        ),
                      )}
                  </tbody>
                </table>
              </div>
              {data && data?.count > 10 ? (
                <PaginationComponent
                  count={data?.count}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  items={reviews}
                />
              ) : null}
            </>
          ) : (
            <Div>
              <Placeholder
                icon={<Icon24ListDeleteOutline />}
                header={'Отзывы не найдены'}
              >
                Попробуйте изменить критерии поиска
              </Placeholder>
            </Div>
          )}
        </Group>
      )}
      <ModalRoot activeModal={openModal}>
        <ModalCard
          id="PUBLISHED"
          onClose={() => setOpenModal('')}
          className="fixed"
        >
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black" level="2">
              Опубликовать отзывы
            </Title>
            <Text className="text-color-gray-600">
              Выбранные отзывы будут опубликованы
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={() => setOpenModal('')}
              >
                Отмена
              </Button>
              <Button
                className="vkui-edit-button-primary"
                mode="primary"
                size="m"
                onClick={() => handleUpdateStatus('PUBLISHED')}
              >
                Опубликовать
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard>
        <ModalCard
          id="REJECTED"
          onClose={() => setOpenModal('')}
          className="fixed"
        >
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black" level="2">
              Скрыть отзывы
            </Title>
            <Text className="text-color-gray-600">
              Выбранные отзывы будут скрыты
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={() => setOpenModal('')}
              >
                Отмена
              </Button>
              <Button
                className="vkui-edit-button-primary"
                mode="primary"
                size="m"
                onClick={() => handleUpdateStatus('REJECTED')}
              >
                Скрыть
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard>
      </ModalRoot>
    </>
  );
};

export default Reviews;
