import React from 'react';
import { useParams } from 'react-router-dom';
import TypesDirectory from './TypesDirectory/TypesDirectory';
import EventTagsDirectory from './EventTagsDirectory/EventTagsDirectory';
import GenresDirectory from './GenresDirectory/GenresDirectory';
import EventLabelsDirectory from './EventLabelsDirectory/EventLabelsDirectory';

const Directories = () => {
  const { directoriesType } = useParams();

  const tagPanels = {
    types: <TypesDirectory />,
    event_tags: <EventTagsDirectory />,
    genres: <GenresDirectory />,
    event_labels: <EventLabelsDirectory />,
  };

  return tagPanels[directoriesType as keyof typeof tagPanels];
};

export default Directories;
