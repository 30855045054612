import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { classNames } from '@vkontakte/vkui';
import { PopupObjType } from './AdminHeader';

interface IHeaderItem {
  link: string;
  title: string;
  setOpenPopup: (obj: PopupObjType) => void;
  className?: string;
}

const HeaderItem: React.FC<IHeaderItem> = ({
  className,
  link,
  title,
  setOpenPopup,
}) => {
  const location = useLocation();

  const handleClosePopup = () => {
    setOpenPopup({
      users: false,
      directories: false,
      reviews: false,
      events: false,
    });
  };

  const style = classNames(
    className,
    `text-nav transition-colors cursor-pointer hover:text-color-blue-400 ${location.pathname === link ? 'text-color-blue-400' : ''
    }`,
  );

  return (
    <Link onClick={handleClosePopup} to={link} className={style}>
      {title}
    </Link>
  );
};

export default HeaderItem;
