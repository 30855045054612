import React, { useEffect, useState } from 'react';
import { Avatar, CellButton, Div, SimpleCell, Text } from '@vkontakte/vkui';
import { Popover } from '@vkontakte/vkui/dist/components/Popover/Popover';
import {
  Icon24DoorArrowRightOutline,
  Icon28User,
  Icon24CloudSlashOutline,
} from '@vkontakte/icons';
import { useAuth } from 'providers/AuthProvider';
import { useSnackbar } from 'lib/hooks/useSnackbar';
import getMeService from 'lib/api/getMeService';
import { getFullName, getRoleName } from 'lib/utils/userUtils';
import { IMe } from '../Users/types.users';
import { useNavigate } from 'react-router-dom';

const UserProfile: React.FC = () => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const { setUser } = useAuth();
  const { setSnackbarContent } = useSnackbar();
  const [userProfile, setUserProfile] = useState<IMe | undefined>(undefined);
  const navigate = useNavigate();

  const getMyProfile = async () => {
    try {
      const apiResponse = await getMeService.getMyProfile();
      const userData = apiResponse.data;
      setUserProfile(userData);
    } catch (error) {
      setSnackbarContent({
        type: 'error',
        message: 'Ошибка при получении данных пользователя',
      });
    }
  };

  const clearColumnOrderItems = () => {
    const allKeys = Object.keys(localStorage);
    const columnOrderKeys = allKeys.filter((key) =>
      key.startsWith('columnOrder_'),
    );
    columnOrderKeys.forEach((key) => localStorage.removeItem(key));
    navigate(0);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    setUser(null);
  };

  useEffect(() => {
    getMyProfile();
  }, []);

  return (
    <Popover
      action="click"
      shown={isOpenMenu}
      onShownChange={setIsOpenMenu}
      content={
        <Div>
          <CellButton
            className="text-black"
            before={<Icon24CloudSlashOutline />}
            onClick={clearColumnOrderItems}
          >
            Сбросить настройки таблиц
          </CellButton>
          <CellButton
            className="text-black"
            before={<Icon24DoorArrowRightOutline />}
            onClick={handleLogout}
          >
            Выйти
          </CellButton>
        </Div>
      }
    >
      <SimpleCell
        className="h-14"
        after={
          <Avatar
            size={42}
            fallbackIcon={
              <Icon28User width={36} height={36} color={'#5181B8'} />
            }
            src="#"
            className="ml-3"
          />
        }
        subtitle={userProfile ? getRoleName(userProfile.role) : ''}
      >
        <Text>
          {userProfile
            ? getFullName(userProfile.lastname, userProfile.firstname)
            : ''}
        </Text>
      </SimpleCell>
    </Popover>
  );
};

export default UserProfile;
