export const createStringFromObjArray = <T>(arr: T[], key: keyof T): string => {
  const uniqueValues = new Set<string>();

  arr.forEach((obj) => {
    if (obj[key] !== undefined && obj[key] !== null) {
      uniqueValues.add(String(obj[key]));
    }
  });

  return Array.from(uniqueValues).join(', ');
};

export const countUniqueValues = <T>(arr: T[], key: keyof T): number => {
  const uniqueValues = new Set<string>();

  arr.forEach((obj) => {
    if (obj[key] !== undefined && obj[key] !== null) {
      uniqueValues.add(String(obj[key]));
    }
  });

  return uniqueValues.size;
};
