import React, { ChangeEventHandler, KeyboardEvent } from 'react';
import { Input } from '@vkontakte/vkui';
import { Icon16SearchOutline, Icon16Clear } from '@vkontakte/icons';

interface IInputSearch {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  type?: string;
  onSubmit: () => void;
  onClick?: () => void;
  placeholder?: string;
  allowClearButton?: boolean;
  width?: string;
}

const InputSearch = ({
  value,
  onChange,
  type = 'text',
  onSubmit,
  placeholder = 'Поиск',
  onClick,
  allowClearButton = false,
  width = '200px',
}: IInputSearch) => {
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  const handleClear = () => {
    const event = {
      target: { value: '' },
    } as React.ChangeEvent<HTMLInputElement>;
    onChange(event);
  };

  return (
    <Input
      onKeyDown={handleKeyDown}
      type={type}
      before={<Icon16SearchOutline fill="#99A2AD" />}
      style={{ width: width, height: '42px' }}
      placeholder={placeholder}
      size={15}
      className="input-style bg-color-white"
      value={value}
      onChange={onChange}
      onClick={onClick}
      after={
        allowClearButton &&
        value && (
          <Icon16Clear className="cursor-pointer" onClick={handleClear} />
        )
      }
    />
  );
};

export default InputSearch;
