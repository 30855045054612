import { TabId } from './types.events';

export const eventHeaders = [
	{
		order: 1,
		label: 'Название',
		value: 'name',
	},
	{
		order: 2,
		label: 'Тип',
		value: 'category',
	},
	{
		order: 3,
		label: 'Город',
		value: 'city',
	},
	{
		order: 4,
		label: 'Место проведения',
		value: 'place_name',
	},
	{
		order: 5,
		label: 'Статус',
		value: 'is_enabled',
	},
	{
		order: 6,
		label: 'Поставщик',
		value: 'provider',
	},
	{
		order: 7,
		label: 'Рейтинг',
		value: 'rating',
	},
	{
		order: 8,
		label: 'Обложка',
		value: 'preview_url',
	},
	{
		order: 9,
		label: 'Описание',
		value: 'description',
	},
	{
		order: 10,
		label: 'Сеансы',
		value: 'seances',
	},
	{
		order: 11,
		label: 'Лейбл мероприятия',
		value: 'label_top',
	},
	{
		order: 12,
		label: 'Лейбл цены',
		value: 'label_bottom',
	},
	{
		order: 13,
		label: 'Владелец данных',
		value: 'source_owner',
	},
	{
		order: 14,
		label: 'Обогащение данных',
		value: 'data_enricher',
	},
	{
		order: 15,
		label: 'Изменено админом',
		value: 'changed_from_admin',
	},
	{
		order: 16,
		label: 'Пушкинская карта',
		value: 'is_pushkin_card',
	},
];

export const eventFilters = {
	q: '',
	ordering: '',
	preview_url_exists: '',
	description_exists: '',
	seances_exists: '',
	category_id: [],
	provider: '',
	is_enabled: '',
	city_id: [],
	place_id: [],
	label_top_id: [],
	label_bottom_id: [],
	changed_from_admin: '',
	is_pushkin_card: '',
	event_provider_id: '',
};

export const eventBlank = {
	id: '',
	slug: '',
	name: '',
	description: '',
	short_description: '',
	category: {
		id: '',
		name: '',
	},
	category_id: '',
	age_category: '',
	organizer: '',
	provider_id: 0,
	provider: '',
	vk_url: '',
	preview_url: '',
	preview_url_original: '',
	image_urls: [],
	is_enabled: false,
	rating: 0,
	label_top: {
		id: '',
		name: '',
	},
	label_bottom: {
		id: '',
		name: '',
	},
	countries: [],
	year: 0,
	genres: [],
	trailer: {
		thumbnail: '',
		preview: '',
		hd: '',
	},
	poster: '',
	source_owner: '',
	data_enricher: [],
	changed_from_admin: [],
	is_pushkin_card: false,
	persons: [],
	changed_from_admin_at: '',
};

export const eventSeancesFilters = {
	ordering: '',
	city_id: [],
	place_id: [],
	is_pushkin_card: '',
};

export const eventSeancesHeaders = [
	{
		order: 1,
		label: 'Дата',
		value: 'start',
		sortingValue: 'start',
	},
	{
		order: 2,
		label: 'Город',
		value: 'city',
	},
	{
		order: 3,
		label: 'Место проведения',
		value: 'place_name',
	},
	{
		order: 4,
		label: 'Сеанс',
		value: 'start_time',
	},
	{
		order: 5,
		label: 'Продолжительность',
		value: 'duration_minutes',
	},
	{
		order: 6,
		label: 'Форматы',
		value: 'formats',
	},
	{
		order: 7,
		label: 'Кол-во билетов',
		value: 'tickets_count',
	},
	{
		order: 8,
		label: 'Ссылка на виджет',
		value: 'widget_url',
	},
	{
		order: 9,
		label: 'Пушкинская карта',
		value: 'is_pushkin_card',
	},
];

export const eventLinksHeaders = [
	{
		order: 1,
		label: 'Город',
		value: 'city_name',
	},
	{
		order: 2,
		label: 'Ссылка на веб',
		value: 'web_url',
	},
	{
		order: 3,
		label: 'Ссылка на миниапп',
		value: 'miniapp_url',
	},
];

export const eventFieldNameMap = {
	slug: 'Ссылка на мероприятие (ЧПУ)',
	miniapp_slug: 'Ссылка на миниапп',
	id: 'ID',
	provider_id: 'ID мероприятия у Поставщика',
	provider: 'Поставщик',
	source_owner: 'Владелец данных',
	data_enricher: 'Обогащение данных',
	rating: 'Рейтинг',
	name: 'Название',
	description: 'Описание',
	category: 'Тип',
	genres: 'Жанр',
	age_category: 'Возрастная категория',
	label_top: 'Лейбл мероприятия',
	label_bottom: 'Лейбл цены',
	vk_url: 'Ссылка на событие ВК',
	organizer: 'Организатор',
	countries: 'Страна',
	year: 'Год создания',
	changed_from_admin: 'Изменено админом',
	is_pushkin_card: 'Пушкинская карта',
	is_enabled: 'Активно',
	reviews_link: 'Посмотреть отзывы',
};

export const tabs = [
	{ title: 'Информация', id: TabId.info },
	{ title: 'Сеансы', id: TabId.sessions },
	{ title: 'Медиа', id: TabId.media },
	{ title: 'Персоны', id: TabId.persons },
	{ title: 'Ссылки', id: TabId.links },
];
