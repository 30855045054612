export const createPassword = (
  minLength: number,
  minUniqueChars: number,
): string => {
  let password: string;
  do {
    password = generatePassword(minLength);
  } while (!validatePassword(password, minLength, minUniqueChars));
  return password;
};

export const generatePassword = (length: number): string => {
  const numbers = ['0', ' 1', '2', '3', '4', '5', '6', '7', '8', '9'];
  const symbols = ['!', '@', '#', '$', '%', '&', '?', '-', '+', '=', '~'];
  const letters = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ];
  const capitals = letters.map((letter) => letter.toUpperCase());

  const passwordCandidate = numbers
    .concat(symbols, letters, capitals)
    .sort(() => Math.random() - 0.5)
    .splice(0, length)
    .join('');

  return passwordCandidate;
};

export const validatePassword = (
  password: string,
  minLength: number,
  minUniqueChars: number,
): boolean => {
  if (password.length < minLength) {
    return false;
  }

  const uniqueChars = new Set(password);
  if (uniqueChars.size < minUniqueChars) {
    return false;
  }

  const hasDigit = /\d/.test(password);
  const hasLower = /[a-z]/.test(password);
  const hasUpper = /[A-Z]/.test(password);
  if (!hasDigit || !hasLower || !hasUpper) {
    return false;
  }

  return true;
};
