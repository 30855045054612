import React, { FC, ReactNode, useState, createContext, SetStateAction, Dispatch } from "react";

export interface IHistoryContext {
  history: string[];
  setHistory: Dispatch<SetStateAction<string[]>>;
  getPreviousRoute: () => string | null
}

export interface IHistoryContextProvider {
  children: ReactNode;
}

export const HistoryContext = createContext<IHistoryContext>({} as IHistoryContext);

export const HistoryContextProvider: FC<IHistoryContextProvider> = ({ children }) => {
  const [history, setHistory] = useState<IHistoryContext["history"]>([]);

  const getPreviousRoute = () => {
    return history.length >= 2 
      ? history[history.length - 2]
      : null
  }

  return (
    <HistoryContext.Provider value={{ history, setHistory, getPreviousRoute }}>
      {children}
    </HistoryContext.Provider>
  );
};