import React, { type ReactNode } from 'react';
import ArrowBack from './ArrowBack';
import { Div } from '@vkontakte/vkui';

interface IPanelTitle {
  arrowColor?: '#2688EB' | '#99A2AD';
  children: ReactNode;
  withArrow?: boolean;
}

const PanelTitle = ({
  arrowColor = '#2688EB',
  children,
  withArrow = false,
}: IPanelTitle) => {
  return (
    <Div className="py-5 px-3 flex items-center gap-3">
      {withArrow ? <ArrowBack color={arrowColor} /> : null}
      {children}
    </Div>
  );
};

export default PanelTitle;
