import React, { FC, MouseEvent, useEffect } from 'react';
import FormItemPanel from './FormItemPanel';
import { DateInput, LocaleProvider } from '@vkontakte/vkui';
import { Icon16Clear } from '@vkontakte/icons';
import { formatDateTo } from 'lib/utils/date.utils';
import { useSnackbar } from 'lib/hooks/useSnackbar';
import { parseFiltersUrl } from 'lib/utils/parseFunction';
import { getQueryParam } from 'lib/utils/getQueryParam';
import clsx from 'clsx';

interface IDateRangePickerProps {
  title: string;
  withTime: boolean;
  from: Date | undefined;
  setFrom: (from: Date | undefined) => void;
  fromFilterKey: string;
  to: Date | undefined;
  setTo: (to: Date | undefined) => void;
  toFilterKey: string;
  // eslint-disable-next-line
  filters: Record<string, any>;
  // eslint-disable-next-line
  setFilters: (filters: Record<string, any>) => void;
}

const DateRangePicker: FC<IDateRangePickerProps> = ({
  title,
  withTime,
  from,
  setFrom,
  fromFilterKey,
  to,
  setTo,
  toFilterKey,
  filters,
  setFilters,
}) => {
  const { setSnackbarContent } = useSnackbar();

  const handleDateClear = (event: MouseEvent<SVGSVGElement>, name: string) => {
    event.stopPropagation();
    if (name) {
      switch (name) {
        case fromFilterKey:
          setFrom(undefined);
          // eslint-disable-next-line
          setFilters((prevFilters: any) => {
            // eslint-disable-next-line
            const { [fromFilterKey]: _, ...restFilters } = prevFilters;
            return restFilters;
          });
          break;

        case toFilterKey:
          setTo(undefined);
          // eslint-disable-next-line
          setFilters((prevFilters: any) => {
            // eslint-disable-next-line
            const { [toFilterKey]: _, ...restFilters } = prevFilters;
            return restFilters;
          });
          break;
      }
    }
  };

  const handleDateChange = (date: Date | undefined, name: string) => {
    if (date) {
      if (!withTime) {
        switch (name) {
          case fromFilterKey:
            date.setHours(0, 0, 0, 0);
            break;
          case toFilterKey:
            date.setHours(23, 59, 59, 999);
            break;
        }
      }

      switch (name) {
        case fromFilterKey:
          if (to && date > to) {
            setFrom(undefined);
            setSnackbarContent({
              type: 'error',
              message: 'Начало интервала не может быть позже конца интервала.',
            });
          } else {
            setFrom(date);
            setFilters({
              ...filters,
              [fromFilterKey]: formatDateTo({
                date: date,
                format: withTime ? 'daytime' : 'dayFrom',
              }),
            });
          }
          break;

        case toFilterKey:
          if (from && date < from) {
            setTo(undefined);
            setSnackbarContent({
              type: 'error',
              message: 'Конец интервала не может быть раньше начала интервала.',
            });
          } else {
            setTo(date);
            setFilters({
              ...filters,
              [toFilterKey]: formatDateTo({
                date: date,
                format: withTime ? 'daytime' : 'dayTo',
              }),
            });
          }
          break;
      }
    }
  };

  useEffect(() => {
    const queryParams = location.search ? parseFiltersUrl(location.search) : '';

    if (queryParams) {
      const fromParam = getQueryParam(queryParams, fromFilterKey);
      const fromDate = fromParam !== null ? new Date(fromParam) : null;
      if (fromDate) {
        setFrom(fromDate);
      }

      const toParam = getQueryParam(queryParams, toFilterKey);
      const toDate = toParam !== null ? new Date(toParam) : null;
      if (toDate) {
        setTo(toDate);
      }
    }
  }, []);

  return (
    <div>
      <FormItemPanel top={title} width={`${withTime && '348px'}`}>
        <LocaleProvider value="ru">
          <div
            className={clsx({
              'vkui-edit-date-range-container': !withTime,
              'vkui-edit-datetime-range-container': withTime,
            })}
          >
            <DateInput
              className={clsx({
                'vkui-edit-date-range': !withTime,
                'vkui-edit-datetime-range': withTime,
              })}
              value={from}
              enableTime={withTime}
              onChange={(date) => handleDateChange(date, fromFilterKey)}
              closeOnChange={true}
              showNeighboringMonth={true}
              after={
                from && (
                  <Icon16Clear
                    className="cursor-pointer -ml-12"
                    onClick={(event) => handleDateClear(event, fromFilterKey)}
                  />
                )
              }
            />
            <DateInput
              className={clsx({
                'vkui-edit-date-range': !withTime,
                'vkui-edit-datetime-range': withTime,
              })}
              value={to}
              enableTime={withTime}
              onChange={(date) => handleDateChange(date, toFilterKey)}
              closeOnChange={true}
              showNeighboringMonth={true}
              after={
                to && (
                  <Icon16Clear
                    className="cursor-pointer -ml-12"
                    onClick={(event) => handleDateClear(event, toFilterKey)}
                  />
                )
              }
            />
          </div>
        </LocaleProvider>
      </FormItemPanel>
    </div>
  );
};

export default DateRangePicker;
