import * as React from 'react';
import { FormItem, Link } from '@vkontakte/vkui';
import { Icon24ExternalLinkOutline } from '@vkontakte/icons';
import { getClearUrl } from 'lib/utils/getClearUrl';

interface IAppLinkFormItemProps {
  obj: 'place' | 'event';
  type?: string;
  /**
   * id or slug
   */
  identifier?: string;
  app: 'web' | 'miniapp';
};

export const AppLinkFormItem = ({ identifier, obj, type, app }: IAppLinkFormItemProps): React.ReactElement => {
  return (
    <FormItem top={app === 'miniapp' ? 'Ссылка на миниапп' : "Ссылка на мероприятие (ЧПУ)"}>
      {!identifier || !type ? (
        <div>Ссылка не сформирована</div>
      ) : process.env.REACT_APP_ENV === 'develop' ? (
        <div className="flex flex-col">
          <span className="text-sm">{identifier}</span>
          <span className="text-xs text-gray-500">
            (В текущем окружении нет ссылки)
          </span>
        </div>
      ) : (
        <Link
          href={getClearUrl(
            obj,
            type,
            identifier,
            app,
          )}
          target="_blank"
        >
          {identifier}
          <Icon24ExternalLinkOutline
            width={18}
            height={18}
            className="mb-1"
          />
        </Link>
      )}
    </FormItem>
  );
};
