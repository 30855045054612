import { FormEvent, Dispatch, SetStateAction, ChangeEvent } from 'react';
import { Crop } from 'react-image-crop';
import { apiServiceResizer } from 'lib/api/apiResizer';
import { ISnackbarContent } from 'components/common/Snackbar';
import { validateImage } from './validateImage';

type IImageData = {
  link_to_file: string;
};

const uploadToFileStorage = async (
  dataUrl: string,
  setState: (url: string) => void,
) => {
  try {
    const response = await fetch(dataUrl);
    const blobData = await response.blob();

    const formData = new FormData();
    formData.append('file', blobData);

    const apiResponse = await apiServiceResizer.postFormData<IImageData>(
      `/convert-and-save-data`,
      formData,
    );

    const imageUrl = apiResponse.data.link_to_file;
    setState(imageUrl);
  } catch (error) {
    console.error('Ошибка при загрузке файла:', error);
  }
};

const handleImageChange = async (
  event: ChangeEvent<HTMLInputElement>,
  {
    setSelectedImages,
    setSnackbarContent,
  }: {
    setSelectedImages: Dispatch<SetStateAction<string[]>>;
    setSnackbarContent?: Dispatch<SetStateAction<ISnackbarContent>>;
  },
) => {
  const selectedFile = event.target.files?.[0] || null;

  if (selectedFile) {
    const { isValid } = validateImage({
      image: selectedFile,
      setSnackbarContent,
    });

    if (!isValid) return;

    try {
      const formData = new FormData();
      formData.append('file', selectedFile);

      const response = await apiServiceResizer.postFormData<IImageData>(
        `/convert-and-save-data`,
        formData,
      );

      const imageUrl = response.data.link_to_file;

      setSelectedImages((prevImages) => [...prevImages, imageUrl]);
    } catch (error) {
      console.error('Ошибка при загрузке файла:', error);
    }
  }
};

const setCanvas = (
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: Crop,
) => {
  const context = canvas.getContext('2d');
  if (!context) {
    throw new Error('No 2d context');
  }

  const pixelRatio = window.devicePixelRatio;
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  context.scale(pixelRatio, pixelRatio);
  context.imageSmoothingQuality = 'high';
  context.save();

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  context.translate(-cropX, -cropY);
  context.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  );

  context.restore();
};

type HandleFileSelectionParams = {
  minDimension: number;
  aspectRatio: number;
  file: File | null;
  setCropError: (error: string) => void;
  setImageSrc: (src: string) => void;
  setOpenModal?: (modal: string) => void;
  setSnackbarContent?: Dispatch<SetStateAction<ISnackbarContent>>;
  setOriginal?: (url: string) => void;
};

const handleFileSelection = ({
  minDimension,
  aspectRatio,
  file,
  setCropError,
  setImageSrc,
  setOpenModal,
  setSnackbarContent,
  setOriginal,
}: HandleFileSelectionParams) => {
  if (!file) return;

  if (setSnackbarContent) {
    const { isValid } = validateImage({ image: file, setSnackbarContent });
    if (!isValid) return;
  }

  const reader = new FileReader();
  reader.onloadend = () => {
    const imageUrl = reader.result as string;

    const imageElement = new Image();
    imageElement.onload = () => {
      setCropError('');
      const { naturalWidth, naturalHeight } = imageElement;

      if (naturalWidth < minDimension || naturalHeight < aspectRatio) {
        setCropError('Минимальный размер изображения 200 x 200 пикселей.');
        setImageSrc('');
      } else {
        if (setOriginal) {
          uploadToFileStorage(imageUrl, setOriginal);
        }
        setImageSrc(imageUrl);
        if (setOpenModal) {
          setOpenModal('crop');
        }
      }
    };

    imageElement.src = imageUrl;
  };

  reader.readAsDataURL(file);
};

const handleImageUrl = (
  minDimension: number,
  aspectRatio: number,
  imageUrl: string,
  setCropError: (error: string) => void,
  setImageSrc: (src: string) => void,
  setOpenModal?: (modal: string) => void,
) => {
  fetch(imageUrl)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Ошибка загрузки изображения');
      }
      return response.blob();
    })
    .then((blob) => {
      const imageElement = new Image();
      imageElement.crossOrigin = 'Anonymous';

      const objectUrl = URL.createObjectURL(blob);

      imageElement.onload = () => {
        const { naturalWidth, naturalHeight } = imageElement;

        if (naturalWidth < minDimension || naturalHeight < aspectRatio) {
          setCropError('Минимальный размер изображения 200 x 200 пикселей.');
          setImageSrc('');
        } else {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          if (!ctx) {
            setCropError('Не удалось создать контекст для рисования.');
            return;
          }

          canvas.width = naturalWidth;
          canvas.height = naturalHeight;
          ctx.drawImage(imageElement, 0, 0);

          const base64Image = canvas.toDataURL('image/jpeg');
          setImageSrc(base64Image);

          if (setOpenModal) {
            setOpenModal('crop');
          }
        }

        URL.revokeObjectURL(objectUrl);
      };

      imageElement.onerror = () => {
        setCropError('Не удалось загрузить изображение с указанного URL.');
        setImageSrc('');
      };

      imageElement.src = objectUrl;
    })
    .catch((error) => {
      setCropError(`Ошибка при загрузке изображения: ${error.message}`);
      setImageSrc('');
    });
};

const handleDisplayedImageIndexChange = (
  action: 'next' | 'prev',
  {
    selectedImages,
    displayedImageIndex,
    setDisplayedImageIndex,
  }: {
    selectedImages: string[];
    displayedImageIndex: number;
    setDisplayedImageIndex: (index: number) => void;
  },
) => {
  const maxIndex = selectedImages.length - 1;
  const currentIndex = displayedImageIndex;

  const newIndex =
    action === 'next'
      ? (currentIndex + 1) % (maxIndex + 1)
      : action === 'prev'
        ? (currentIndex - 1 + maxIndex + 1) % (maxIndex + 1)
        : 0;

  setDisplayedImageIndex(newIndex);
};

const handleOrderInput = (
  event: FormEvent<HTMLInputElement>,
  currentIndex: number,
  {
    selectedImages,
    setSelectedImages,
  }: {
    selectedImages: string[];
    setSelectedImages: Dispatch<SetStateAction<string[]>>;
  },
) => {
  const newOrder = parseInt(event.currentTarget.value);

  if (!isNaN(newOrder) && newOrder > 0 && newOrder <= selectedImages.length) {
    const updatedSelectedImages = [...selectedImages];
    const movedImage = updatedSelectedImages.splice(currentIndex, 1)[0];
    updatedSelectedImages.splice(newOrder - 1, 0, movedImage);
    setSelectedImages(updatedSelectedImages);
  }
};

export {
  uploadToFileStorage,
  handleImageChange,
  setCanvas,
  handleFileSelection,
  handleDisplayedImageIndexChange,
  handleOrderInput,
  handleImageUrl,
};
