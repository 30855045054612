import { AxiosInstance, AxiosResponse } from 'axios';
import { createAxiosInstance } from './axiosInstance';

const axiosInstanceResizerFormData: AxiosInstance = createAxiosInstance(
  process.env.REACT_APP_RESIZER_URL as string,
  'multipart/form-data',
  true
);

export const apiServiceResizer = {
  postFormData: <T>(
    url: string,
    formData?: FormData,
  ): Promise<AxiosResponse<T>> =>
    axiosInstanceResizerFormData.post<T>(url, formData),
};
