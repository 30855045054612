import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Title } from '@vkontakte/vkui';
import Layout from 'layout';
import PanelTitle from 'components/common/PanelTitle';
import PersonsEdit from 'components/Persons/PersonsEdit';
import Persons from 'components/Persons/Persons';

const PersonsPage: FC = () => {
  const { methodType } = useParams();

  return (
    <Layout>
      {!methodType ? (
        <PanelTitle withArrow={false}>
          <Title className="text-color-black">Персоны</Title>
        </PanelTitle>
      ) : null}
      {methodType ? <PersonsEdit /> : <Persons />}
    </Layout>
  );
};

export default PersonsPage;
