import React, { FC } from 'react';
import {
  Icon28CheckCircleOutline,
  Icon28ErrorCircleOutline,
} from '@vkontakte/icons';
import {
  Snackbar as VKSnackbar,
  SnackbarProps as VKSnackbarProps,
} from '@vkontakte/vkui';
import { Portal } from './Portal';
import { v4 as uuidv4 } from 'uuid';

export interface ISnackbarContent {
  type?: 'success' | 'error';
  message: string;
}

export interface ISnackbar
  extends ISnackbarContent,
    Omit<VKSnackbarProps, 'onClose'> {
  isShow?: boolean;
  onClose?: () => void;
}

export const Snackbar: FC<ISnackbar> = (props) => {
  const { message, type = 'success', isShow, onClose, ...rest } = props;

  const icon =
    type === 'success' ? (
      <Icon28CheckCircleOutline fill="var(--vkui--color_icon_positive)" />
    ) : (
      <Icon28ErrorCircleOutline fill="var(--vkui--color_icon_negative)" />
    );

  return isShow ? (
    <Portal>
      <VKSnackbar
        key={uuidv4()}
        before={icon}
        onClose={() => (onClose ? onClose() : false)}
        {...rest}
      >
        {message}
      </VKSnackbar>
    </Portal>
  ) : null;
};
