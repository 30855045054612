import React from 'react';
import { Div, Text } from '@vkontakte/vkui';

export const EmptyChipsSelect = () => {
  return (
    <div className="pointer-events-none opacity-50">
      <Div className="flex items-center justify-between bg-color-steel-gray-20 rounded-md border-1 p-1.5 border-color-steel-gray-100 h-10">
        <Text className="text-color-steel-gray-400 pl-2">
          Нет доступных для выбора
        </Text>
      </Div>
    </div>
  );
};
