import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Title } from '@vkontakte/vkui';
import Layout from 'layout';
import PanelTitle from 'components/common/PanelTitle';
import ReviewsEdit from 'components/Reviews/ReviewsEdit';
import Reviews from 'components/Reviews/Reviews';

const ReviewsPage: FC = () => {
  const { methodType } = useParams();

  return (
    <Layout>
      {!methodType ? (
        <PanelTitle withArrow={false}>
          <Title className="text-color-black">Отзывы</Title>
        </PanelTitle>
      ) : null}
      {methodType ? <ReviewsEdit /> : <Reviews />}
    </Layout>
  );
};

export default ReviewsPage;
