import { IAuthUser } from 'lib/types';

export const getUserToken = (tokenType: string): string | null => {
  const storedUserString: string | null = localStorage.getItem('user');
  const storedUser: IAuthUser = storedUserString
    ? JSON.parse(storedUserString)
    : null;
  return tokenType === 'access'
    ? storedUser?.access_token || null
    : storedUser?.refresh_token || null;
};

export const getUserRole = (): string | null => {
  const storedUserString: string | null = localStorage.getItem('user');
  const storedUser: IAuthUser = storedUserString
    ? JSON.parse(storedUserString)
    : null;
  return storedUser?.role || null;
};
