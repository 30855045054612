import React from 'react';
import { useParams } from 'react-router-dom';
import { Title } from '@vkontakte/vkui';
import Layout from 'layout';
import PanelTitle from 'components/common/PanelTitle';
import UsersEdit from 'components/Users/UsersEdit';
import Users from 'components/Users/Users';

const UsersPage = () => {
  const { methodType } = useParams();

  return (
    <Layout>
      {!methodType ? (
        <PanelTitle withArrow={false}>
          <Title className="text-color-black">Пользователи</Title>
        </PanelTitle>
      ) : null}
      {methodType ? <UsersEdit /> : <Users />}
    </Layout>
  );
};

export default UsersPage;
