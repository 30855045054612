import { AxiosInstance, AxiosResponse } from 'axios';
import { createAxiosInstance } from './axiosInstance';
import { IMe } from 'components/Users/types.users';

export interface ApiResponse<T> {
  count: number;
  next: number | null;
  previous: number | null;
  results: T;
}

const axiosInstance: AxiosInstance = createAxiosInstance(
  process.env.REACT_APP_BASE_URL as string,
  'application/json',
);

export const getMeService = {
  getMyProfile: (): Promise<AxiosResponse<IMe>> =>
    axiosInstance.get<IMe>('/business-profile/me/'),
};

export default getMeService;
