import React, { useEffect, useRef, useState, FC } from 'react';
import FormItemPanel from './FormItemPanel';
import { EmptyChipsSelect } from './EmptyChipsSelect';
import ChipsSelectComponent from './ChipsSelectComponent';
import { IChip } from 'lib/types';
import useDebounce from 'lib/hooks/useDebounce';
import useFetch from 'lib/hooks/useFetch';
import { ApiResponse } from 'lib/api/api';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { IEventLabel } from 'components/directories/EventLabelsDirectory/types.eventLabelsDirectory';
import { IType } from 'components/directories/TypesDirectory/types.typesDirectory';

interface ILabelChipsSelectProps {
  // eslint-disable-next-line
  label_top_id?: any;
  // eslint-disable-next-line
  label_bottom_id?: any;
  // eslint-disable-next-line
  filters: Record<string, any>;
  // eslint-disable-next-line
  setFilters: (filters: Record<string, any>) => void;
  reset: () => void;
  labelType: 'Лейбл мероприятия' | 'Лейбл цены';
}

const LabelChipsSelect: FC<ILabelChipsSelectProps> = ({
  label_top_id,
  label_bottom_id,
  filters,
  setFilters,
  reset,
  labelType,
}) => {
  const [allTopLabels, setAllTopLabels] = useState<IEventLabel[]>([]);
  const [currentTopLabels, setCurrentTopLabels] = useState<IEventLabel[]>([]);
  const [topLabelsCurrentPage, setTopLabelsCurrentPage] = useState<number>(1);
  const [topLabelsSearch, setTopLabelsSearch] = useState<string>('');
  const debouncedTopLabelsSearch = useDebounce(topLabelsSearch, 500);
  const topLabelListInnerRef = useRef<HTMLDivElement>(null);

  const {
    data: topLabelsData,
    loading: topLabelsLoading,
    fetchData: fetchTopLabels,
  } = useFetch<ApiResponse<IEventLabel[]>>();

  const [topLabelsSentryRef, { rootRef: topLabelsRootRef }] = useInfiniteScroll(
    {
      onLoadMore: () => {
        setTopLabelsCurrentPage((prev) => prev + 1);
      },
      hasNextPage: !!topLabelsData?.next,
      loading: topLabelsLoading,
      rootMargin: '0px 0px 0px 0px',
      delayInMs: 200,
    },
  );

  const [allBottomLabels, setAllBottomLabels] = useState<IType[]>([]);
  const [currentBottomLabels, setCurrentBottomLabels] = useState<IType[]>([]);
  const [bottomLabelsCurrentPage, setBottomLabelsCurrentPage] =
    useState<number>(1);
  const [bottomLabelsSearch, setBottomLabelsSearch] = useState<string>('');
  const debouncedBottomLabelsSearch = useDebounce(bottomLabelsSearch, 500);
  const bottomLabelListInnerRef = useRef<HTMLDivElement>(null);

  const {
    data: bottomLabelsData,
    loading: bottomLabelsLoading,
    fetchData: fetchBottomLabels,
  } = useFetch<ApiResponse<IType[]>>();

  const [bottomLabelsSentryRef, { rootRef: bottomLabelsRootRef }] =
    useInfiniteScroll({
      onLoadMore: () => {
        setBottomLabelsCurrentPage((prev) => prev + 1);
      },
      hasNextPage: !!bottomLabelsData?.next,
      loading: bottomLabelsLoading,
      rootMargin: '0px 0px 0px 0px',
      delayInMs: 200,
    });

  useEffect(() => {
    handleReset();
  }, [reset]);

  const handleReset = () => {
    labelType === 'Лейбл мероприятия' && setTopLabelsSearch('');
    labelType === 'Лейбл цены' && setBottomLabelsSearch('');
  };

  useEffect(() => {
    fetchTopLabels({
      path: `/event/label/?type=event&is_active=true&page=${
        topLabelsCurrentPage - 1
      }&page_size=10${topLabelsSearch && `&q=${debouncedTopLabelsSearch}`}`,
      method: 'get',
    });
  }, [topLabelsCurrentPage, debouncedTopLabelsSearch]);

  useEffect(() => {
    setTopLabelsCurrentPage(1);
    setCurrentTopLabels([]);
  }, [topLabelsSearch]);

  useEffect(() => {
    if (!topLabelsLoading && topLabelsData?.results) {
      setCurrentTopLabels(topLabelsData.results);
      setAllTopLabels((prev) => [...prev, ...topLabelsData.results]);
      topLabelListInnerRef.current?.scrollIntoView({
        inline: 'start',
        block: 'nearest',
      });
    }
  }, [topLabelsLoading]);

  useEffect(() => {
    fetchBottomLabels({
      path: `/event/label/?type=price&is_active=true&page=${
        bottomLabelsCurrentPage - 1
      }&page_size=10${bottomLabelsSearch && `&q=${debouncedBottomLabelsSearch}`}`,
      method: 'get',
    });
  }, [bottomLabelsCurrentPage, debouncedBottomLabelsSearch]);

  useEffect(() => {
    setBottomLabelsCurrentPage(1);
    setCurrentBottomLabels([]);
  }, [bottomLabelsSearch]);

  useEffect(() => {
    if (!bottomLabelsLoading && bottomLabelsData?.results) {
      setCurrentBottomLabels(bottomLabelsData.results);
      setAllBottomLabels((prev) => [...prev, ...bottomLabelsData.results]);
      bottomLabelListInnerRef.current?.scrollIntoView({
        inline: 'start',
        block: 'nearest',
      });
    }
  }, [bottomLabelsLoading]);

  return (
    <>
      {labelType === 'Лейбл мероприятия' && (
        <FormItemPanel top="Лейбл мероприятия">
          {allTopLabels.length === 0 ? (
            <EmptyChipsSelect />
          ) : (
            <ChipsSelectComponent
              disabled={allTopLabels.length === 0}
              hasNextPage={!!topLabelsData?.next}
              loaderRef={topLabelsSentryRef}
              listRef={topLabelsRootRef}
              listInnerRef={topLabelListInnerRef}
              onInput={(e) => {
                setTopLabelsSearch(e.target.value.trim());
              }}
              inputValue={topLabelsSearch}
              chipClassName="max-w-[175px] truncate"
              placeholder="Выберите лейбл"
              values={(label_top_id as IChip[]).map((label) => ({
                label:
                  allTopLabels.find((item) => item.id === label.value)?.name ||
                  label.label,
                value: label.value,
              }))}
              onChange={(value) =>
                setFilters({ ...filters, label_top_id: value })
              }
              options={currentTopLabels.map((label) => ({
                label: label.name,
                value: label.id,
              }))}
            />
          )}
        </FormItemPanel>
      )}
      {labelType === 'Лейбл цены' && (
        <FormItemPanel top="Лейбл цены">
          {allBottomLabels.length === 0 ? (
            <EmptyChipsSelect />
          ) : (
            <ChipsSelectComponent
              disabled={allBottomLabels.length === 0}
              hasNextPage={!!bottomLabelsData?.next}
              loaderRef={bottomLabelsSentryRef}
              listRef={bottomLabelsRootRef}
              listInnerRef={bottomLabelListInnerRef}
              onInput={(e) => {
                setBottomLabelsSearch(e.target.value.trim());
              }}
              inputValue={bottomLabelsSearch}
              chipClassName="max-w-[175px] truncate"
              placeholder="Выберите лейбл"
              values={(label_bottom_id as IChip[]).map((label) => ({
                label:
                  allBottomLabels.find((item) => item.id === label.value)
                    ?.name || label.label,
                value: label.value,
              }))}
              onChange={(value) =>
                setFilters({ ...filters, label_bottom_id: value })
              }
              options={currentBottomLabels.map((label) => ({
                label: label.name,
                value: label.id,
              }))}
            />
          )}
        </FormItemPanel>
      )}
    </>
  );
};

export default LabelChipsSelect;
