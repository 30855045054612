import {
  ADMIN_DIRECTORIES_ROUTE,
  ADMIN_EVENTS_ROUTE,
  ADMIN_NOT_FOUND_ROUTE,
  ADMIN_ORDERS_ROUTE,
  ADMIN_PLACES_ROUTE,
  ADMIN_RANKING_ROUTE,
  ADMIN_REVIEWS_ROUTE,
} from 'lib/constants';

export const checkUserRights = (role: string, url: string) => {
  const supportRoutes = [ADMIN_REVIEWS_ROUTE, ADMIN_ORDERS_ROUTE];

  const editorRoutes = [
    ADMIN_EVENTS_ROUTE,
    ADMIN_RANKING_ROUTE,
    ADMIN_PLACES_ROUTE,
    ADMIN_DIRECTORIES_ROUTE,
    ADMIN_REVIEWS_ROUTE,
    ADMIN_ORDERS_ROUTE,
  ];

  switch (role) {
    case 'support':
      return supportRoutes.includes(url) ? url : ADMIN_NOT_FOUND_ROUTE;

    case 'editor':
      return editorRoutes.includes(url) ? url : ADMIN_NOT_FOUND_ROUTE;

    case 'admin':
      return url;

    default:
      return ADMIN_NOT_FOUND_ROUTE;
  }
};
