import AuthPage from 'pages/AuthPage';
import DirectoriesPage from 'pages/DirectoriesPage';
import ErrorPage from 'pages/ErrorPage';
import EventsPage from 'pages/EventsPage';
import EventsRankingPage from 'pages/EventsRankingPage';
import OrdersPage from 'pages/OrdersPage';
import PlacesPage from 'pages/PlacesPage';
import ReviewsPage from 'pages/ReviewsPage';
import UsersPage from 'pages/UsersPage';
import PersonsPage from 'pages/PersonsPage';
import React from 'react';
import {
  ADMIN_DIRECTORIES_ROUTE,
  ADMIN_EVENTS_ROUTE,
  ADMIN_LOGIN_ROUTE,
  ADMIN_NOT_FOUND_ROUTE,
  ADMIN_ORDERS_ROUTE,
  ADMIN_PLACES_ROUTE,
  ADMIN_RANKING_ROUTE,
  ADMIN_REVIEWS_ROUTE,
  ADMIN_USERS_ROUTE,
  ADMIN_PERSONS_ROUTE,
} from './lib/constants';

export const publicRoutes = [
  {
    path: ADMIN_LOGIN_ROUTE,
    element: <AuthPage />,
  },
];

export const authRoutes = [
  {
    path: ADMIN_USERS_ROUTE + '/:methodType?/:userId?',
    element: <UsersPage />,
  },
  {
    path: ADMIN_EVENTS_ROUTE + '/:methodType?/:eventId?',
    element: <EventsPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ADMIN_RANKING_ROUTE + '/:methodType?/:rankingId?',
    element: <EventsRankingPage />,
  },
  {
    path: ADMIN_PLACES_ROUTE + '/:methodType?/:placeId?',
    element: <PlacesPage />,
  },
  {
    path: ADMIN_ORDERS_ROUTE + '/:methodType?/:orderId?',
    element: <OrdersPage />,
  },
  {
    path:
      ADMIN_DIRECTORIES_ROUTE + '/:directoriesType/:methodType?/:directoryId?',
    element: <DirectoriesPage />,
  },
  {
    path: ADMIN_REVIEWS_ROUTE + '/:methodType?/:reviewId?',
    element: <ReviewsPage />,
  },
  {
    path: ADMIN_PERSONS_ROUTE + '/:methodType?/:personId?',
    element: <PersonsPage />,
  },
  {
    path: ADMIN_NOT_FOUND_ROUTE,
    element: <ErrorPage />,
  },
];
