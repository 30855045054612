import SeoMetaFormItem from 'components/common/SeoMetaFromItem';
import { Meta } from 'entity/seoMeta/seoMeta';
import * as React from 'react';

const SEO_META_TITLE_LENGTH = 90;
const SEO_META_DESCRIPTION_LENGTH = 400;

type PersonsSeoProps = {
    meta: Meta;
    setMeta: React.Dispatch<React.SetStateAction<Meta>>;
    footerForm?: React.ReactNode;
};

export const PersonsSeo: React.FC<PersonsSeoProps> = ({
    meta,
    setMeta,
    footerForm,
}) => {
    const changeTitle = (title: string) => {
        setMeta({
            title,
            description: meta?.description ?? '',
        });
    };

    const changeDescription = (description: string) => {
        setMeta({
            title: meta?.title ?? '',
            description,
        });
    };

    return (
        <>
            <SeoMetaFormItem
                className="vkui-input"
                top="Title" name="meta_title"
                value={meta?.title ?? ''}
                onChange={changeTitle}
                placeholder="title"
                maxLength={SEO_META_TITLE_LENGTH}
            />
            <SeoMetaFormItem
                className="vkui-input"
                top="Description"
                name="meta_description"
                value={meta?.description ?? ''}
                onChange={changeDescription}
                placeholder="description"
                maxLength={SEO_META_DESCRIPTION_LENGTH}
            />
            {footerForm}
        </>
    );
};