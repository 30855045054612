import React, { useEffect, useRef, useState, FC } from 'react';
import FormItemPanel from './FormItemPanel';
import { EmptyChipsSelect } from './EmptyChipsSelect';
import ChipsSelectComponent from './ChipsSelectComponent';
import { IChip } from 'lib/types';
import useDebounce from 'lib/hooks/useDebounce';
import useFetch from 'lib/hooks/useFetch';
import { ApiResponse } from 'lib/api/api';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { IPlace } from 'components/Places/types.places';

interface IPlaceChipsSelectProps {
  // eslint-disable-next-line
  place_id: any;
  // eslint-disable-next-line
  filters: Record<string, any>;
  // eslint-disable-next-line
  setFilters: (filters: Record<string, any>) => void;
  reset: () => void;
  filterKey: string;
}

const PlaceChipsSelect: FC<IPlaceChipsSelectProps> = ({
  place_id,
  filters,
  setFilters,
  reset,
  filterKey,
}) => {
  const [forceUpdate, setForceUpdate] = useState(0);
  const [allVenues, setAllVenues] = useState<IPlace[]>([]);
  const [currentVenues, setCurrentVenues] = useState<IPlace[]>([]);
  const [venueCurrentPage, setVenueCurrentPage] = useState<number>(1);
  const [venueSearch, setVenueSearch] = useState<string>('');
  const debouncedVenueSearch = useDebounce(venueSearch, 500);
  const venueListInnerRef = useRef<HTMLDivElement>(null);

  const rerender = () => {
    setForceUpdate((prevState) => prevState + 1);
  };

  const {
    data: venuesData,
    loading: venuesLoading,
    fetchData: fetchVenues,
  } = useFetch<ApiResponse<IPlace[]>>();

  const [venueSentryRef, { rootRef: venueRootRef }] = useInfiniteScroll({
    onLoadMore: () => {
      setVenueCurrentPage((prev) => prev + 1);
    },
    hasNextPage: !!venuesData?.next,
    loading: venuesLoading,
    rootMargin: '0px 0px 0px 0px',
    delayInMs: 200,
  });

  useEffect(() => {
    handleReset();
  }, [reset]);

  const handleReset = () => {
    setVenueSearch('');
  };

  useEffect(() => {
    fetchVenues({
      path: `/search/places?page=${
        venueCurrentPage - 1
      }&page_size=30${venueSearch && `&q=${debouncedVenueSearch}`}`,
      method: 'get',
    });
  }, [venueCurrentPage, debouncedVenueSearch]);

  useEffect(() => {
    setVenueCurrentPage(1);
    setCurrentVenues([]);
  }, [venueSearch]);

  useEffect(() => {
    if (!venuesLoading && venuesData?.results) {
      setCurrentVenues(venuesData.results);
      setAllVenues((prev) => [...prev, ...venuesData.results]);

      venueListInnerRef.current?.scrollIntoView({
        inline: 'start',
        block: 'nearest',
      });
    }
  }, [venuesLoading]);

  const { data, loading, fetchData } = useFetch<IPlace>();
  const [checkedPlaces, setCheckedPlaces] = useState<IChip[]>([]);

  const fetchDetail = async (id: string) => {
    await fetchData({
      path: `/search/places/${id}`,
      method: 'get',
    });
  };

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const getPlaceNameById = async (ids: IChip[]) => {
    if (ids?.length > 0) {
      for (const id of ids) {
        await fetchDetail(id.value);
        await delay(100);
      }
    }
  };

  useEffect(() => {
    if (!loading && data) {
      setCheckedPlaces((prev) => {
        const updatedPlaces = [
          ...prev,
          {
            label: data.name,
            value: data.id,
          },
        ];

        const uniquePlaces = Array.from(
          new Map(updatedPlaces.map((item) => [item.value, item])).values(),
        );

        return uniquePlaces;
      });

      rerender();
    }
  }, [data, loading]);

  useEffect(() => {
    getPlaceNameById(place_id);
  }, []);

  return (
    <>
      <FormItemPanel top="Место проведения">
        {allVenues.length === 0 ? (
          <EmptyChipsSelect />
        ) : (
          <ChipsSelectComponent
            key={forceUpdate}
            disabled={allVenues.length === 0}
            hasNextPage={!!venuesData?.next}
            loaderRef={venueSentryRef}
            listRef={venueRootRef}
            listInnerRef={venueListInnerRef}
            onInput={(e) => {
              setVenueSearch(e.target.value.trim());
            }}
            inputValue={venueSearch}
            chipClassName="max-w-[175px] truncate"
            placeholder="Выберите места проведения"
            values={checkedPlaces}
            onChange={(value) => {
              setFilters({ ...filters, [filterKey]: value });
            }}
            options={currentVenues.map((venue) => ({
              label: venue.name,
              value: venue.id,
            }))}
          />
        )}
      </FormItemPanel>
    </>
  );
};

export default PlaceChipsSelect;
