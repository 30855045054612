import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { Cell, Div, Image } from '@vkontakte/vkui';
import {
  Icon24RemoveCircle,
  Icon24ListNumberOutline,
  Icon24LinkCircleFilled,
  Icon12EyeSlashOutline,
  Icon20FavoriteOutline,
} from '@vkontakte/icons';
import { Reorder } from 'framer-motion';
import clsx from 'clsx';
import { IRankingEvent } from './types.rankings';
import { ADMIN_EVENTS_ROUTE } from 'lib/constants';

interface IEventsRankingCardsProps {
  cards: IRankingEvent[];
  oldCards: IRankingEvent[];
  setCards: Dispatch<SetStateAction<IRankingEvent[]>>;
  onDelete: (index: number) => void;
}

const RankingCards: FC<IEventsRankingCardsProps> = ({
  cards,
  oldCards,
  setCards,
  onDelete,
}) => {
  const handleDelete = (index: number) => {
    onDelete(index);
  };

  const handleOrderInput = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const newIndex = parseInt(event.target.value) - 1;
    if (newIndex >= 0 && newIndex < cards.length && newIndex !== index) {
      const newCards = [...cards];
      const [movedItem] = newCards.splice(index, 1);
      newCards.splice(newIndex, 0, movedItem);
      setCards(newCards);
    }
  };

  const isOldCard = (id: string) =>
    oldCards.some((oldCard) => oldCard.id === id);

  return (
    <Div>
      <Reorder.Group
        className="flex flex-col gap-1"
        values={cards}
        onReorder={setCards}
      >
        {cards.map((item, index) => (
          <Reorder.Item id={item.id} key={item.id} value={item}>
            <div className="flex gap-3 relative">
              <input
                type="text"
                className="focus:outline-none focus:ring-2 focus:ring-color-nav-items-blue w-8 max-h-8 text-center rounded-md mt-4 py-px bg-color-card-grey text-color-steel-gray-500"
                value={index + 1}
                onChange={(event) => handleOrderInput(event, index)}
              />
              <Cell
                className={clsx(
                  'bg-color-card-grey group py-1 w-1/2 rounded-md hover:bg-color-light-blue-40 transition-all',
                  {
                    'bg-color-card-grey':
                      item.has_active_seances &&
                      item.is_enabled &&
                      isOldCard(item.id),
                    'bg-red-100 hover:bg-red-200':
                      !(item.has_active_seances && item.is_enabled) &&
                      isOldCard(item.id),
                    'outline-1 outline-dashed outline-color-nav-items-blue':
                      !isOldCard(item.id),
                  },
                )}
                hasHover={false}
                hasActive={false}
                before={
                  item.preview_url === '' ? (
                    <Image size={48} borderRadius="l">
                      <Icon12EyeSlashOutline />
                    </Image>
                  ) : (
                    <Image
                      size={48}
                      src={item.preview_url}
                      borderRadius="l"
                    ></Image>
                  )
                }
                after={
                  <div className="flex flex-row align-middle items-center">
                    {item.rating && item.rating !== 0 ? (
                      <div className="flex flex-row align-middle items-center absolute right-20">
                        <>
                          <Icon20FavoriteOutline
                            width={24}
                            height={24}
                            color={'#5181B8'}
                          />
                          <span className="text-base">{item.rating || null}</span>
                        </>
                      </div>
                    ) : null}

                    <Icon24LinkCircleFilled
                      className="cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute right-10 top-1/2 transform -translate-y-1/2"
                      width={27}
                      height={27}
                      color={'#5181B8'}
                      onClick={() =>
                        window.open(
                          ADMIN_EVENTS_ROUTE + `/edit/${item.id}`,
                          '_blank',
                        )
                      }
                    />
                    <Icon24RemoveCircle
                      className="cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute right-2 top-1/2 transform -translate-y-1/2"
                      width={24}
                      height={24}
                      color={'#e64646'}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDelete(index);
                      }}
                    />
                  </div>
                }
              >
                <div className="flex flex-col">
                  <span className="font-medium">{`${item.name.substring(0, 45)}${item.name.length > 45 ? '...' : ''}`}</span>
                  <span className="text-color-steel-gray-500 font-light">
                    {item.category_name || 'Тип не указан'}
                  </span>
                </div>
              </Cell>
              <div>
                <Icon24ListNumberOutline
                  key={item.id}
                  color="#E5EBF1"
                  width={24}
                  height={24}
                  className="reorder-handle cursor-ns-resize mt-5"
                />
              </div>
            </div>
          </Reorder.Item>
        ))}
      </Reorder.Group>
    </Div>
  );
};

export default RankingCards;
