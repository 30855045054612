export const eventTagHeaders = [
	{
		order: 1,
		label: 'Название',
		value: 'name',
	},
	{
		order: 2,
		label: 'Активность',
		value: 'is_active',
	},
];

export const eventTagFilters = {
	ordering: '',
	q: '',
	name: '',
	is_active: '',
	parent_id: '',
};

export const eventTagBlank = {
	id: '',
	name: '',
	is_active: false,
};

export const eventTagsFieldNameMap = {
	name: 'Название',
	isNewActive: 'Активно',
};
